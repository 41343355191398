<ion-header>
    <ion-toolbar class="tabbar-more__toolbar" color="sidebar">
        <ion-title class="cc-primary-header">{{ 'TABBAR_MENU' | translate }}</ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="dismiss()">
                <i class="material-icons" slot="icon-only" aria-label="Close">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="tabbar-more__content" color="sidebar">
    <ion-list lines="none">
        @for (module of modules; track module) {
            @if (module.type === 'folder') {
                <ion-accordion-group>
                    <ion-accordion>
                        <ion-item
                            class="tabbar-more__item"
                            color="sidebar"
                            button
                            slot="header"
                            [class]="'cc-sidebar-left-list-item-' + module.id"
                            [class.selected]="selectedModuleId === module.id"
                        >
                            <cc-module-icon
                                slot="start"
                                [module]="module"
                                [attr.aria-label]="module.name"
                            ></cc-module-icon>
                            <ion-label>{{ module.name }}</ion-label>
                        </ion-item>

                        @for (child of module.children; track child) {
                            <ion-item
                                class="tabbar-more__item tabbar-more__child"
                                color="sidebar"
                                button
                                slot="content"
                                [class.selected]="selectedModuleId === child.id"
                                [routerLink]="child.routeCommands"
                                (click)="onModuleClick()"
                            >
                                <cc-module-icon
                                    slot="start"
                                    [module]="child"
                                    [attr.aria-label]="child.name"
                                ></cc-module-icon>
                                <ion-label>{{ child.name }}</ion-label>
                            </ion-item>
                        }
                    </ion-accordion>
                </ion-accordion-group>
            } @else {
                <ion-item
                    class="tabbar-more__item"
                    color="sidebar"
                    button
                    [class.selected]="selectedModuleId === module.id"
                    [routerLink]="module.routeCommands"
                    (click)="onModuleClick()"
                >
                    <cc-module-icon slot="start" [module]="module" [attr.aria-label]="module.name"></cc-module-icon>
                    <ion-label>{{ module.name }}</ion-label>
                </ion-item>
            }
        }
    </ion-list>

    <ion-list class="tabbar-more__footer" lines="none">
        @if (portalConfig) {
            <ion-item class="tabbar-more__item" color="sidebar" button (click)="portalReturn()">
                <i aria-hidden="true" slot="start" class="material-icons">apps</i>
                <ion-label> {{ 'SIDEBAR_LEFT_BACK_TO_PORTAL' | translate }} {{ portalConfig.portal_name }} </ion-label>
            </ion-item>
        }

        <ion-item class="tabbar-more__item" color="sidebar" button (click)="openTerms()">
            <i aria-hidden="true" slot="start" class="material-icons">policy</i>
            <ion-label>
                {{ 'SIDEBAR_LEFT_TERMS_AND_CONDITIONS' | translate }}
            </ion-label>
        </ion-item>

        <cc-experimental-switch></cc-experimental-switch>
    </ion-list>
</ion-content>
