<div *ngIf="notification" class="notification__container" [ngClass]="notification.theme">
    <button
        class="notification__button ion-button-reset"
        [attr.aria-label]="notification.actionAriaLabel | translate: notification.translateParams"
        (click)="clicked()"
    >
        <ion-card class="notification__card">
            <ion-card-content class="notification__card-content" [class.with-buttons]="notification.buttons">
                <div *ngIf="notification.icon" class="notification__card-icon">
                    <i aria-hidden="true" class="material-icons-outlined">{{ notification.icon }}</i>
                </div>
                <div class="notification__card-body">
                    <div class="notification__header-row">
                        <h1 class="notification__title">
                            {{ notification.title | translate: notification.translateParams }}
                        </h1>
                        <ion-button
                            class="notification__close-button"
                            size="small"
                            shape="round"
                            [attr.aria-label]="'NOTIFICATIONS_DISMISS_ARIA_LABEL' | translate"
                            (click)="dismiss()"
                        >
                            <i class="material-icons" slot="icon-only" aria-hidden="true">close</i>
                        </ion-button>
                    </div>
                    <p *ngFor="let message of messages" class="notification__message">
                        {{ message | translate: notification.translateParams }}
                    </p>
                </div>
            </ion-card-content>
            <div class="notification__card-footer" [class.has-actions]="notification.buttons">
                <div *ngIf="notification.buttons" class="notification__action-buttons">
                    <ng-container *ngFor="let button of notification.buttons">
                        <ion-button
                            class="notification__action-button"
                            size="small"
                            color="light"
                            (click)="button.handler()"
                        >
                            <i *ngIf="button.icon" slot="start" class="material-icons-outlined" aria-hidden="true">
                                {{ button.icon }}
                            </i>
                            <span>
                                {{ button.text | translate }}
                            </span>
                        </ion-button>
                    </ng-container>
                </div>
            </div>
        </ion-card>
    </button>
</div>
