import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { AppRouteParams } from '../../routing/constants/app-route-params.constant';
import { ModuleService } from '../services/module/module.service';
import { AppModuleRoutes } from '@core/routing/constants/module-routes.constant';
import { map } from 'rxjs';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';

export const FolderGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    const moduleService = inject(ModuleService);
    const appCoreFacadeService = inject(AppCoreFacadeService);
    const moduleId = parseInt(route.paramMap.get(AppRouteParams.moduleId), 10);
    const appName = route.paramMap.get(AppRouteParams.appName);

    return moduleService.getModule(appName, moduleId).pipe(
        map((module) => {
            const childModule = module.children[0];
            const childModuleRoute = AppModuleRoutes[childModule.type];

            appCoreFacadeService.dispatch(
                AppRoutingActions.goToAppModule({ urlSegments: [childModule.id, childModuleRoute] })
            );
            return false;
        })
    );
};
