import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BadgesEnabledGuard {
    constructor(private appCoreFacadeService: AppCoreFacadeService) {}

    canActivate(): Observable<boolean> {
        return this.appCoreFacadeService.getAppSettings().pipe(map((app) => app && app.badges_enabled));
    }
}
