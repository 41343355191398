import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { ChallengeCompletedModalComponent } from '../../components/challenge-completed-modal/challenge-completed-modal.component';
import { Observable, from, switchMap } from 'rxjs';
import { ChallengeFailedModalComponent } from '../../components/challenge-failed-modal/challenge-failed-modal.component';
import { ChallengeCompletion } from '@shared/api';
import { DateTime } from 'luxon';
import { ChallengeAlreadyCompletedModalComponent } from '@features/challenge-completion/components/challenge-already-completed-modal/challenge-already-completed-modal.component';

@Injectable({
    providedIn: 'root'
})
export class ChallengeCompletionModalService {
    private handledChallengeCompletions: Array<number> = [];
    private lastCompletionDisplayedAt: DateTime;
    private readonly completionDebounceSeconds = 3;

    constructor(private modalController: ModalController) {}

    // Implemented to avoid duplicate completion messages being show in various places
    public shouldHandleChallengeCompletion(completion: ChallengeCompletion): boolean {
        const isDuplicateCompletion = this.handledChallengeCompletions.includes(completion.id);
        if (isDuplicateCompletion) {
            return false;
        }

        if (!this.lastCompletionDisplayedAt) {
            return true;
        }

        const debounceTimeElapsed =
            DateTime.now().diff(this.lastCompletionDisplayedAt, 'seconds').seconds > this.completionDebounceSeconds;

        return debounceTimeElapsed;
    }

    public openChallengeCompletedModal(options: { completion: ChallengeCompletion }): Observable<void> {
        this.handledChallengeCompletions.push(options.completion.id);
        this.lastCompletionDisplayedAt = DateTime.now();

        return from(
            this.modalController.create({
                component: ChallengeCompletedModalComponent,
                componentProps: {
                    completion: options.completion
                },
                cssClass: ['rounded-modal', 'small-modal', 'transparent-modal'],
                backdropDismiss: false
            })
        ).pipe(switchMap((modal) => modal.present()));
    }

    public openChallengeAlreadyCompletedModal(): Observable<void> {
        return from(
            this.modalController.create({
                component: ChallengeAlreadyCompletedModalComponent,
                cssClass: ['rounded-modal', 'small-modal', 'transparent-modal'],
                backdropDismiss: false
            })
        ).pipe(switchMap((modal) => modal.present()));
    }

    public openChallengeFailedModal(): Observable<void> {
        return from(
            this.modalController.create({
                component: ChallengeFailedModalComponent,
                cssClass: ['rounded-modal', 'small-modal', 'transparent-modal'],
                backdropDismiss: false
            })
        ).pipe(switchMap((modal) => modal.present()));
    }
}
