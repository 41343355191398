import { Component } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AppCoreActions } from '@core/root-store/store/app-core/actions/app-core.actions';
import { TranslateModule } from '@ngx-translate/core';
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle
} from '@ionic/angular/standalone';

@Component({
    selector: 'cc-error404',
    templateUrl: './error404.component.html',
    styleUrls: ['./error404.component.scss'],
    imports: [
        LottieComponent,
        TranslateModule,
        IonCard,
        IonCardHeader,
        IonCardTitle,
        IonCardSubtitle,
        IonCardContent,
        IonButton
    ]
})
export class Error404Component {
    public readonly noDataAnimationOptions: AnimationOptions = {
        autoplay: true,
        path: '/assets/lottie-animations/no-data.lottie.json',
        loop: false
    };

    constructor(private appCoreFacadeService: AppCoreFacadeService) {}

    public onHomepage(): void {
        this.appCoreFacadeService.dispatch(AppCoreActions.navigateHome());
    }
}
