import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular/standalone';
import { FocusService } from 'src/app/core/a11y/services/focus.service';
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';
import { SidebarType } from '../enums/sidebar-type.enum';

@Injectable({
    providedIn: 'root'
})
export class SidebarService {
    constructor(
        private menuController: MenuController,
        private focusService: FocusService
    ) {}

    async open(sidebar: SidebarType): Promise<void> {
        await this.menuController.open(sidebar);
        const menu = await this.menuController.get(sidebar);
        const focusTrap = await this.focusService.trapFocusByElementId(`${sidebar}-menu`);

        fromEvent(menu, 'ionDidClose')
            .pipe(take(1))
            .subscribe(() => focusTrap.destroy());
    }

    close(sidebar: SidebarType): void {
        this.menuController.close(sidebar);
    }

    enable(sidebar: SidebarType, enabled: boolean): void {
        this.menuController.enable(enabled, sidebar);
    }
}
