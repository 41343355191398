@if (theme?.banner_image) {
    <img
        tabindex="1"
        alt="App Banner"
        class="banner-image cc-sidebar-left-banner"
        [src]="theme.banner_image"
        (click)="onAppBannerClick()"
    />
}
<cc-banner-ad class="cc-sidebar-left-ad"></cc-banner-ad>

<div class="module-container" role="list">
    @for (module of modules; track module.id) {
        @if (module.type === 'folder') {
            <ion-accordion-group>
                <ion-accordion>
                    <ion-item
                        class="module-item"
                        lines="none"
                        color="sidebar"
                        slot="header"
                        button
                        [ngClass]="'cc-sidebar-left-list-item-' + module.id"
                        [class.selected]="selectedModule === module.id"
                    >
                        <cc-module-icon slot="start" [module]="module"></cc-module-icon>
                        <ion-label class="ion-text-wrap cc-primary-header">
                            {{ module.name }}
                        </ion-label>
                    </ion-item>

                    @for (child of module.children; track child.id) {
                        <ion-item
                            class="module-item module-item__child"
                            lines="none"
                            color="sidebar"
                            slot="content"
                            button
                            [ngClass]="'cc-sidebar-left-list-item-' + child.id"
                            [class.selected]="selectedModule === child.id"
                            [routerLink]="child.routeCommands"
                            (click)="onModuleClick()"
                        >
                            <cc-module-icon slot="start" [module]="child"></cc-module-icon>
                            <ion-label class="ion-text-wrap cc-primary-header">
                                {{ child.name }}
                            </ion-label>
                        </ion-item>
                    }
                </ion-accordion>
            </ion-accordion-group>
        } @else {
            <ion-item
                class="module-item"
                lines="none"
                color="sidebar"
                button
                [ngClass]="'cc-sidebar-left-list-item-' + module.id"
                [class.selected]="selectedModule === module.id"
                [routerLink]="module.routeCommands"
                (click)="onModuleClick()"
            >
                <cc-module-icon slot="start" [module]="module"></cc-module-icon>
                <ion-label class="ion-text-wrap cc-primary-header">
                    {{ module.name }}
                </ion-label>
            </ion-item>
        }
    }
</div>

<div class="footer">
    <ion-item class="collapse-menu-button" lines="none" button (click)="onToggleCollapse()" color="sidebar">
        <i class="material-icons" slot="start" aria-hidden="true">menu_open</i>
        <ion-label class="cc-primary-header" aria-expanded="true">
            {{ 'SIDEBAR_LEFT_COLLAPSE_HINT' | translate }}
        </ion-label>
    </ion-item>

    @if (portalConfig) {
        <ion-item button lines="none" (click)="onPortalReturn()" color="sidebar">
            <i class="material-icons" slot="start" aria-hidden="true">apps</i>
            <ion-label> {{ 'SIDEBAR_LEFT_BACK_TO_PORTAL' | translate }} {{ portalConfig.portal_name }} </ion-label>
        </ion-item>
    }

    <ion-item button lines="none" (click)="onTosDialogClick()" color="sidebar">
        <i class="material-icons" slot="start" aria-hidden="true">policy</i>
        <ion-label>
            {{ 'SIDEBAR_LEFT_TERMS_AND_CONDITIONS' | translate }}
        </ion-label>
    </ion-item>

    <cc-experimental-switch></cc-experimental-switch>

    <a href="https://www.crowdcomms.com" target="_blank" rel="noopener">
        @if (showPoweredByLogo) {
            <ion-item lines="none" color="sidebar">
                <cc-powered-by-logo [shade]="poweredByLogoShade"></cc-powered-by-logo>
            </ion-item>
        }
    </a>
</div>
