<a *ngIf="theme?.icon_image" class="cc-sidebar-left-app-icon" (click)="onAppIconClick()">
    <img [src]="theme.icon_image" alt="App Icon" />
</a>

<div class="module-icon-container cc-hide-scrollbar" color="sidebar" role="list">
    @for (module of modules; track module.id) {
        @if (module.type === 'folder') {
            @let moduleIconId = 'module-icon-' + module.id;
            <ion-accordion-group>
                <ion-accordion>
                    <ion-item
                        class="module-icon-item"
                        lines="none"
                        color="sidebar"
                        slot="header"
                        button
                        tooltip-class="cc-sidebar-left-tooltip"
                        [id]="moduleIconId"
                        [options]="tooltipOptions"
                        [tooltip]="module.name"
                        [class]="'cc-sidebar-left-icon-item-' + module.id"
                        [class.selected]="selectedModule === module.id"
                    >
                        <div class="module-icon-item-inner">
                            <cc-module-icon
                                class="module-icon-item-icon"
                                [module]="module"
                                [attr.aria-label]="module.name"
                            ></cc-module-icon>
                            @if (showLabels) {
                                <ion-label class="module-icon-item-label ion-text-wrap">{{ module.name }}</ion-label>
                            }
                        </div>
                        <ion-icon class="ion-accordion-toggle-icon"></ion-icon>
                    </ion-item>
                    @for (child of module.children; track child.id) {
                        <ng-container
                            [ngTemplateOutlet]="menuItem"
                            [ngTemplateOutletContext]="{ isChild: true, module: child }"
                        ></ng-container>
                    }
                </ion-accordion>
            </ion-accordion-group>
        } @else {
            <ng-container
                [ngTemplateOutlet]="menuItem"
                [ngTemplateOutletContext]="{ isChild: false, module: module }"
            ></ng-container>
        }
    }
</div>

<ng-template #menuItem let-isChild="isChild" let-module="module">
    <ion-item
        class="module-icon-item"
        [class.child-item]="isChild"
        lines="none"
        color="sidebar"
        [slot]="isChild ? 'content' : undefined"
        tooltip-class="cc-sidebar-left-tooltip"
        button
        [class]="'cc-sidebar-left-icon-item-' + module.id"
        [class.selected]="selectedModule === module.id"
        [options]="tooltipOptions"
        [tooltip]="module.name"
        [routerLink]="module.routeCommands"
        (click)="onModuleClick()"
    >
        <div class="module-icon-item-inner" [class.child-item-inner]="isChild">
            <cc-module-icon
                class="module-icon-item-icon"
                [attr.aria-label]="module.name"
                [module]="module"
            ></cc-module-icon>
            @if (showLabels) {
                <ion-label class="module-icon-item-label ion-text-wrap">
                    {{ module.name }}
                </ion-label>
            }
        </div>
    </ion-item>
</ng-template>

<div class="footer">
    <ion-button
        class="ion-no-margin"
        expand="full"
        color="sidebar"
        tooltip-class="cc-sidebar-left-tooltip"
        [options]="tooltipOptions"
        [tooltip]="'SIDEBAR_LEFT_EXPAND_HINT' | translate"
        (click)="onToggleCollapse()"
    >
        <i class="material-icons" slot="icon-only" aria-label="Expand" aria-expanded="false">chevron_right</i>
    </ion-button>

    <ion-button
        *ngIf="portalConfig"
        class="ion-no-margin"
        expand="full"
        color="sidebar"
        tooltip-class="cc-sidebar-left-tooltip"
        [options]="tooltipOptions"
        [tooltip]="('SIDEBAR_LEFT_BACK_TO_PORTAL' | translate) + ' ' + portalConfig.portal_name"
        (click)="onPortalReturn()"
    >
        <i class="material-icons" aria-label="Return to portal">apps</i>
    </ion-button>
</div>
