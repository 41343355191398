import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { ContainerService } from '@features/container/services/container.service';
import { DeviceService } from '../../device/services/device.service';
import { PageRoutes } from '../../routing/constants/page-routes.constant';
import { AppRouteParams } from '@core/routing/constants/app-route-params.constant';

/**
 * This guard will redirect from the top level route to either an app or the container page.
 */
@Injectable({
    providedIn: 'root'
})
export class AppRedirectGuard {
    constructor(
        private deviceService: DeviceService,
        private router: Router,
        private containerService: ContainerService
    ) {}

    async canActivate(next: ActivatedRouteSnapshot): Promise<UrlTree | boolean> {
        if (!this.isTopLevel(next)) {
            return true;
        }

        const containerChildAppUrl = this.containerService.getApp();

        if (containerChildAppUrl) {
            return this.router.createUrlTree([containerChildAppUrl]);
        }

        const nativeAppUrl = await this.deviceService.getNativeAppUrl();
        const isContainerApp = await this.containerService.isContainerApp(nativeAppUrl);

        if (!nativeAppUrl || isContainerApp) {
            return this.router.createUrlTree([PageRoutes.container]);
        }

        return this.router.createUrlTree([nativeAppUrl]);
    }

    // After the standalone migration, even the top level route '/' will have a firstChild
    // So to check if we're at the top level route, we also need to check that the appName is not undefined
    private isTopLevel(next: ActivatedRouteSnapshot): boolean {
        return !next.firstChild || next.firstChild.paramMap.get(AppRouteParams.appName) === `${undefined}`;
    }
}
