import { NgModule } from '@angular/core';
import { AppCoreModule } from './app-core/app-core.module';
import { BannerModule } from './banner/banner.module';
import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { FirebaseModule } from './firebase/firebase.module';
import { NavigationHistoryModule } from './navigation-history/navigation-history.module';
import { NavigationModule } from './navigation/navigation.module';
import { NotificationsModule } from './notifications/notifications.module';
import { RootStoreModule } from './root-store/root-store.module';
import { TagManagerModule } from './tag-management/tag-manager.module';
import { WINDOW_PROVIDERS } from './window.service';
import { NATIVE_PROVIDERS } from '@core/native/native-providers.constant';
import { AlertsModule } from '@features/alerts/alerts.module';
import { CustomIcons } from '@core/icons/constants/custom-icons.constant';
import { ICONS } from '@core/icons/tokens/icons.token';
import { VideoCallsModule } from '@features/video-calls/video-calls.module';
import { ChallengeCompletionModule } from '@features/challenge-completion/challenge-completion.module';

@NgModule({
    imports: [
        AlertsModule,
        AppCoreModule,
        ErrorHandlingModule,
        RootStoreModule,
        NavigationModule,
        NavigationHistoryModule,
        FirebaseModule,
        BannerModule,
        TagManagerModule,
        NotificationsModule,
        VideoCallsModule,
        ChallengeCompletionModule
    ],
    providers: [
        WINDOW_PROVIDERS,
        ...NATIVE_PROVIDERS,
        {
            provide: ICONS,
            useValue: CustomIcons
        }
    ]
})
export class CoreModule {}
