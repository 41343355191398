import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipe } from '@common/pipes/safe/safe.pipe';
import { IonButton } from '@ionic/angular/standalone';

@Component({
    selector: 'cc-global-message-banner',
    templateUrl: './global-message-banner.component.html',
    styleUrls: ['./global-message-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, TranslateModule, SafePipe, IonButton]
})
export class GlobalMessageBannerComponent {
    @Input()
    public content: string;

    @Input()
    public dismissable: boolean;

    @Output()
    public dismiss = new EventEmitter<void>();
}
