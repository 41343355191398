import { Component, OnInit } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { ModuleService } from '@core/module/services/module/module.service';
import { NavigationHistoryService } from '@core/navigation-history/services/navigation-history.service';
import { NavigationFacadeService } from '@core/navigation/services/navigation-facade.service';
import { NavigationActions } from '@core/navigation/store/actions/navigation.actions';
import { AppRoutingFacadeService } from '@core/routing/services/app-routing-facade.service';
import { PortalConfig } from '@features/portal/interfaces/portal-config.interface';
import { PortalService } from '@features/portal/services/portal.service';
import { IonContent, IonLabel, IonPopover, IonTabBar, IonTabButton, ModalController } from '@ionic/angular/standalone';
import { Module } from '@shared/api';
import { ModuleWithRouteCommands } from '@shared/api/models/ModuleWithRouteCommands';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TabbarMoreComponent } from '../tabbar-more/tabbar-more.component';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ModuleIconComponent } from '../module-icon/module-icon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'cc-tabbar',
    templateUrl: './tabbar.component.html',
    styleUrls: ['./tabbar.component.scss'],
    imports: [
        RouterLink,
        ModuleIconComponent,
        AsyncPipe,
        TranslateModule,
        IonTabBar,
        IonTabButton,
        IonLabel,
        IonContent,
        IonPopover
    ]
})
export class TabbarComponent implements OnInit {
    public modules$: Observable<ModuleWithRouteCommands[]>;
    public tabbarModules$: Observable<ModuleWithRouteCommands[]>;
    public shouldShowLabels$: Observable<boolean>;
    public selectedModuleId$: Observable<number>;
    public portalConfig$: Observable<PortalConfig>;

    constructor(
        private navigationFacadeService: NavigationFacadeService,
        private navigationHistoryService: NavigationHistoryService,
        private appRoutingFacadeService: AppRoutingFacadeService,
        private appCoreFacadeService: AppCoreFacadeService,
        private modalController: ModalController,
        private portalService: PortalService,
        private moduleService: ModuleService
    ) {}

    public ngOnInit(): void {
        this.modules$ = combineLatest([
            this.navigationFacadeService.getModules(),
            this.appCoreFacadeService.getAppName()
        ]).pipe(
            map(([modules, appName]) =>
                modules.map((module) => {
                    // Due to this being in a modal it tries to navigate from root rather than the nested app url route.
                    // So we add the app name in front of the commands.
                    const routeCommands = [appName, ...this.moduleService.getRouterCommandsForModule(module)];

                    return {
                        ...module,
                        routeCommands: routeCommands,
                        children: module.children.map((child) => {
                            const childRouteCommands = [
                                appName,
                                ...this.moduleService.getRouterCommandsForModule(child)
                            ];

                            return {
                                ...child,
                                children: [],
                                routeCommands: childRouteCommands
                            };
                        })
                    };
                })
            )
        );

        this.tabbarModules$ = this.navigationFacadeService.getTabbarModules().pipe(
            map((modules) =>
                modules.map((module) => {
                    const routeCommands = this.moduleService.getRouterCommandsForModule(module);

                    return {
                        ...module,
                        routeCommands: routeCommands,
                        children: module.children.map((child) => {
                            const childRouteCommands = this.moduleService.getRouterCommandsForModule(child);

                            return {
                                ...child,
                                children: [],
                                routeCommands: childRouteCommands
                            };
                        })
                    };
                })
            )
        );

        this.shouldShowLabels$ = this.appCoreFacadeService
            .getAppSettings()
            .pipe(map((app) => !app.menu_settings || app.menu_settings.show_tab_bar_labels));
        this.selectedModuleId$ = this.appRoutingFacadeService.getCurrentModuleId();
        this.portalConfig$ = this.appCoreFacadeService
            .getAppName()
            .pipe(map((appName) => this.portalService.getPortalConfigForApp(appName)));
    }

    public openTermsDialog(): void {
        this.navigationFacadeService.dispatch(NavigationActions.openTermsAndConditions());
    }

    public portalReturn(): void {
        this.navigationFacadeService.dispatch(NavigationActions.portalReturnClick());
    }

    public onModuleClick(): void {
        this.navigationHistoryService.forget();
    }

    public async openMore(modules: Module[], selectedModuleId: number, portalConfig: PortalConfig): Promise<void> {
        const modal = await this.modalController.create({
            component: TabbarMoreComponent,
            componentProps: {
                modules,
                selectedModuleId,
                portalConfig
            }
        });
        await modal.present();
        const modalResponse = await modal.onWillDismiss();

        if (!modalResponse.data) {
            return;
        }

        if (modalResponse.data.terms) {
            this.openTermsDialog();
            return;
        }

        if (modalResponse.data.portal) {
            this.portalReturn();
            return;
        }
    }
}
