<span *ngIf="isOffline" class="offline-indicator">
    <i
        class="offline-indicator-icon material-icons"
        slot="icon-only"
        [tooltip]="'TOOLBAR_RIGHT_OFFLINE_HINT' | translate"
        [attr.aria-label]="'TOOLBAR_RIGHT_OFFLINE_HINT' | translate"
    >
        wifi_off
    </i>
</span>

<ion-button
    *ngIf="updateAvailable"
    class="clear-on-primary update-indicator"
    fill="clear"
    [tooltip]="'TOOLBAR_RIGHT_UPDATE_HINT' | translate"
    [options]="tooltipOptions"
    (click)="onUpdateClick()"
    @fadeIn
>
    <i class="material-icons-outlined" slot="icon-only" [attr.aria-label]="'TOOLBAR_RIGHT_UPDATE_HINT' | translate">
        file_download
    </i>
</ion-button>

<ion-button
    *ngIf="loggedInUser && canChat"
    class="badge-icon clear-on-primary cc-navbar-chat cc-chat-all"
    fill="clear"
    [tooltip]="'TOOLBAR_RIGHT_CHAT_HINT' | translate"
    [options]="tooltipOptions"
    (click)="onChatClick()"
>
    <i class="material-icons" slot="icon-only" [attr.aria-label]="'TOOLBAR_RIGHT_CHAT_HINT' | translate">
        {{ popupOpen ? 'speaker_notes_off' : 'chat' }}
    </i>

    <ion-badge *ngIf="chatBadge > 0" role="alert" [attr.aria-label]="'TOOLBAR_RIGHT_NEW_CHATs_HINT' | translate">
        {{ chatBadge }}
    </ion-badge>
</ion-button>

<ion-button
    *ngIf="loggedInUser"
    class="badge-icon clear-on-primary cc-navbar-alerts cc-alerts-all"
    fill="clear"
    id="alerts-button"
    [tooltip]="'TOOLBAR_RIGHT_ALERTS_HINT' | translate"
    [options]="tooltipOptions"
    (click)="onAlertsClick()"
>
    <i class="material-icons" slot="icon-only" [attr.aria-label]="'TOOLBAR_RIGHT_ALERTS_HINT' | translate">
        notifications
    </i>

    <ion-badge *ngIf="alertsBadge > 0" role="alert" [attr.aria-label]="'TOOLBAR_RIGHT_NEW_ALERTS_HINT' | translate">
        {{ alertsBadge }}
    </ion-badge>
</ion-button>

<ion-button
    class="clear-on-primary"
    fill="clear"
    data-testid="open-sidebar-end"
    [ngSwitch]="!!loggedInUser"
    (click)="onUserClick()"
>
    <cc-person-icon
        *ngSwitchCase="true"
        slot="icon-only"
        size="xsmall"
        [attr.aria-label]="'TOOLBAR_RIGHT_PROFILE_HINT' | translate"
        [person]="loggedInUser"
    ></cc-person-icon>

    <i class="material-icons" aria-hidden="true"> expand_more </i>

    <i
        *ngSwitchCase="false"
        class="material-icons"
        slot="icon-only"
        [attr.aria-label]="'TOOLBAR_RIGHT_LOGIN_HINT' | translate"
    >
        person
    </i>
</ion-button>
