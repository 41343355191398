import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieConfigurationState } from '@core/banner/interfaces/cookie-configuration-state.interface';
import { BannerFacadeService } from '@core/banner/services/banner-facade/banner-facade.service';
import { BannerActions } from '@core/banner/store/actions/banner.actions';
import { TosDialogService } from '@core/tos/services/tos-dialog/tos-dialog.service';
import { NgIf, UpperCasePipe } from '@angular/common';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { TranslateModule } from '@ngx-translate/core';
import { IonButton, IonLabel, IonToggle } from '@ionic/angular/standalone';

@Component({
    selector: 'cc-cookie-customisation-banner',
    templateUrl: './cookie-customisation-banner.component.html',
    styleUrls: ['./cookie-customisation-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        UpperCasePipe,
        TranslateModule,
        IonLabel,
        IonToggle,
        IonButton
    ]
})
export class CookieCustomisationBannerComponent implements OnInit {
    @Input()
    public cookieConfig: CookieConfigurationState;

    public cookieForm: UntypedFormGroup;

    constructor(
        private bannerFacadeService: BannerFacadeService,
        private formBuilder: UntypedFormBuilder,
        private tosDialogService: TosDialogService
    ) {}

    public ngOnInit(): void {
        this.cookieForm = this.formBuilder.group({
            allow_functional: this.formBuilder.control(!!this.cookieConfig.allow_functional),
            allow_performance: this.formBuilder.control(!!this.cookieConfig.allow_performance),
            allow_analytics: this.formBuilder.control(!!this.cookieConfig.allow_analytics),
            allow_advertising: this.formBuilder.control(!!this.cookieConfig.allow_advertising)
        });
    }

    public acceptCookies(): void {
        this.bannerFacadeService.dispatch(BannerActions.acceptAllCookies());
    }

    public saveSelection(): void {
        this.bannerFacadeService.dispatch(
            BannerActions.saveSelectedCookies({ cookies: { ...this.cookieForm.value, cookies_dismissed: true } })
        );
    }

    public showCookieNotice(e: MouseEvent): void {
        e.preventDefault();
        e.stopPropagation();
        this.tosDialogService.showCookieNotice();
    }
}
