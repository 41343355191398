import { of, timer, asyncScheduler, expand, mapTo } from 'rxjs';
import { exponentialBackoffDelay, getDelay } from '../utils';
export function intervalBackoff(config, scheduler = asyncScheduler) {
  let {
    initialInterval,
    maxInterval = Infinity,
    backoffDelay = exponentialBackoffDelay
  } = typeof config === 'number' ? {
    initialInterval: config
  } : config;
  initialInterval = initialInterval < 0 ? 0 : initialInterval;
  return of(0, scheduler).pipe(expand(iteration => timer(getDelay(backoffDelay(iteration, initialInterval), maxInterval)).pipe(mapTo(iteration + 1))));
}