import 'hammerjs';
import { environment } from './environments/environment';
import { enableProdMode, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './app/core/authentication/interceptors/auth.interceptor';
import { MfaInterceptor } from '@core/authentication/interceptors/mfa.interceptor';
import { LanguageInterceptor } from './app/core/i18n/interceptors/language.interceptor';
import { RateLimitInterceptor } from '@core/error-handling/interceptors/rate-limit.interceptor';
import { ErrorHandlingInterceptor } from './app/core/error-handling/interceptors/error-handling.interceptor';
import { UnauthorisedInterceptor } from '@core/error-handling/interceptors/unauthorised.interceptor';
import { DeviceInterceptor } from './app/core/device/interceptors/device.interceptor';
import { OneSignalInterceptor } from '@core/native/interceptors/one-signal.interceptor';
import { ServerUnresponsiveInterceptor } from '@core/error-handling/interceptors/server-unresponsive.interceptor';
import { APP_BASE_HREF, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppConfigService } from './app/app-config.service';
import { HrefInterceptorService } from './app/core/browser-utilities/services/href-interceptor.service';
import { provideAngularQuery, QueryClient } from '@tanstack/angular-query-experimental';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CoreModule } from './app/core/core.module';
import { MissingTranslationHandler, provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { MissingTranslationHelper } from './app/core/i18n/missing-translation-handler';
import { provideQuillConfig } from 'ngx-quill';
import { provideServiceWorker } from '@angular/service-worker';
import { AppComponent } from './app/app.component';
import { I18nPruningTranslationLoader } from '@core/i18n/services/i18n-pruning-translation-loader';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { Version, BuiltAt } from './environments/version';

if (environment.PRODUCTION) {
    enableProdMode();
}

(window as any).CONFIG = environment as any;
(window as any).VERSION = environment.VERSION;

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(CoreModule),
        provideServiceWorker(`${window.location.origin}/ngsw-worker.js`, {
            enabled: environment.PWA_ENABLED,
            registrationStrategy: 'registerWhenStable:30000'
        }),
        provideTranslateService({
            loader: {
                provide: TranslateLoader,
                useClass: I18nPruningTranslationLoader
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationHelper }
        }),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MfaInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LanguageInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RateLimitInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlingInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorisedInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DeviceInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OneSignalInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerUnresponsiveInterceptor,
            multi: true
        },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        provideAppInitializer(async () => {
            const appConfigService = inject(AppConfigService);
            await appConfigService.loadAppConfig();
        }),
        provideAppInitializer(() => {
            inject(HrefInterceptorService);
        }),
        { provide: APP_BASE_HREF, useValue: window.location.origin },
        provideAngularQuery(new QueryClient()),
        provideCacheableAnimationLoader(),
        provideLottieOptions({ player: () => import('lottie-web') }),
        provideAnimations(),
        provideQuillConfig({}),
        provideIonicAngular({
            mode: 'md'
        })
    ]
}).catch((err) => console.error(err));

console.table({
    Platform: 'Delegate App',
    'Environment Name': environment.name,
    Version: require('../package.json').version,
    'Commit Hash': Version,
    'Last Updated': new Date(parseInt(BuiltAt, 10))
});
