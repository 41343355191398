import { Injectable } from '@angular/core';
import { RealtimeActions } from '@core/root-store/store/realtime/actions/realtime.actions';
import { ChallengeCompletionModalService } from '@features/challenge-completion/services/challenge-completion-modal/challenge-completion-modal.service';
import { ChallengeCompletionRealtimeService } from '@features/challenge-completion/services/challenge-completion-realtime/challenge-completion-realtime.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, takeUntil } from 'rxjs';
import { ChallengeCompletionActions } from '../actions/challenge-completion.actions';

@Injectable()
export class ChallengeCompletionEffects {
    listenForChallengeCompletions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RealtimeActions.connectedToPrivateChannel),
            switchMap(() =>
                this.challengeCompletionRealtimeService.getRealtimeStream().pipe(
                    takeUntil(this.actions$.pipe(ofType(RealtimeActions.disconnectedFromPrivateChannel))),
                    map((completion) =>
                        ChallengeCompletionActions.challengeCompleted({
                            completion,
                            challengeId: completion.challenge
                        })
                    )
                )
            )
        )
    );

    challengeCompleted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChallengeCompletionActions.challengeCompleted),
                filter(({ completion }) =>
                    this.challengeCompletionModalService.shouldHandleChallengeCompletion(completion)
                ),
                switchMap(({ completion }) =>
                    this.challengeCompletionModalService.openChallengeCompletedModal({ completion })
                )
            ),
        { dispatch: false }
    );

    challengeAlreadyCompleted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChallengeCompletionActions.challengeAlreadyCompleted),
                switchMap(() => this.challengeCompletionModalService.openChallengeAlreadyCompletedModal())
            ),
        { dispatch: false }
    );

    challengeFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChallengeCompletionActions.challengeFailed),
                switchMap(() => this.challengeCompletionModalService.openChallengeFailedModal())
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private challengeCompletionRealtimeService: ChallengeCompletionRealtimeService,
        private challengeCompletionModalService: ChallengeCompletionModalService
    ) {}
}
