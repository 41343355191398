import { Injectable } from '@angular/core';
import { BannerFacadeService } from '@core/banner/services/banner-facade/banner-facade.service';
import { BannerActions } from '@core/banner/store/actions/banner.actions';
import { DeviceService } from '@core/device/services/device.service';
import { PwaInstallService } from '@core/pwa/services/pwa-install/pwa-install.service';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { TosDialogService } from '@core/tos/services/tos-dialog/tos-dialog.service';
import { PeopleFacadeService } from '@features/people/services/people-facade.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ContainerService } from '../../../features/container/services/container.service';
import { AppCoreFacadeService } from '../../app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '../../authentication/services/authentication-facade.service';
import { I18nService } from '../../i18n/services/i18n.service';
import { AppRoutingActions } from '../../root-store/store/app-routing/actions/app-routing.actions';
import { AppPageRoutes } from '../../routing/constants/app-page-routes.constant';
import { PageRoutes } from '../../routing/constants/page-routes.constant';
import { AppUserPageRoutes } from '../../routing/constants/user-page-routes.constant';

@Injectable({
    providedIn: 'root'
})
export class SidebarRightItemsService {
    constructor(
        private i18nService: I18nService,
        private containerService: ContainerService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private appCoreFacadeService: AppCoreFacadeService,
        private pwaInstallService: PwaInstallService,
        private bannerFacadeService: BannerFacadeService,
        private deviceService: DeviceService,
        private tosDialogService: TosDialogService,
        private peopleFacadeService: PeopleFacadeService
    ) {}

    getRightSidebarItems(): Observable<Array<RightSidebarItem>> {
        return this.getRightSidebarData().pipe(
            map((data) => [
                {
                    icon: 'today',
                    translateKey: 'APP_SIDEBAR_RIGHT_MY_SCHEDULE',
                    className: 'schedule',
                    onClick: () =>
                        this.appCoreFacadeService.dispatch(
                            AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.personalAgenda] })
                        ),
                    show: () => data.myScheduleEnabled
                },
                {
                    icon: 'bookmark',
                    translateKey: 'APP_SIDEBAR_RIGHT_NOTES',
                    className: 'notes',
                    onClick: () =>
                        this.appCoreFacadeService.dispatch(
                            AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.notes] })
                        ),
                    show: () => data.isAuthed
                },
                {
                    icon: 'groups',
                    translateKey: 'APP_SIDEBAR_RIGHT_MEETINGS',
                    className: 'meetings',
                    show: () => data.meetingBookingEnabled,
                    onClick: () =>
                        this.appCoreFacadeService.dispatch(
                            AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.meetings] })
                        )
                },
                {
                    icon: 'recent_actors',
                    translateKey: 'APP_SIDEBAR_RIGHT_BUSINESS_CARDS',
                    className: 'business-cards',
                    onClick: () =>
                        this.appCoreFacadeService.dispatch(
                            AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.businessCards] })
                        ),
                    show: () => data.businessCardsEnabled
                },
                {
                    icon: 'badge',
                    translateKey: 'APP_SIDEBAR_RIGHT_BADGE_CONTACTS',
                    className: 'badge-contacts',
                    onClick: () =>
                        this.appCoreFacadeService.dispatch(
                            AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.badgeContacts] })
                        ),
                    show: () => data.badgesEnabled
                },
                {
                    icon: 'list',
                    translateKey: 'APP_SIDEBAR_RIGHT_MY_SURVEYS',
                    className: 'surveys',
                    onClick: () =>
                        this.appCoreFacadeService.dispatch(
                            AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.surveys] })
                        ),
                    show: () => data.isAuthed
                },
                {
                    icon: 'qr_code_2',
                    translateKey: 'APP_SIDEBAR_RIGHT_MY_BADGE',
                    className: 'badge',
                    onClick: () =>
                        this.appCoreFacadeService.dispatch(
                            AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.badge] })
                        ),
                    show: () => data.myBadgeEnabled
                },
                {
                    icon: 'person',
                    translateKey: 'APP_SIDEBAR_RIGHT_LOGIN',
                    className: 'login',
                    onClick: () =>
                        this.appCoreFacadeService.dispatch(
                            AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.login] })
                        ),
                    show: () => data.isAuthed.pipe(map((isAuthed) => !isAuthed)),
                    testId: 'sidebar-end-login'
                },
                {
                    icon: 'person',
                    translateKey: 'APP_SIDEBAR_RIGHT_MY_PROFILE',
                    className: 'profile',
                    onClick: () =>
                        this.appCoreFacadeService.dispatch(
                            AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.profile] })
                        ),
                    show: () => data.isAuthed
                },
                {
                    icon: 'download',
                    translateKey: 'APP_SIDEBAR_RIGHT_INSTALL',
                    className: 'install',
                    onClick: () => this.pwaInstallService.promptDownload(),
                    show: () => this.pwaInstallService.canInstall$
                },
                {
                    icon: 'settings',
                    translateKey: 'APP_SIDEBAR_RIGHT_ACCOUNT',
                    className: 'account',
                    onClick: () =>
                        this.appCoreFacadeService.dispatch(
                            AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.account] })
                        ),
                    show: () => data.isAuthed
                },
                {
                    icon: 'apps',
                    translateKey: 'APP_SIDEBAR_RIGHT_CONTAINER',
                    className: 'container',
                    onClick: () => {
                        this.containerService.resetApp();
                        this.appCoreFacadeService.dispatch(
                            AppRoutingActions.goToPage({ urlSegments: [PageRoutes.container] })
                        );
                    },
                    show: () => data.isRunningInContainer
                },
                {
                    icon: '',
                    translateKey: 'APP_SIDEBAR_RIGHT_CHANGE_LANGUAGE',
                    className: 'language',
                    show: () => data.showLanguages
                },
                {
                    icon: 'policy',
                    translateKey: 'APP_SIDEBAR_RIGHT_COOKIE_CONSENT',
                    className: 'cookie-consent',
                    onClick: () =>
                        this.bannerFacadeService.dispatch(BannerActions.showCookiesCustomisation({ show: true })),
                    show: () => of(!this.deviceService.isIos())
                },
                {
                    icon: 'policy',
                    translateKey: 'APP_SIDEBAR_RIGHT_COOKIE_NOTICE',
                    className: 'cookie-notice',
                    onClick: () => this.tosDialogService.showCookieNotice(),
                    show: () => of(this.deviceService.isIos())
                },
                {
                    icon: 'exit_to_app',
                    translateKey: 'APP_SIDEBAR_RIGHT_LOGOUT',
                    className: 'log-out',
                    onClick: () => this.authenticationFacadeService.dispatch(AuthenticationActions.logout()),
                    show: () => data.isAuthed
                }
            ])
        );
    }

    private getRightSidebarData(): Observable<RightSidebarData> {
        return combineLatest([
            this.appCoreFacadeService.getAppName(),
            this.authenticationFacadeService.getAuthenticatedPerson(),
            this.peopleFacadeService.getVisibility()
        ]).pipe(
            map(([appName, currentUser]) => {
                const badgesEnabled$ = this.appCoreFacadeService
                    .getAppSettings()
                    .pipe(map((app) => app && app.badges_enabled && !!currentUser));

                const myBadgeEnabled$ = badgesEnabled$.pipe(
                    switchMap((badgesEnabled) =>
                        this.peopleFacadeService
                            .getVisibility()
                            .pipe(
                                map(
                                    (peopleVisibility) => badgesEnabled && (!peopleVisibility || peopleVisibility.badge)
                                )
                            )
                    )
                );

                const meetingBookingEnabled$ = this.appCoreFacadeService
                    .getAppSettings()
                    .pipe(
                        map(
                            (app) =>
                                app?.mbookings_enabled &&
                                currentUser?.meeting_booking_enabled &&
                                currentUser.meeting_booking_allowed
                        )
                    );

                const myScheduleEnabled$ = this.appCoreFacadeService
                    .getAppSettings()
                    .pipe(map((app) => app?.enable_add_to_schedule && !!currentUser));

                const businessCardsEnabled =
                    currentUser?.business_cards_enabled && currentUser?.can_exchange_business_cards;

                const isRunningInContainer = this.containerService.isRunningInContainer(appName);

                return {
                    isAuthed: of(!!currentUser),
                    badgesEnabled: badgesEnabled$,
                    myBadgeEnabled: myBadgeEnabled$,
                    businessCardsEnabled: of(businessCardsEnabled),
                    isRunningInContainer: of(isRunningInContainer),
                    showLanguages: of(this.i18nService.languages.length > 1),
                    meetingBookingEnabled: meetingBookingEnabled$,
                    myScheduleEnabled: myScheduleEnabled$
                };
            })
        );
    }
}

export interface RightSidebarItem {
    icon: string;
    translateKey: string;
    className: string;
    onClick?: () => void;
    show?: () => Observable<boolean>;
    testId?: string;
}

export interface RightSidebarItems {
    items: Array<RightSidebarItem>;
    footerItems: Array<RightSidebarItem>;
}

export interface RightSidebarData {
    isAuthed: Observable<boolean>;
    badgesEnabled: Observable<boolean>;
    myBadgeEnabled: Observable<boolean>;
    businessCardsEnabled: Observable<boolean>;
    isRunningInContainer: Observable<boolean>;
    showLanguages: Observable<boolean>;
    meetingBookingEnabled: Observable<boolean>;
    myScheduleEnabled: Observable<boolean>;
}
