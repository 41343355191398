<ion-menu side="end" menuId="right" contentId="app-content" id="right-menu">
    <cc-sidebar-right [appUrl]="app.url_name" aria-label="Profile navigation"></cc-sidebar-right>
</ion-menu>

<ion-split-pane
    id="split-pane"
    when="(min-width: 1200px)"
    class="app__split-pane"
    contentId="app-content"
    [ngClass]="{ 'has-tabbar': shouldShowTabbar && hasAccess }"
    [disabled]="!shouldShowSidebar"
>
    <ion-menu class="menu-left" contentId="app-content" side="start" menuId="left" id="left-menu">
        <nav aria-label="Main Navigation">
            <cc-sidebar-left-container></cc-sidebar-left-container>
        </nav>
    </ion-menu>
    <div class="app__content app-content" id="app-content" [ngClass]="language ? 'translate-' + language : ''">
        <header class="cc-cms-body-header" *ngIf="shouldShowToolbar">
            <cc-toolbar [shouldShowSidebar]="shouldShowSidebar"></cc-toolbar>
        </header>
        <main class="app__body" tabindex="-1" [attr.aria-label]="'MAIN_ARIA_LABEL' | translate">
            <div
                class="app__background"
                [ngStyle]="
                    app.theme.background_image && {
                        'background-image': 'url(' + app.theme.background_image + '?d=1920x1080)'
                    }
                "
            >
                <router-outlet></router-outlet>
            </div>
        </main>
        <footer *ngIf="shouldShowBanner">
            <cc-global-message-banner
                class="app-page__global-banner"
                [content]="globalBannerConfig.content"
                [dismissable]="globalBannerConfig.dismissable"
                (dismiss)="dismissBanner.emit()"
            ></cc-global-message-banner>
        </footer>
    </div>
</ion-split-pane>

<cc-cookie-banner></cc-cookie-banner>

<cc-tabbar *ngIf="shouldShowTabbar && hasAccess"></cc-tabbar>

<cc-notifications></cc-notifications>

@if (popupChatOpen) {
    <div class="popup-chat__container" @slideInOutFromBottom>
        <cc-chat-popup></cc-chat-popup>
    </div>
}
