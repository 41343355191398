import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { GlobalBannerState } from '@core/banner/store/models/banner.state.model';
import { App } from '@shared/api';
import { slideInOutFromBottom } from '@common/animations/animations';
import { SidebarRightComponent } from '@core/navigation/components/sidebar-right/sidebar-right.component';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { SidebarLeftContainerComponent } from '@core/navigation/components/sidebar-left-container/sidebar-left-container.component';
import { ToolbarComponent } from '@core/navigation/components/toolbar/toolbar.component';
import { RouterOutlet } from '@angular/router';
import { GlobalMessageBannerComponent } from '@core/banner/components/global-message-banner/global-message-banner.component';
import { CookieBannerComponent } from '@core/banner/components/cookie-banner/cookie-banner.component';
import { TabbarComponent } from '@core/navigation/components/tabbar/tabbar.component';
import { NotificationsComponent } from '@core/notifications/components/notifications/notifications.component';
import { ChatPopupComponent } from '@features/chat/smart-components/chat-popup/chat-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonMenu, IonSplitPane } from '@ionic/angular/standalone';

@Component({
    selector: 'cc-app-layout',
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [slideInOutFromBottom()],
    imports: [
        SidebarRightComponent,
        NgClass,
        SidebarLeftContainerComponent,
        NgIf,
        ToolbarComponent,
        NgStyle,
        RouterOutlet,
        GlobalMessageBannerComponent,
        CookieBannerComponent,
        TabbarComponent,
        NotificationsComponent,
        ChatPopupComponent,
        TranslateModule,
        IonMenu,
        IonSplitPane
    ]
})
export class AppLayoutComponent implements OnChanges {
    @Input()
    public app: App;

    @Input()
    public language: string;

    @Input()
    public hasAccess: boolean;

    @Input()
    public shouldShowToolbar: boolean;

    @Input()
    public shouldShowSidebar: boolean;

    @Input()
    public shouldShowTabbar: boolean;

    @Input()
    public globalBannerConfig: GlobalBannerState;

    @Input()
    public popupChatOpen: boolean;

    @Output()
    public dismissBanner = new EventEmitter<void>();

    public shouldShowBanner;

    public ngOnChanges(): void {
        this.shouldShowBanner =
            this.hasAccess &&
            this.globalBannerConfig.enabled &&
            (!this.globalBannerConfig.dismissable || !this.globalBannerConfig.hasDismissed);
    }
}
