import { Action, createReducer, on } from '@ngrx/store';
import { GlobalSettingsActions } from '../actions/global-settings.actions';
import { GlobalSettingsState } from '../models/global-settings-state.model';

export const initialState: GlobalSettingsState = {
    meetingBookingConfig: {
        meeting_start: '00:00',
        meeting_end: '23:59',
        meeting_type: undefined,
        min_length: 10,
        max_length: 0,
        smart_sessions_allowed: false,
        meetings_start_date: undefined,
        meetings_end_date: undefined
    },
    businessCardsSettings: undefined
};

const globalSettingsReducerFn = createReducer(
    initialState,
    on(GlobalSettingsActions.fetchMeetingBookingConfigSuccess, (state, { config }) => ({
        ...state,
        meetingBookingConfig: {
            ...config,
            meeting_start: config.meeting_start?.slice(0, -3),
            meeting_end: config.meeting_end === '00:00:00' ? '23:59' : config.meeting_end?.slice(0, -3)
        }
    })),
    on(GlobalSettingsActions.fetchUserBusinessCardsConfigSuccess, (state, { settings }) => ({
        ...state,
        businessCardsSettings: settings
    }))
);

export function globalSettingsReducer(state: GlobalSettingsState | undefined, action: Action): GlobalSettingsState {
    return globalSettingsReducerFn(state, action);
}
