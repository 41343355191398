import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeIn } from '@common/animations/animations';
import { People } from '@shared/api';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive-major-angular-updates';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { PersonIconComponent } from '@common/components/person-icon/person-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonBadge, IonButton } from '@ionic/angular/standalone';

@Component({
    selector: 'cc-toolbar-right',
    templateUrl: './toolbar-right.component.html',
    styleUrls: ['./toolbar-right.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeIn()],
    imports: [NgIf, TooltipModule, NgSwitch, NgSwitchCase, PersonIconComponent, TranslateModule, IonButton, IonBadge]
})
export class ToolbarRightComponent {
    @Input()
    public loggedInUser: People;

    @Input()
    public alertsBadge: number;

    @Input()
    public popupOpen: boolean;

    @Input()
    public chatBadge: number;

    @Input()
    public canChat: boolean;

    @Input()
    public updateAvailable: boolean;

    @Input()
    public isOffline: boolean | null;

    @Output()
    public chatClick = new EventEmitter<void>();

    @Output()
    public alertsClick = new EventEmitter<void>();

    @Output()
    public userClick = new EventEmitter<void>();

    @Output()
    public updateClick = new EventEmitter<void>();

    public tooltipOptions: TooltipOptions = {
        placement: 'bottom',
        'hide-delay': 0,
        'animation-duration': 0
    };

    public onChatClick(): void {
        this.chatClick.emit();
    }

    public onAlertsClick(): void {
        this.alertsClick.emit();
    }

    public onUserClick(): void {
        this.userClick.emit();
    }

    public onUpdateClick(): void {
        this.updateClick.emit();
    }
}
