import { Component, Input, OnInit } from '@angular/core';
import { SidebarType } from '@core/navigation/enums/sidebar-type.enum';
import { SidebarService } from '@core/navigation/services/sidebar.service';
import { NetworkStatusService } from '@core/pwa/services/network-status/network-status.service';
import { People } from '@shared/api';
import { Observable } from 'rxjs';
import { AlertsFacadeService } from '@features/alerts/services/alerts-facade.service';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { NavigationHistoryService } from '@core/navigation-history/services/navigation-history.service';
import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';
import { AppUserPageRoutes } from '@core/routing/constants/user-page-routes.constant';
import { ToolbarLeftType } from '../../enums/toolbar-left-type.enum';
import { ToolbarService } from '../../services/toolbar.service';
import { PwaUpdateService } from '@core/pwa/services/pwa-update/pwa-update.service';
import { ChatPopupService } from '@features/chat/services/chat-popup/chat-popup.service';
import { ChatService } from '@features/chat/services/chat/chat.service';
import { NgIf, AsyncPipe } from '@angular/common';
import { ToolbarLeftComponent } from '../toolbar-left/toolbar-left.component';
import { ToolbarRightComponent } from '../toolbar-right/toolbar-right.component';
import { IonHeader, IonToolbar } from '@ionic/angular/standalone';

@Component({
    selector: 'cc-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    imports: [NgIf, ToolbarLeftComponent, ToolbarRightComponent, AsyncPipe, IonToolbar, IonHeader]
})
export class ToolbarComponent implements OnInit {
    @Input()
    public shouldShowSidebar: boolean;

    /* Toolbar left*/
    public showMenuButton$: Observable<boolean>;
    public menuButtonType$: Observable<ToolbarLeftType>;
    public canGoBack$: Observable<boolean>;
    public isOffline$: Observable<boolean>;
    public popupOpen$: Observable<boolean>;

    /* Toolbar right */
    public chatBadge$: Observable<number>;
    public alertsBadge$: Observable<number>;
    public loggedInUser$: Observable<People>;
    public canChat$: Observable<boolean>;
    public updateAvailable$: Observable<boolean>;

    constructor(
        private toolbarService: ToolbarService,
        private chatService: ChatService,
        private alertsFacadeService: AlertsFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private sidebarService: SidebarService,
        private navigationHistoryService: NavigationHistoryService,
        private appCoreFacadeService: AppCoreFacadeService,
        private pwaUpdateService: PwaUpdateService,
        private networkStatusService: NetworkStatusService,
        private chatPopupService: ChatPopupService
    ) {}

    public ngOnInit(): void {
        this.menuButtonType$ = this.toolbarService.getMenuButtonType();
        this.showMenuButton$ = this.toolbarService.getShowMenuButton();
        this.canGoBack$ = this.navigationHistoryService.canGoBack();
        this.isOffline$ = this.networkStatusService.isOffline$;
        this.updateAvailable$ = this.pwaUpdateService.updateAvailable$;
        this.popupOpen$ = this.chatPopupService.isOpen$;

        this.chatBadge$ = this.chatService.badgeNumber$;
        this.alertsBadge$ = this.alertsFacadeService.getBadgeNumber();
        this.loggedInUser$ = this.authenticationFacadeService.getAuthenticatedPerson();

        this.canChat$ = this.chatService.loggedInUserCanChat$();
    }

    public onMenuButtonClick(type: ToolbarLeftType): void {
        this.toolbarService.handleMenuButtonClick(type);
    }

    public onBackClick(): void {
        this.navigationHistoryService.back();
    }

    public onChatClick(popupChatOpen: boolean): void {
        if (popupChatOpen) {
            this.chatPopupService.close();
        } else {
            this.appCoreFacadeService.dispatch(
                AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.chat] })
            );
        }
    }

    public onAlertsClick(): void {
        this.appCoreFacadeService.dispatch(
            AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.alerts] })
        );
    }

    public onUserClick(): void {
        this.sidebarService.open(SidebarType.Right);
    }

    public onUpdateClick(): void {
        this.pwaUpdateService.updateApp();
    }
}
