import { Injectable } from '@angular/core';
import { UserAuthInfo } from '@shared/api/models/UserAuthInfo';

@Injectable({
    providedIn: 'root'
})
export class PiiService {
    public stripPii(userAuthInfo: UserAuthInfo): UserAuthInfo {
        return {
            ...userAuthInfo,
            user: { ...userAuthInfo.user, first_name: undefined, last_name: undefined, email: undefined }
        };
    }
}
