import { NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { initializeAuth, inMemoryPersistence, provideAuth } from '@angular/fire/auth';

@NgModule({
    providers: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideDatabase(() => getDatabase()),
        provideAuth(() =>
            initializeAuth(getApp(), {
                persistence: inMemoryPersistence
            })
        )
    ]
})
export class FirebaseModule {}
