@if (
    {
        modules: modules$ | async,
        tabbarModules: tabbarModules$ | async,
        shouldShowLabels: shouldShowLabels$ | async,
        selectedModuleId: selectedModuleId$ | async,
        portalConfig: portalConfig$ | async
    };
    as tabbarData
) {
    <ion-tab-bar color="sidebar" class="tabbar__container">
        @for (module of tabbarData.tabbarModules; track module.id) {
            @if (module.type === 'folder') {
                @let tabbarItemId = 'tabbar-item-' + module.id;

                <ion-tab-button class="tabbar__item" [id]="tabbarItemId">
                    <cc-module-icon [module]="module" [attr.aria-label]="module.name"></cc-module-icon>
                    @if (tabbarData.shouldShowLabels) {
                        <ion-label class="tabbar__label">{{ module.name }}</ion-label>
                    }
                </ion-tab-button>
                <ion-popover
                    side="top"
                    alignment="center"
                    size="auto"
                    triggerAction="click"
                    dismiss-on-select="true"
                    [trigger]="tabbarItemId"
                >
                    <ng-template>
                        <ion-content>
                            <div class="popover-container">
                                @for (child of module.children; track child.id) {
                                    <ion-tab-button
                                        class="child-item"
                                        [selected]="tabbarData.selectedModuleId === child.id"
                                        [routerLink]="child.routeCommands"
                                        (click)="onModuleClick()"
                                    >
                                        <div class="child-item__container">
                                            <cc-module-icon
                                                [module]="child"
                                                [attr.aria-label]="child.name"
                                            ></cc-module-icon>
                                            @if (tabbarData.shouldShowLabels) {
                                                <ion-label class="tabbar__label">{{ child.name }}</ion-label>
                                            }
                                        </div>
                                    </ion-tab-button>
                                }
                            </div>
                        </ion-content>
                    </ng-template>
                </ion-popover>
            } @else {
                <ion-tab-button
                    class="tabbar__item"
                    [selected]="tabbarData.selectedModuleId === module.id"
                    [routerLink]="module.routeCommands"
                    (click)="onModuleClick()"
                >
                    <cc-module-icon [module]="module" [attr.aria-label]="module.name"></cc-module-icon>
                    @if (tabbarData.shouldShowLabels) {
                        <ion-label class="tabbar__label">{{ module.name }}</ion-label>
                    }
                </ion-tab-button>
            }
        }

        <ion-tab-button
            class="tabbar__item"
            (click)="openMore(tabbarData.modules, tabbarData.selectedModuleId, tabbarData.portalConfig)"
        >
            <i aria-hidden="true" class="material-icons tabbar-icon">more_horiz</i>
            @if (tabbarData.shouldShowLabels) {
                <ion-label>{{ 'TABBAR_MORE' | translate }}</ion-label>
            }
        </ion-tab-button>
    </ion-tab-bar>
}
