import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { WINDOW } from '@core/window.service';
import { debounceTime, delay, filter, take } from 'rxjs/operators';
import { DeviceService } from '../../device/services/device.service';
import { CapacitorService } from './capacitor.service';
import Color from 'color';
import { Style } from '@capacitor/status-bar';
import { SplashScreenService } from '@core/native/services/splash-screen.service';

@Injectable({
    providedIn: 'root'
})
export class StatusBarService {
    constructor(
        private deviceService: DeviceService,
        private capacitorService: CapacitorService,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
        private splashScreenService: SplashScreenService
    ) {}

    initialise(): void {
        if (!this.deviceService.isNative() || !this.capacitorService.isPluginAvailable('StatusBar')) {
            return;
        }

        if (this.deviceService.isIos()) {
            this.subscribeToRouteChanges();
        } else {
            this.handleAndroid();
        }
    }

    subscribeToRouteChanges(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                debounceTime(400) // Saves running this when someone is clicking around
            )
            .subscribe(() => this.checkStatusbarStyle());
    }

    private checkStatusbarStyle(): void {
        const buttonElement = this.document.querySelector('cc-toolbar ion-toolbar ion-button');
        if (!buttonElement) {
            return;
        }

        const computedStyle = this.window.getComputedStyle(buttonElement);
        const computedColor = computedStyle.getPropertyValue('color');
        const color = Color(computedColor);
        const isLight = color.isLight();
        const statusBarStyle = isLight ? Style.Dark : Style.Light;

        this.capacitorService.statusBar.setStyle({ style: statusBarStyle });
    }

    private async handleAndroid(): Promise<void> {
        // We have to wait for splash screen to be hidden before messing with the status bar
        this.splashScreenService.splashScreenVisible$
            .pipe(
                filter((visible) => !visible),
                take(1),
                delay(2000) // 2 seconds should be enough...
            )
            .subscribe(() => {
                this.capacitorService.statusBar.setStyle({ style: Style.Light });
                this.capacitorService.edgeToEdge.setBackgroundColor({ color: '#ffffff' });
            });
    }
}
