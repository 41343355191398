import { Component, OnInit } from '@angular/core';
import { CookieConfigurationState } from '@core/banner/interfaces/cookie-configuration-state.interface';
import { BannerFacadeService } from '@core/banner/services/banner-facade/banner-facade.service';
import { BannerActions } from '@core/banner/store/actions/banner.actions';
import { Observable } from 'rxjs/internal/Observable';
import { AsyncPipe, NgIf, UpperCasePipe } from '@angular/common';
import { CookieCustomisationBannerComponent } from '../cookie-customisation-banner/cookie-customisation-banner.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonButton } from '@ionic/angular/standalone';

@Component({
    selector: 'cc-cookie-banner',
    templateUrl: './cookie-banner.component.html',
    styleUrls: ['./cookie-banner.component.scss'],
    imports: [NgIf, CookieCustomisationBannerComponent, AsyncPipe, UpperCasePipe, TranslateModule, IonButton]
})
export class CookieBannerComponent implements OnInit {
    public showCustomise$: Observable<boolean>;
    public cookieConfig$: Observable<CookieConfigurationState>;

    constructor(private bannerFacadeService: BannerFacadeService) {}

    public ngOnInit(): void {
        this.cookieConfig$ = this.bannerFacadeService.getCookieConfig();
        this.showCustomise$ = this.bannerFacadeService.selectShowCookieCustomisation();
    }

    public acceptCookies(): void {
        this.bannerFacadeService.dispatch(BannerActions.acceptAllCookies());
    }

    public customiseCookies(): void {
        this.bannerFacadeService.dispatch(BannerActions.showCookiesCustomisation({ show: true }));
    }
}
