import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { People } from '@api';
import { PersonActionService } from '@features/people/services/person-action/person-action.service';
import { switchMap } from 'rxjs/operators';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { ChatPopupService } from '@features/chat/services/chat-popup/chat-popup.service';
import { PopupChatState } from '@features/chat/types/popup-chat-state.type';
import { PopupChatView } from '@features/chat/constants/popup-chat-view.constant';
import { AppUserPageRoutes } from '@core/routing/constants/user-page-routes.constant';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';
import { RouterLink } from '@angular/router';
import { PersonNameComponent } from '@common/components/person-name/person-name.component';
import { ChatListComponent } from '../chat-list/chat-list.component';
import { ChatDetailComponent } from '../chat-detail/chat-detail.component';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonButton, IonButtons, IonTitle, IonToolbar } from '@ionic/angular/standalone';

@Component({
    selector: 'cc-chat-popup',
    templateUrl: './chat-popup.component.html',
    styleUrls: ['./chat-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLink,
        PersonNameComponent,
        ChatListComponent,
        ChatDetailComponent,
        AsyncPipe,
        TranslateModule,
        IonToolbar,
        IonButtons,
        IonTitle,
        IonButton
    ]
})
export class ChatPopupComponent implements OnInit {
    state$: Observable<PopupChatState>;
    chatId$: Observable<string | null>;
    detailPeer$: Observable<People | null>;
    canVideoCall$: Observable<boolean>;
    appUrl$: Observable<string>;

    loadedPeer = new BehaviorSubject<People | null>(null);

    popupChatView = PopupChatView;
    appUserRoutes = AppUserPageRoutes;
    appPageRoutes = AppPageRoutes;

    constructor(
        private personActionService: PersonActionService,
        private appCoreFacadeService: AppCoreFacadeService,
        private chatPopupService: ChatPopupService
    ) {}

    public ngOnInit(): void {
        this.state$ = this.chatPopupService.state$;
        this.appUrl$ = this.appCoreFacadeService.getAppName();
        this.detailPeer$ = this.loadedPeer.asObservable();

        this.canVideoCall$ = this.detailPeer$.pipe(switchMap((peer) => this.personActionService.canVideoCall(peer)));
    }

    public videoCall(peer: People): void {
        this.personActionService.videoCall(peer);
    }

    public onPeerLoaded(peer: People): void {
        this.loadedPeer.next(peer);
    }

    public close(): void {
        this.chatPopupService.close();
    }
}
