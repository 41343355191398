import { inject, NgModule, provideAppInitializer } from '@angular/core';
import { BannerModule } from '@core/banner/banner.module';
import { NotificationsModule } from '@core/notifications/notifications.module';
import { AppCoreActions } from '@core/root-store/store/app-core/actions/app-core.actions';
import { PopupChatModule } from '@features/chat/popup-chat.module';
import { Store } from '@ngrx/store';
import { AppRoutingModule } from '../routing/app-routing.module';

export const appCoreInitializer = provideAppInitializer(() => {
    const store = inject(Store);
    store.dispatch(AppCoreActions.initialisePlatform());
});

@NgModule({
    imports: [AppRoutingModule, BannerModule, PopupChatModule, NotificationsModule],
    providers: [appCoreInitializer]
})
export class AppCoreModule {}
