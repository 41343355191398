import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GoogleAnalyticsEventType } from '@core/analytics/enums/google-analytics-event-type.enum';
import { AnalyticsService } from '@core/analytics/services/analytics.service';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthStorageKeys } from '@core/authentication/constants/auth-storage-keys';
import { AnonymousTokenService } from '@core/authentication/services/anonymous-token.service';
import { AuthenticationApiService } from '@core/authentication/services/authentication-api.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { SsoAuthService } from '@core/authentication/services/sso-auth.service';
import { SsoService } from '@core/authentication/services/sso.service';
import { SelectedTimezoneService } from '@core/locale/services/selected-timezone/selected-timezone.service';
import { PushNotificationService } from '@core/native/services/push-notification.service';
import { TokenBackupService } from '@core/native/services/token-backup.service';
import { NavigationHistoryActions } from '@core/navigation-history/store/actions/navigation-history.actions';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';
import { AppRoutingFacadeService } from '@core/routing/services/app-routing-facade.service';
import { StorageService } from '@core/storage/services/storage.service';
import { ToastService } from '@core/toast/services/toast/toast.service';
import { TosDialogService } from '@core/tos/services/tos-dialog/tos-dialog.service';
import { LoginFacadeService } from '@features/login/services/login-facade.service';
import { LoginPageActions } from '@features/login/store/actions/login-page.actions';
import { LoginStep } from '@features/login/store/models/login.state';
import { MfaApiService } from '@features/mfa/services/mfa-api-service/mfa-api.service';
import { PortalService } from '@features/portal/services/portal.service';
import { UserProfileApiService } from '@features/user-profile/services/user-profile-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { CustomFieldsApiService } from '@shared/custom-fields/services/custom-fields-api.service';
import { EMPTY, NEVER, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AppCoreApiActions } from '../../app-core/actions/app-core-api.actions';
import { AppCoreActions } from '../../app-core/actions/app-core.actions';
import { AppRoutingActions } from '../../app-routing/actions/app-routing.actions';
import { AuthInterceptorActions } from '../actions/auth-interceptor.actions';
import { AuthenticationApiActions } from '../actions/authentication-api.actions';
import { AuthenticationActions } from '../actions/authentication.actions';
import { PiiService } from '@core/authentication/services/pii/pii.service';

@Injectable()
export class AuthenticationEffects {
    initialisePersistedAuthData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseApp),
            // Wait for the app settings to be loaded before getting the persisted data
            switchMap(() => this.actions$.pipe(ofType(AppCoreApiActions.loadAppSettingsSuccess), take(1))),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            map(([, appName]) => {
                const userAuthInfo =
                    this.storageService.get(AuthStorageKeys.appUserAuthInfo(appName)) ||
                    this.storageService.get(AuthStorageKeys.userAuthInfo);

                let hasDismissedMfa =
                    !!this.storageService.get(AuthStorageKeys.mfaDismissed(appName)) ||
                    !!this.storageService.get(AuthStorageKeys.mfaDismissedForever(appName));

                let otpSignature = this.storageService.get(AuthStorageKeys.otpSignature(appName));

                const portalConfig = this.portalService.getPortalConfigForApp(appName);

                if (portalConfig?.portal) {
                    // We use the auth info from the portals parent.
                    hasDismissedMfa =
                        !!this.storageService.get(AuthStorageKeys.mfaDismissed(portalConfig.portal)) ||
                        !!this.storageService.get(AuthStorageKeys.mfaDismissedForever(portalConfig.portal));

                    // We use the otp signature from the portals parent.
                    otpSignature = this.storageService.get(AuthStorageKeys.otpSignature(portalConfig.portal));
                }

                const passcodeTokens = this.storageService.get(AuthStorageKeys.passcodeToken);
                const anonymousToken = this.storageService.get(AuthStorageKeys.anonymousToken);

                return AuthenticationActions.initialisePersistedAuthDataSuccess({
                    appName,
                    userAuthInfo,
                    passcodeTokens,
                    anonymousToken,
                    hasDismissedMfa,
                    otpSignature
                });
            })
        )
    );

    checkAuthenticationComplete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AuthenticationApiActions.submitPasscodeSuccess,
                AuthenticationApiActions.mfaValidateSuccess,
                AuthenticationApiActions.mfaCalibrateSuccess,
                AuthenticationApiActions.loginSuccess,
                AuthenticationActions.mfaDismissPrompt
            ),
            withLatestFrom(
                this.authenticationFacadeService.hasAppAccess(),
                this.authenticationFacadeService.getUserAuthInfo()
            ),
            filter(([_, hasAppAccess]) => hasAppAccess),
            map(([_, __, userAuthInfo]) => {
                if (userAuthInfo) {
                    return AuthenticationActions.getAuthenticatedPerson();
                }

                return AuthenticationActions.completed();
            })
        )
    );

    checkRequiresPasscodeAfterMfa$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AuthenticationActions.mfaDismissPrompt,
                AuthenticationApiActions.mfaCalibrateSuccess,
                AuthenticationApiActions.mfaValidateSuccess
            ),
            withLatestFrom(this.authenticationFacadeService.isPasscodeRequired()),
            map(([_, passcodeRequired]) => {
                const loginStep = passcodeRequired ? LoginStep.EnterPasscode : LoginStep.Loading;

                return LoginPageActions.goToLoginStep({ loginStep });
            })
        )
    );

    getAuthenticatedPerson$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.getAuthenticatedPerson),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) => {
                return this.authenticationApiService.getAuthenticatedPerson(appName).pipe(
                    switchMap(({ body }) => {
                        return [
                            AuthenticationApiActions.getAuthenticatedPersonSuccess({ authenticatedPerson: body }),
                            AuthenticationActions.completed()
                        ];
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.getAuthenticatedPersonFailure({ error }))
                    )
                );
            })
        )
    );

    initialiseAuthenticatedPerson$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.initialisePersistedAuthDataSuccess),
            switchMap(({ appName, userAuthInfo }) => {
                if (!userAuthInfo) {
                    return of(
                        AuthenticationApiActions.initialiseAuthenticationSuccess({ authenticatedPerson: undefined })
                    );
                }

                return this.authenticationApiService.getAuthenticatedPerson(appName).pipe(
                    switchMap(({ body }) => {
                        const actions = [];

                        actions.push(
                            AuthenticationApiActions.initialiseAuthenticationSuccess({ authenticatedPerson: body })
                        );

                        return actions;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return [
                            AuthenticationApiActions.initialiseAuthenticationFailure({ error }),
                            AppCoreActions.initialiseApp({ appName })
                        ];
                    })
                );
            })
        )
    );

    initialiseAuthenticatedPersonCustomFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationApiActions.initialiseAuthenticationSuccess),
            map(({ authenticatedPerson }) => {
                if (!authenticatedPerson) {
                    return AuthenticationApiActions.setUserCustomFieldValues({ customFieldValues: [] });
                }

                return AuthenticationApiActions.syncCustomFieldValues();
            })
        )
    );

    fetchAuthenticatedPersonCustomFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationApiActions.syncCustomFieldValues, AuthenticationActions.completed),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) =>
                this.customFieldsApiService.getCustomFieldValues(appName, { type: 'people' }).pipe(
                    map((customFieldValues) =>
                        AuthenticationApiActions.setUserCustomFieldValues({ customFieldValues })
                    ),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    loginGetToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.login),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            // exhaustMap ensures one login request is processed before the next begins.
            exhaustMap(([{ email, password }, appName]) => {
                return this.authenticationApiService.login(appName, email, password).pipe(
                    map((userAuthInfo) => AuthenticationApiActions.loginGetTokenSuccess({ appName, userAuthInfo })),
                    catchError((error: HttpErrorResponse) => of(AuthenticationApiActions.loginFailure({ error })))
                );
            })
        )
    );

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationApiActions.loginGetTokenSuccess, AuthenticationApiActions.exchangeAuthTokenSuccess),
            withLatestFrom(
                this.authenticationFacadeService.getHasDismissedMfa(),
                this.appCoreFacadeService.getAppSettings(),
                this.authenticationFacadeService.isPasscodeRequired()
            ),
            map(([{ userAuthInfo }, hasDismissedMfa, appSettings, isPasscodeRequired]) =>
                AuthenticationApiActions.loginSuccess({
                    userAuthInfo,
                    hasDismissedMfa,
                    appSettings,
                    isPasscodeRequired
                })
            )
        )
    );

    persistUserAuthInfo$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    AuthenticationActions.completed,
                    AuthenticationActions.setUserAuthInfo,
                    AuthInterceptorActions.refreshTokenSuccess,
                    AuthenticationApiActions.exchangeAuthTokenSuccess
                ),
                withLatestFrom(
                    this.authenticationFacadeService.getUserAuthInfo(),
                    this.appCoreFacadeService.getAppName()
                ),
                tap(([action, userAuthInfo, rawAppName]) => {
                    if (action.type === AuthenticationApiActions.exchangeAuthTokenSuccess.type) {
                        this.storageService.setStorageItem(AuthStorageKeys.mfaDismissed(rawAppName), true);
                    }

                    /* If 'setUserAuthInfo' action has an app attached, override the value of the current app url */
                    /* An example of this happening is when the auth info is set whilst opening a portal app */
                    const appName = 'app' in action ? action.app : rawAppName;

                    // Ensure PII data is not stored locally
                    const strippedAuthInfo = this.piiService.stripPii(userAuthInfo);
                    this.storageService.put(AuthStorageKeys.appUserAuthInfo(appName), strippedAuthInfo);
                }),
                switchMap(() => this.tokenBackupService.save())
            ),
        { dispatch: false }
    );

    checkTosAccepted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.completed, AppCoreActions.initialiseAppSuccess),
            withLatestFrom(
                this.appCoreFacadeService.getAppSettings(),
                this.authenticationFacadeService.getAuthenticatedPerson()
            ),
            filter(([_, __, loggedInUser]) => !!loggedInUser),
            switchMap(([_, app, loggedInUser]) => this.tosDialogService.checkAndShowDialog(app, loggedInUser)),
            map((agreed) => (agreed ? AuthenticationActions.tosCompleted() : AuthenticationActions.logout()))
        )
    );

    clearUserAuthInfo$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    AuthenticationApiActions.initialiseAuthenticationFailure,
                    AuthenticationActions.logoutSuccess,
                    AuthInterceptorActions.refreshTokenFailure
                ),
                withLatestFrom(this.appCoreFacadeService.getAppName()),
                tap(([, appName]) => {
                    this.storageService.clear(AuthStorageKeys.userAuthInfo);
                    this.storageService.clear(AuthStorageKeys.mfaDismissed(appName));
                    this.storageService.clear(AuthStorageKeys.appUserAuthInfo(appName));
                }),
                switchMap(() => this.tokenBackupService.clear())
            ),
        { dispatch: false }
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.logout),
            withLatestFrom(this.authenticationFacadeService.getUserAuthInfo()),
            switchMap(([, userAuthInfo]) =>
                userAuthInfo ? this.authenticationApiService.logout(userAuthInfo.auth.refresh_token) : of(true)
            ),
            // Even if the API request fails we still want to log the user out.
            catchError(() => of(AuthenticationActions.logoutSuccess())),
            map(() => AuthenticationActions.logoutSuccess())
        )
    );

    clearPortalConfigOnFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    AuthenticationApiActions.initialiseAuthenticationFailure,
                    AuthInterceptorActions.refreshTokenFailure
                ),
                withLatestFrom(this.appCoreFacadeService.getAppName()),
                map(([_, appName]) => this.portalService.getPortalConfigForApp(appName)),
                filter((portalConfig) => !!portalConfig?.portal),
                tap((portalConfig) => this.storageService.clear(AuthStorageKeys.appUserAuthInfo(portalConfig.portal)))
            ),
        { dispatch: false }
    );

    refreshAuthenticatedPerson$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.refreshAuthenticatedPerson),
            switchMap(({ appName }) => {
                return this.authenticationApiService.getAuthenticatedPerson(appName).pipe(
                    map(({ body }) => {
                        return AuthenticationApiActions.refreshAuthenticatedPersonSuccess({
                            authenticatedPerson: body
                        });
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.refreshAuthenticatedPersonFailure({ error }))
                    )
                );
            })
        )
    );

    patchAuthenticatedPerson$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.patchAuthenticatedPerson),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ patch }, appUrl]) =>
                this.userProfileApiService.patchUserProfile(appUrl, patch).pipe(
                    map((authenticatedPerson) =>
                        AuthenticationApiActions.refreshAuthenticatedPersonSuccess({ authenticatedPerson })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.refreshAuthenticatedPersonFailure({ error }))
                    )
                )
            )
        )
    );

    setTimezone$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    AuthenticationApiActions.initialiseAuthenticationSuccess,
                    AuthenticationApiActions.getAuthenticatedPersonSuccess
                ),
                withLatestFrom(this.appCoreFacadeService.getAppSettings()),
                tap(([{ authenticatedPerson }, app]) => {
                    this.selectedTimezoneService.setInitialTimezone(
                        authenticatedPerson?.timezone_preference || app.timezone_default_preference
                    );
                })
            ),
        { dispatch: false }
    );

    redirectToLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthInterceptorActions.refreshTokenFailure, AuthenticationActions.logoutSuccess),
            withLatestFrom(this.appRoutingFacadeService.getUrl()),
            switchMap(([action, routerUrl]) => {
                const actions: Array<Action> = [
                    AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.login] }),
                    NavigationHistoryActions.forget()
                ];

                if (action.type === AuthenticationActions.logoutSuccess.type) {
                    return actions;
                }

                const lastIndexOfSlash = routerUrl.lastIndexOf('/');
                if (lastIndexOfSlash !== 0) {
                    actions.push(AuthenticationActions.setRedirectUrl({ redirectUrl: routerUrl }));
                }

                return actions;
            })
        )
    );

    sessionExpiredNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthInterceptorActions.refreshTokenFailure),
                tap(() => this.toastService.notify('AUTHENTICATION_SESSION_EXPIRED'))
            ),
        { dispatch: false }
    );

    persistPasscodeTokens$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    AuthenticationApiActions.submitPasscodeSuccess,
                    AuthenticationActions.setPasscodeToken,
                    AuthenticationActions.clearPasscodeToken
                ),
                switchMap(() => {
                    return this.authenticationFacadeService.getPasscodeTokens();
                }),
                tap((passcodeTokens) => this.storageService.put(AuthStorageKeys.passcodeToken, passcodeTokens))
            ),
        { dispatch: false }
    );

    generateAnonymousToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.initialisePersistedAuthDataSuccess),
            switchMap(() => {
                return this.authenticationFacadeService.getAnonymousToken().pipe(take(1));
            }),
            switchMap((anonymousToken) => {
                if (!anonymousToken) {
                    const generatedAnonymousToken = this.anonymousTokenService.generateToken();
                    return of(AuthenticationActions.setAnonymousToken({ anonymousToken: generatedAnonymousToken }));
                } else {
                    return NEVER;
                }
            })
        )
    );

    persistAnonToken$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.setAnonymousToken),
                tap(({ anonymousToken }) => {
                    this.storageService.put(AuthStorageKeys.anonymousToken, anonymousToken);
                })
            ),
        { dispatch: false }
    );

    getDownloadToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.refreshDownloadToken),
            withLatestFrom(this.authenticationFacadeService.isAuthenticated()),
            switchMap(([, isAuthenticated]) => {
                if (!isAuthenticated) {
                    return NEVER;
                }
                return this.authenticationApiService.getDownloadToken().pipe(
                    map(({ token, expires }) => AuthenticationApiActions.getDownloadTokenSuccess({ token, expires })),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.getDownloadTokenFailure({ error }))
                    )
                );
            })
        )
    );

    checkEmailAddress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.checkEmailAddress),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ email }, appName]) => {
                return this.authenticationApiService.checkEmailAddress(appName, email).pipe(
                    map((userEmailInfo) => AuthenticationApiActions.checkEmailAddressSuccess({ userEmailInfo })),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.checkEmailAddressFailure({ error }))
                    )
                );
            })
        )
    );

    submitPasscode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.submitPasscode),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ passcode }, appName]) => {
                return this.authenticationApiService.submitPasscode(appName, passcode).pipe(
                    map(() => AuthenticationApiActions.submitPasscodeSuccess({ appName, passcode })),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.submitPasscodeFailure({ error }))
                    )
                );
            })
        )
    );

    registerUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.registerUser),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ email, password, firstName, lastName }, appName]) => {
                return this.authenticationApiService.register(appName, email, password, firstName, lastName).pipe(
                    switchMap((userAuthInfo) => [
                        AuthenticationApiActions.registerUserSuccess({ email, password }),
                        AuthenticationApiActions.loginGetTokenSuccess({ appName, userAuthInfo })
                    ]),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.registerUserFailure({ error }))
                    )
                );
            })
        )
    );

    requestResetPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.requestResetPassword),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ email }, appName]) => {
                return this.authenticationApiService.submitForgotPassword(appName, email).pipe(
                    map(() => AuthenticationApiActions.requestResetPasswordSuccess()),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.requestResetPasswordFailure({ error }))
                    )
                );
            })
        )
    );

    requestVerificationEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.requestVerificationEmail),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ email }, appName]) => {
                return this.authenticationApiService.requestSendVerificationEmail(appName, email).pipe(
                    map(() => AuthenticationApiActions.requestVerificationEmailSuccess()),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.requestVerificationEmailFailure({ error }))
                    )
                );
            })
        )
    );

    verifyEmailAddress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.verifyEmailAddress),
            switchMap(({ token }) => {
                return this.authenticationApiService.verifyEmailAddress(token).pipe(
                    map(() => AuthenticationApiActions.verifyEmailAddressSuccess()),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.verifyEmailAddressFailure({ error }))
                    )
                );
            })
        )
    );

    updatePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.updatePassword),
            switchMap(({ newPassword, token, uid }) => {
                return this.authenticationApiService.updatePassword(uid, newPassword, token).pipe(
                    map(() => AuthenticationApiActions.updatePasswordSuccess()),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.updatePasswordFailure({ error }))
                    )
                );
            })
        )
    );

    analyticsAuthUpdate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.completed, AppCoreActions.initialiseAppSuccess),
                withLatestFrom(this.authenticationFacadeService.getUser(), this.appCoreFacadeService.getAppSettings()),
                tap(([{}, user, appSettings]) => {
                    if (user && appSettings) {
                        const username = user.username;
                        const appUrl = appSettings.url_name;
                        this.analyticsService.emitUserAuthEvent(
                            username,
                            appUrl,
                            GoogleAnalyticsEventType.EnterLoggedInUser
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    appIdentityProviders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.getAppIdentityProviders),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) => {
                return this.authenticationApiService.getIdentityProviders(appName).pipe(
                    map((providers) => AuthenticationApiActions.getAppIdentityProvidersSuccess({ providers })),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.getAppIdentityProvidersFailure({ error }))
                    )
                );
            })
        )
    );

    ssoExchangeToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.ssoExchangeToken),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ authToken }, appName]) => {
                return this.ssoAuthService.validate(authToken).pipe(
                    map((response) => {
                        const { otp_signature, ...userAuthInfo } = response;
                        return { otpSignature: otp_signature, userAuthInfo };
                    }),
                    tap(({ otpSignature }) => {
                        if (otpSignature) {
                            this.storageService.put(AuthStorageKeys.otpSignature(appName), otpSignature);
                        }
                    }),
                    switchMap(({ otpSignature, userAuthInfo }) =>
                        of(
                            AuthenticationApiActions.exchangeAuthTokenSuccess({ appName, userAuthInfo, otpSignature }),
                            AuthenticationActions.ssoHasSettled()
                        )
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            AuthenticationApiActions.exchangeAuthTokenFailure({ error }),
                            AuthenticationActions.ssoHasSettled()
                        )
                    )
                );
            })
        )
    );

    ssoOpenlogin$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.ssoOpenLogin),
                tap((login) => this.ssoService.open(login.loginUrl))
            ),
        { dispatch: false }
    );

    listenForSsoAuthToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            withLatestFrom(this.routingFacadeService.getQueryParams()),
            map(([_, { sso_token }]) => {
                if (sso_token) {
                    return AuthenticationActions.ssoExchangeToken({ authToken: sso_token });
                }

                return AuthenticationActions.ssoHasSettled();
            })
        )
    );

    mfaDismissPrompt$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.mfaDismissPrompt),
                withLatestFrom(this.appCoreFacadeService.getAppName()),
                tap(([{ askAgain }, appName]) => {
                    const storageKey = askAgain
                        ? AuthStorageKeys.mfaDismissed(appName)
                        : AuthStorageKeys.mfaDismissedForever(appName);
                    this.storageService.put(storageKey, true);
                })
            ),
        { dispatch: false }
    );

    mfaSendEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.mfaSendEmail),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) => {
                return this.mfaApiService.sendEmail(appName).pipe(
                    map(() => AuthenticationApiActions.mfaSendEmailSuccess()),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.mfaSendEmailFailure({ error }))
                    )
                );
            })
        )
    );

    goToStepOnSendEmailSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationApiActions.mfaSendEmailSuccess),
            map(() => AuthenticationActions.mfaGoToEmailValidateStep())
        )
    );

    mfaGetQrCodeOnLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationApiActions.loginSuccess),
            withLatestFrom(this.loginFacadeService.getLoginPageState()),
            filter(([_, loginPageState]) => !!loginPageState),
            filter(([_, { loginStep }]) => loginStep === LoginStep.MfaSetup),
            map(() => AuthenticationActions.mfaGetQR())
        )
    );

    mfaGetQrCodeOnGoToSetupStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.mfaGoToSetupStep),
            map(() => AuthenticationActions.mfaGetQR())
        )
    );

    mfaGetQrCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.mfaGetQR),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) => {
                return this.mfaApiService.getQr(appName).pipe(
                    map((mfaQrCode) => AuthenticationApiActions.mfaGetQrSuccess({ mfaQrCode })),
                    catchError((error: HttpErrorResponse) => of(AuthenticationApiActions.mfaGetQrFailure({ error })))
                );
            })
        )
    );

    mfaCalibrate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.mfaCalibrate),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ mfaCode }, appName]) => {
                return this.mfaApiService.calibrate(appName, mfaCode).pipe(
                    tap(({ otp_signature }) => {
                        this.storageService.put(AuthStorageKeys.otpSignature(appName), otp_signature);
                    }),
                    map(({ otp_signature }) =>
                        AuthenticationApiActions.mfaCalibrateSuccess({ otpSignature: otp_signature })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthenticationApiActions.mfaCalibrateFailure({ error }))
                    )
                );
            })
        )
    );

    manualMfaCalibrateSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationApiActions.manualMfaCalibrateSuccess),
                withLatestFrom(this.appCoreFacadeService.getAppName()),
                tap(([{ otpSignature }, appName]) => {
                    this.toastService.success('MFA_VALIDATE_SUCCESS');
                    this.storageService.put(AuthStorageKeys.otpSignature(appName), otpSignature);
                })
            ),
        { dispatch: false }
    );

    mfaValidate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.mfaValidate),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ mfaCode, mfaTrust }, appName]) => {
                return this.mfaApiService.validate(appName, mfaCode, mfaTrust).pipe(
                    tap(({ otp_signature }) => {
                        this.storageService.put(AuthStorageKeys.otpSignature(appName), otp_signature);
                    }),
                    map(({ otp_signature }) =>
                        AuthenticationApiActions.mfaValidateSuccess({ otpSignature: otp_signature })
                    ),
                    catchError((error: HttpErrorResponse) => of(AuthenticationApiActions.mfaValidateFailure({ error })))
                );
            })
        )
    );

    mfaSetOtpSignature$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.mfaSetOtpSignature),
                withLatestFrom(this.appCoreFacadeService.getAppName()),
                tap(([{ otpSignature }, appName]) => {
                    this.storageService.put(AuthStorageKeys.otpSignature(appName), otpSignature);
                })
            ),
        { dispatch: false }
    );

    optInToPushNotificationsOnLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AuthenticationApiActions.getAuthenticatedPersonSuccess,
                AuthenticationApiActions.initialiseAuthenticationSuccess
            ),
            withLatestFrom(this.appCoreFacadeService.getAppSettings()),
            switchMap(([{ authenticatedPerson }, appSettings]) =>
                this.pushNotificationService.optIn(appSettings, authenticatedPerson)
            ),
            // NOTE: In the future we will not need to save this OS User ID anywhere. So this will be a dispatch false effect.
            map((osUserId) => AppCoreActions.initialisePushNotificationsSuccess({ osUserId }))
        )
    );

    optOutOfPushNotificationsOnLogout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.logoutSuccess),
                tap(() => this.pushNotificationService.optOut())
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private authenticationApiService: AuthenticationApiService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private anonymousTokenService: AnonymousTokenService,
        private storageService: StorageService,
        private appCoreFacadeService: AppCoreFacadeService,
        private tosDialogService: TosDialogService,
        private analyticsService: AnalyticsService,
        private userProfileApiService: UserProfileApiService,
        private portalService: PortalService,
        private ssoAuthService: SsoAuthService,
        private ssoService: SsoService,
        private routingFacadeService: AppRoutingFacadeService,
        private mfaApiService: MfaApiService,
        private loginFacadeService: LoginFacadeService,
        private tokenBackupService: TokenBackupService,
        private toastService: ToastService,
        private selectedTimezoneService: SelectedTimezoneService,
        private pushNotificationService: PushNotificationService,
        private appRoutingFacadeService: AppRoutingFacadeService,
        private customFieldsApiService: CustomFieldsApiService,
        private piiService: PiiService
    ) {}
}
