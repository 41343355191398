import { Injectable } from '@angular/core';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { CookieStorageKeys } from '@core/banner/constants/cookie-storage-keys.constant';
import { CookieConfigurationState } from '@core/banner/interfaces/cookie-configuration-state.interface';
import { StorageService } from '@core/storage/services/storage.service';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { CookieApiService } from '../cookie-api/cookie-api.service';

@Injectable({
    providedIn: 'root'
})
export class CookieConsentService {
    constructor(
        private storageService: StorageService,
        private cookieConsent: CookieApiService,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}

    public setCookies(appName: string, config: CookieConfigurationState): Observable<any> {
        this.storageService.setStorageItem(CookieStorageKeys.preferences(appName), config);

        return this.authenticationFacadeService.hasAppAccessAndAuthenticated().pipe(
            filter((authorised) => authorised),
            switchMap(() => this.cookieConsent.setCookieConsent(appName, config.allow_analytics))
        );
    }
}
