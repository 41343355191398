import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Module } from '@shared/api';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ImageDimensionsPipe } from '../../../../common/pipes/image-dimensions/image-dimensions.pipe';

@Component({
    selector: 'cc-module-icon',
    templateUrl: './module-icon.component.html',
    styleUrls: ['./module-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, NgSwitch, NgSwitchCase, ImageDimensionsPipe]
})
export class ModuleIconComponent {
    @Input()
    public module: Module;

    @Input()
    public slot: string;

    public iconToggleEnum = Module.IconToggleEnum;

    constructor() {}
}
