import { ErrorHandler, inject, NgModule, provideAppInitializer } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { SentryIgnoreErrors } from './constants/sentry-ignore-errors.constant';
import { browserTracingIntegration, createErrorHandler, init, TraceService } from '@sentry/angular';

@NgModule({
    providers: [
        provideAppInitializer(() => {
            init({
                dsn: 'https://fd96d64bf9f149b1833cfbd76c8cf819@o94555.ingest.sentry.io/1207744',
                integrations: [browserTracingIntegration()],
                environment: environment.name,
                release: environment.VERSION,
                ignoreErrors: SentryIgnoreErrors
            });
        }),
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false
            })
        },
        {
            provide: TraceService,
            deps: [Router]
        },
        provideAppInitializer(() => {
            inject(TraceService);
        })
    ]
})
export class ErrorHandlingModule {}
