import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IonApp } from '@ionic/angular/standalone';

@Component({
    selector: 'cc-app',
    template: `
        <ion-app class="new-app">
            <router-outlet></router-outlet>
        </ion-app>
    `,
    imports: [RouterOutlet, IonApp]
})
export class AppComponent {}
