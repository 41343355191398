import { EventEmitter, Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguage } from '@shared/api';
import { AlertInput } from '@ionic/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageSelectionService {
    constructor(
        private alertController: AlertController,
        private translateService: TranslateService
    ) {}

    async showLanguageSelectionDialog(
        languages: AppLanguage[],
        currentLanguage: string,
        onChange: EventEmitter<string>
    ): Promise<void> {
        const translations = await this.translateService
            .get([
                'I18N_CANCEL_SELECT_LANGUAGE_DIALOG_HEADER',
                'I18N_CANCEL_SUBMIT_LANGUAGE_DIALOG_HEADER',
                'I18N_SELECT_LANGUAGE_DIALOG_HEADER'
            ])
            .toPromise();
        const alert = await this.alertController.create({
            cssClass: 'change-language-dialog',
            header: translations.I18N_SELECT_LANGUAGE_DIALOG_HEADER,
            inputs: languages.map<AlertInput>((language) => ({
                type: 'radio',
                label: language.title,
                value: language.language_code.toString(),
                checked: language.language_code.toString() === currentLanguage
            })),
            buttons: [
                { text: translations.I18N_CANCEL_SELECT_LANGUAGE_DIALOG_HEADER, role: 'cancel' },
                {
                    text: translations.I18N_CANCEL_SUBMIT_LANGUAGE_DIALOG_HEADER,
                    handler: (language) => onChange.emit(language)
                }
            ]
        });
        await alert.present();
    }
}
