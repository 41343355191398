import { Component, OnInit } from '@angular/core';
import { ExperimentalService } from '@core/environment/services/experimental.service';
import { NgIf } from '@angular/common';
import { IonItem, IonLabel } from '@ionic/angular/standalone';

@Component({
    selector: 'cc-experimental-switch',
    templateUrl: './experimental-switch.component.html',
    styleUrls: ['./experimental-switch.component.scss'],
    imports: [NgIf, IonItem, IonLabel]
})
export class ExperimentalSwitchComponent implements OnInit {
    public isExperimentalOn: boolean;
    public shouldShowExperimentalSwitch: boolean;

    public ngOnInit(): void {
        this.isExperimentalOn = ExperimentalService.isExperimentalModeOn();
        this.shouldShowExperimentalSwitch = ExperimentalService.shouldShowExperimentalSwitch();
    }

    public toggleExperimental(): void {
        ExperimentalService.toggleExperimental();
    }
}
