import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppLanguage } from '@shared/api';
import { LanguageSelectionService } from '../../services/language-selection.service';
import { IonItem, IonLabel } from '@ionic/angular/standalone';

@Component({
    selector: 'cc-language-selection',
    templateUrl: './language-selection.component.html',
    styleUrls: ['./language-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [IonItem, IonLabel]
})
export class LanguageSelectionComponent {
    @Input()
    itemLabel: string;

    @Input()
    languages: AppLanguage[];

    @Input()
    currentLanguage: string;

    @Output()
    languageChange = new EventEmitter<string>();

    constructor(private languageSelectionService: LanguageSelectionService) {}

    showDialog(): void {
        this.languageSelectionService.showLanguageSelectionDialog(
            this.languages,
            this.currentLanguage,
            this.languageChange
        );
    }
}
