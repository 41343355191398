import { UrlUtilityService } from '@shared/utilities/url-utility.service';
import { fromEvent } from 'rxjs';
import { userGeneratedContentAttribute } from '@common/directives/user-generated-content/user-generated-content-attribute.constant';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class HrefInterceptorService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private urlUtilityService: UrlUtilityService
    ) {
        this.startIntercepting();
    }

    public startIntercepting(): void {
        fromEvent<MouseEvent>(this.document, 'click').subscribe((event) => this.interceptHref(event));
    }

    public interceptHref(_event: MouseEvent): void {
        const anchorElement = this.getAnchorElement(_event.target as HTMLElement);

        if (!anchorElement) {
            return;
        }

        _event.stopPropagation();
        _event.preventDefault();

        const url = anchorElement.getAttribute('href');

        if (!url || url.startsWith('#')) {
            return;
        }

        const isUserGenerated = !!anchorElement.closest(`[${userGeneratedContentAttribute}]`);

        this.urlUtilityService.open(url, {
            warnExternal: isUserGenerated
        });
    }

    private getAnchorElement(element: HTMLElement): HTMLElement | null {
        if (element.tagName === 'A') {
            return element;
        } else if (element.parentElement) {
            return this.getAnchorElement(element.parentElement);
        } else {
            return null;
        }
    }
}
