import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppNotification } from '@core/notifications/interfaces/notification.interface';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonButton, IonCard, IonCardContent } from '@ionic/angular/standalone';

@Component({
    selector: 'cc-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, NgClass, NgFor, TranslateModule, IonCard, IonCardContent, IonButton]
})
export class NotificationComponent implements OnInit {
    @Input()
    public notification: AppNotification;

    @Output()
    public dismissClicked: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public handleClick: EventEmitter<void> = new EventEmitter<void>();

    public messages: Array<string>;

    public ngOnInit(): void {
        this.messages = this.formatMessage();
    }

    public dismiss(): void {
        this.dismissClicked.emit();
    }

    public clicked(): void {
        this.handleClick.emit();
    }

    private formatMessage(): Array<string> {
        if (typeof this.notification.message === 'string') {
            return [this.notification.message];
        }

        return this.notification.message;
    }
}
