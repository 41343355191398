import { Component, OnInit, TrackByFunction } from '@angular/core';
import { slideInOut } from '@common/animations/animations';
import { AppNotification } from '@core/notifications/interfaces/notification.interface';
import { NotificationsFacadeService } from '@core/notifications/services/notifications-facade/notifications-facade.service';
import { NotificationsActions } from '@core/notifications/store/actions/notifications.actions';
import { Observable } from 'rxjs';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { NotificationComponent } from '../notification/notification.component';

@Component({
    selector: 'cc-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    animations: [slideInOut],
    imports: [NgIf, NgFor, NotificationComponent, AsyncPipe]
})
export class NotificationsComponent implements OnInit {
    public notifications$: Observable<Array<AppNotification>>;

    constructor(private notificationsFacadeService: NotificationsFacadeService) {}

    public trackById: TrackByFunction<AppNotification> = (_: number, notification: AppNotification) => notification.id;

    public ngOnInit(): void {
        this.notifications$ = this.notificationsFacadeService.getNotifications();
    }

    public handleDismiss(id: string): void {
        this.notificationsFacadeService.dispatch(NotificationsActions.dismissNotification({ id }));
    }

    public handleClicked(id: string): void {
        this.notificationsFacadeService.dispatch(NotificationsActions.notificationClicked({ id }));
    }
}
