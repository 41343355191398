import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { SoundEffects } from '@common/constants/sound-effects.enum';
import { SoundService } from '@common/services/sound/sound.service';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { EnvironmentService } from '@core/environment/services/environment.service';
import { NotificationDurations } from '@core/notifications/constants/notification-durations.constant';
import { NotificationsApiService } from '@core/notifications/services/notifications-api/notifications-api.service';
import { NotificationsFacadeService } from '@core/notifications/services/notifications-facade/notifications-facade.service';
import { WebPushService } from '@core/notifications/services/web-push/web-push.service';
import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';
import { AuthenticationApiActions } from '@core/root-store/store/authentication/actions/authentication-api.actions';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { ToastService } from '@core/toast/services/toast/toast.service';
import { AlertsApiService } from '@features/alerts/services/alerts-api.service';
import { AlertsActions } from '@features/alerts/store/actions/alerts.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NotificationSettingsPreference } from '@shared/api';
import { combineLatest, of, timer } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationsActions } from '../actions/notifications.actions';

@Injectable()
export class NotificationsEffects {
    public dismissNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.showNotification),
            filter(({ notification }) => notification.duration > 0),
            mergeMap(({ notification }) =>
                timer(notification.duration).pipe(
                    map(() => NotificationsActions.dismissNotification({ id: notification.id }))
                )
            )
        )
    );

    public playSoundOnNewNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NotificationsActions.showNotification),
                withLatestFrom(this.authenticationFacadeService.getAuthenticatedPerson()),
                filter(
                    ([{ notification }, loggedInUser]) =>
                        !notification.silent &&
                        (!loggedInUser ||
                            loggedInUser.in_app_notification_preference ===
                                NotificationSettingsPreference.soundNotifications)
                ),
                tap(() => this.soundService.play(SoundEffects.incomingAlert, 0.2))
            ),
        { dispatch: false }
    );

    public handleNotificationClick$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.notificationClicked),
            switchMap(({ id }) => this.notificationsFacadeService.getNotification(id).pipe(take(1))),
            switchMap((notification) => {
                const actions: Action[] = [NotificationsActions.notificationActioned({ id: notification.id })];
                if (notification.action) {
                    actions.push(notification.action);
                }
                return actions;
            })
        )
    );

    public dismissNotificationOnActioned$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.notificationActioned),
            map(({ id }) => NotificationsActions.dismissNotification({ id }))
        )
    );

    public runNotificationDeeplink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.apiDrivenNotificationActioned),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ id, customDeeplink, canMarkAsRead }, appName]) => {
                const deeplink = customDeeplink
                    ? of(customDeeplink)
                    : this.notificationsApiService.getNotificationDeeplink(appName, id).pipe(
                          map((res) => res.deeplink),
                          catchError(() => {
                              this.notificationsFacadeService.dispatch(AlertsActions.getDeeplinkFailure());
                              return of(undefined);
                          })
                      );

                /* We decrement the notifications badge if actioned from toast message, otherwise they might have already marked it as read */
                if (canMarkAsRead) {
                    this.notificationsFacadeService.dispatch(AlertsActions.decrementNotificationsBadge());
                }

                return combineLatest({
                    deeplink,
                    read: canMarkAsRead ? this.alertsApiService.markNotificationAsRead(appName, id) : of(undefined)
                });
            }),
            filter(Boolean),
            map(({ deeplink }) => {
                const [url, params] = deeplink.split('?');
                const queryParams = Object.fromEntries(new URLSearchParams(params).entries());

                return AppRoutingActions.goToPage({
                    urlSegments: [url],
                    extras: { queryParams }
                });
            })
        )
    );

    public shouldShowSoftWebPushNotificationPrompt$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AuthenticationApiActions.initialiseAuthenticationSuccess,
                AuthenticationApiActions.getAuthenticatedPersonSuccess
            ),
            withLatestFrom(
                this.authenticationFacadeService.getAuthenticatedPerson(),
                this.appCoreFacadeService.getAppSettings(),
                this.swPush.subscription
            ),
            filter(([_, person, app, existingSubscription]) => {
                if (
                    !person ||
                    !app.browser_notifications_enabled ||
                    !app.show_browser_notification_prompt ||
                    existingSubscription
                ) {
                    return false;
                }

                const isSupported = this.webPushService.isWebPushSupported();

                if (!isSupported) {
                    return false;
                }

                const hasRejected = this.webPushService.getHasRejectedWebPush(app.url_name, person.id);

                if (hasRejected) {
                    return false;
                }

                return true;
            }),
            switchMap(() => timer(20000).pipe(takeUntil(this.actions$.pipe(ofType(AuthenticationActions.logout))))),
            map(() => NotificationsActions.showSoftWebPushPrompt())
        )
    );

    public showSoftWebPushNotificationPrompt$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NotificationsActions.showSoftWebPushPrompt),
                withLatestFrom(
                    this.authenticationFacadeService.getAuthenticatedPerson(),
                    this.appCoreFacadeService.getAppName()
                ),
                tap(([_, person, urlName]) => {
                    this.toastService.notify(`WEB_PUSH_POPUP_MESSAGE`, {
                        title: 'WEB_PUSH_POPUP_TITLE',
                        duration: NotificationDurations.persist,
                        silent: true,
                        icon: 'campaign',
                        buttons: [
                            {
                                text: 'WEB_PUSH_POPUP_YES',
                                handler: () =>
                                    this.notificationsFacadeService.dispatch(
                                        NotificationsActions.requestBrowserWebPushPermission()
                                    )
                            },
                            {
                                text: 'WEB_PUSH_POPUP_NO',
                                handler: () => {
                                    this.webPushService.setHasRejectedWebPush(urlName, person.id);
                                    this.toastService.notify('WEB_PUSH_POPUP_LATER', {
                                        title: 'WEB_PUSH_POPUP_LATER_TITLE'
                                    });
                                }
                            }
                        ]
                    });
                })
            ),
        { dispatch: false }
    );

    public requestBrowserWebPushPermission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.requestBrowserWebPushPermission),
            switchMap(() =>
                this.swPush.subscription.pipe(
                    switchMap((existingSubscription) => {
                        if (existingSubscription) {
                            return of(existingSubscription);
                        }

                        return this.swPush.requestSubscription({
                            serverPublicKey: this.enviromentService.WEB_PUSH_PUBLIC_KEY
                        });
                    }),
                    map((data) =>
                        NotificationsActions.requestBrowserWebPushPermissionSuccess({ payload: data.toJSON() })
                    ),
                    catchError((error) => of(NotificationsActions.requestBrowserWebPushPermissionFailure({ error })))
                )
            )
        )
    );

    public subscribeToWebPush$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.requestBrowserWebPushPermissionSuccess),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ payload }, appUrl]) => {
                return this.notificationsApiService
                    .requestPushNotifications(appUrl, {
                        auth: payload.keys.auth,
                        endpoint: payload.endpoint,
                        p256dh: payload.keys.p256dh
                    })
                    .pipe(map(() => NotificationsActions.subscribeToWebPushNotificationSuccess()));
            }),
            catchError(() => of(NotificationsActions.subscribeToWebPushNotificationFailure()))
        )
    );

    public subscribeToWebPushSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NotificationsActions.subscribeToWebPushNotificationSuccess),
                tap(() => this.toastService.success('WEB_PUSH_REQUEST_SUCCESS'))
            ),
        { dispatch: false }
    );

    public requestBrowserWebPushPermissionFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NotificationsActions.requestBrowserWebPushPermissionFailure),
                tap((error) => {
                    let toastErrorMessage = 'WEB_PUSH_REQUEST_FAILURE';

                    if (!this.webPushService.isWebPushPermissionGranted()) {
                        toastErrorMessage = 'WEB_PUSH_REQUEST_FAILURE_PERMISSION_DENIED';
                    }

                    this.toastService.error(toastErrorMessage);

                    if (!this.enviromentService.PRODUCTION) {
                        console.warn(error.error);
                    }
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private notificationsFacadeService: NotificationsFacadeService,
        private soundService: SoundService,
        private notificationsApiService: NotificationsApiService,
        private appCoreFacadeService: AppCoreFacadeService,
        private alertsApiService: AlertsApiService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private swPush: SwPush,
        private toastService: ToastService,
        private enviromentService: EnvironmentService,
        private webPushService: WebPushService
    ) {}
}
