import { Action, createReducer, on } from '@ngrx/store';
import { BannerActions } from '../actions/banner.actions';
import { BannerState } from '../models/banner.state.model';

export const initialState: BannerState = {
    globalBanner: {
        enabled: false,
        hasDismissed: false
    },
    cookies: {
        initialising: true,
        allow_functional: undefined,
        allow_performance: undefined,
        allow_analytics: undefined,
        allow_advertising: undefined,
        cookies_dismissed: undefined,
        showCustomisation: undefined
    }
};

const bannerReducerFn = createReducer(
    initialState,
    on(BannerActions.fetchGlobalBannerConfigSuccess, (state, { config }) => ({
        ...state,
        globalBanner: {
            ...state.globalBanner,
            ...config,
            enabled: true
        }
    })),
    on(BannerActions.setHasDismissed, (state, { hasDismissed }) => ({
        ...state,
        globalBanner: {
            ...state.globalBanner,
            hasDismissed
        }
    })),
    on(BannerActions.fetchGlobalBannerConfigFailure, BannerActions.resetGlobalBanner, (state) => ({
        ...state,
        globalBanner: {
            enabled: false,
            hasDismissed: state.globalBanner.hasDismissed
        }
    })),
    on(BannerActions.fetchCookiesConfiguration, (state) => ({
        ...state,
        cookies: {
            ...initialState.cookies,
            initialising: true
        }
    })),
    on(BannerActions.fetchCookiesConfigurationSuccess, (state, { cookies }) => ({
        ...state,
        cookies: {
            ...state.cookies,
            ...cookies,
            initialising: false
        }
    })),
    on(BannerActions.acceptAllCookiesSuccess, BannerActions.saveSelectedCookiesSuccess, (state, { cookies }) => ({
        ...state,
        cookies: {
            ...state.cookies,
            ...cookies,
            showCustomisation: false
        }
    })),
    on(BannerActions.showCookiesCustomisation, (state, { show }) => ({
        ...state,
        cookies: {
            ...state.cookies,
            showCustomisation: show
        }
    }))
);

export function bannerReducer(state: BannerState | undefined, action: Action): BannerState {
    return bannerReducerFn(state, action);
}
