import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { routerDialogIgnoreList } from '@core/root-store/constants/router-dialog-ignore-list.constant';
import { AppRoutingFacadeService } from '@core/routing/services/app-routing-facade.service';
import { ModalController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';
import { AppRoutingActions } from '../actions/app-routing.actions';

@Injectable()
export class AppRoutingEffects {
    goToAppModule$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppRoutingActions.goToAppModule),
                withLatestFrom(this.appCoreFacadeService.getAppName()),
                switchMap(([{ urlSegments, extras }, appName]) =>
                    from(this.router.navigate([appName, AppPageRoutes.module, ...urlSegments], extras))
                )
            ),
        { dispatch: false }
    );

    goToAppModulePage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppRoutingActions.goToAppModulePage),
                withLatestFrom(
                    this.appCoreFacadeService.getAppName(),
                    this.appRoutingFacadeService.getCurrentModuleId()
                ),
                switchMap(([{ urlSegments, extras }, appName, moduleId]) =>
                    from(this.router.navigate([appName, AppPageRoutes.module, moduleId, ...urlSegments], extras))
                )
            ),
        { dispatch: false }
    );

    goToAppPage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppRoutingActions.goToAppPage),
                withLatestFrom(this.appCoreFacadeService.getAppName()),
                switchMap(([{ urlSegments, extras }, appName]) =>
                    from(this.router.navigate([appName, ...urlSegments], extras))
                )
            ),
        { dispatch: false }
    );

    goToAppUserPage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppRoutingActions.goToAppUserPage),
                withLatestFrom(this.appCoreFacadeService.getAppName()),
                tap(([{ urlSegments, extras }, appName]) =>
                    this.router.navigate([appName, AppPageRoutes.user, ...urlSegments], extras)
                )
            ),
        { dispatch: false }
    );

    goToPage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppRoutingActions.goToPage),
                tap(({ urlSegments, extras }) => this.router.navigate(urlSegments, extras)),
                switchMap(() => this.modalController.getTop()),
                filter((modal) => !!modal && !routerDialogIgnoreList.includes(modal?.id)),
                tap((modal) => modal.dismiss())
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private router: Router,
        private modalController: ModalController,
        private appCoreFacadeService: AppCoreFacadeService,
        private appRoutingFacadeService: AppRoutingFacadeService
    ) {}
}
