{
    "ACCOUNT_SETTINGS_HEADING": "Account Settings",
    "ACCOUNT_SETTINGS_VERIFICATION": "2-Factor Authentication Device",
    "ACCOUNT_SETTINGS_VERIFICATION_DEVICE_NAME": "Device Name",
    "ACCOUNT_SETTINGS_VERIFICATION_DATE_ADDED": "Date Added",
    "ACCOUNT_SETTINGS_VERIFICATION_IDENTIFIER": "Identifier",
    "ACCOUNT_SETTINGS_VERIFICATION_ADD_DEVICE": "Add Device",
    "ACCOUNT_SETTINGS_VERIFICATION_EDIT_MODAL_SUCCESS": "Device successfully updated",
    "ACCOUNT_SETTINGS_VERIFICATION_DELETE_SUCCESS": "Device successfully deleted",
    "ACCOUNT_SETTINGS_VERIFICATION_EDIT_MODAL_TITLE": "Edit device name",
    "ACCOUNT_SETTINGS_VERIFICATION_EDIT_MODAL_SAVE": "Save",
    "ACCOUNT_SETTINGS_VERIFICATION_EDIT_MODAL_CANCEL": "Cancel",
    "ACCOUNT_SETTINGS_VERIFICATION_EDIT_DEVICE_ARIA_LABEL": "Edit Device",
    "ACCOUNT_SETTINGS_VERIFICATION_DELETE_DEVICE_ARIA_LABEL": "Delete Device",
    "ACCOUNT_SETTINGS_DELETE": "Delete device",
    "ACCOUNT_SETTINGS_NO_DEVICES": "You currently do not have a 2FA device set up. To secure your account add a device.",
    "ACCOUNT_SETTINGS_CONFIRM_DELETE": "Confirm",
    "ACCOUNT_SETTINGS_CONFIRM_DELETE_MESSAGE": "Are you sure you want to delete this device?",
    "ACTION_POPOVER_COPY_HINT": "Copy to clipboard",
    "ACTION_POPOVER_CLOSE_HINT": "Close",
    "ACTION_POPOVER_EMAIL_HEADER": "Email Address",
    "ACTION_POPOVER_EMAIL_TOAST": "Email address copied to clipboard",
    "ACTION_POPOVER_EMAIL_HINT": "Compose email",
    "ACTION_POPOVER_PHONE_HEADER": "Phone Number",
    "ACTION_POPOVER_PHONE_TOAST": "Phone number copied to clipboard",
    "ACTION_POPOVER_PHONE_HINT": "Dial phone number",
    "ACTION_POPOVER_WEBSITE_HEADER": "Website",
    "ACTION_POPOVER_WEBSITE_TOAST": "Website copied to clipboard",
    "ACTION_POPOVER_WEBSITE_HINT": "Open website",
    "LIGHTBOX_IMAGE_CLOSE": "Close image",
    "URL_UTILITY_WARNING_TITLE": "Warning",
    "URL_UTILITY_WARNING_MESSAGE": "This link takes you to an external site. Are you sure you want to continue?",
    "ACTIVITY_FEED_ACTION_MENU_DELETE_TITLE": "Delete Post",
    "ACTIVITY_FEED_ACTION_MENU_EDIT_TITLE": "Edit Post",
    "ACTIVITY_FEED_ACTION_MENU_HIDE_POST_TITLE": "Hide Post",
    "ACTIVITY_FEED_ACTION_MENU_REVEAL_POST_TITLE": "Unhide Post",
    "ACTIVITY_FEED_ACTION_MENU_HIDE_COMMENT_TITLE": "Hide Comment",
    "ACTIVITY_FEED_ACTION_MENU_REVEAL_COMMENT_TITLE": "Unhide Comment",
    "ACTIVITY_FEED_ACTION_MENU_HIDE_TITLE": "Hide all posts from this user",
    "ACTIVITY_FEED_ACTION_MENU_PIN": "Pin Post",
    "ACTIVITY_FEED_ACTION_MENU_PIN_UNPIN": "Unpin Post",
    "ACTIVITY_FEED_ACTION_MENU_REPORT_TITLE": "Report this post",
    "ACTIVITY_FEED_COMMENT_ACTION_MENU_DELETE_TITLE": "Delete Comment",
    "ACTIVITY_FEED_COMMENT_ACTION_MENU_REPORT_TITLE": "Report this comment",
    "ACTIVITY_FEED_COMMENT_PENDING_MODERATION": "(Pending Moderation)",
    "ACTIVITY_FEED_LOGIN_PROMPT_COMMENT_LIKE": "You must be logged in to like a comment",
    "ACTIVITY_FEED_LOGIN_PROMPT_COMMENT_REPLY": "You must be logged in to reply to a comment",
    "ACTIVITY_FEED_LOGIN_PROMPT_POST_LIKE": "You must be logged in to like a post",
    "ACTIVITY_FEED_LOGIN_TO_POST": "Log In to Post",
    "ACTIVITY_FEED_NEW_POSTS_BANNER": "New posts",
    "ACTIVITY_FEED_COMMENT_BUTTON": "Comment",
    "ACTIVITY_FEED_LIKE_BUTTON": "Like",
    "ACTIVITY_FEED_REPLY_BUTTON": "Reply",
    "ACTIVITY_FEED_NEW_COMMENT_PLACEHOLDER": "Write a new comment...",
    "ACTIVITY_FEED_COMMENTS": "{{comments}} comments",
    "ACTIVITY_FEED_POST_REPLIED_BY": "Replied by {{comments}} people",
    "ACTIVITY_FEED_POST_LIKED_BY": "Liked by {{likes}} people",
    "ACTIVITY_FEED_COMMENT_OPEN_MORE_OPTIONS": "Open more options",
    "ACTIVITY_FEED_POST_MAX_MESSAGE": "You've reached the limit of 2000 characters",
    "ACTIVITY_FEED_POST_COMMENT_BUTTON_ARIA_LABEL": "Comment on this post",
    "ACTIVITY_FEED_POST_LIKE_BUTTON_ARIA_LABEL": "Like this post",
    "ACTIVITY_FEED_POST_ACTIONS_BUTTON_ARIA_LABEL": "Open actions for this post",
    "ACTIVITY_FEED_POST_MODAL_ADD_PHOTO": "Add Photo",
    "ACTIVITY_FEED_POST_MODAL_ADD_VIDEO": "Add Video",
    "ACTIVITY_FEED_POST_MODAL_CANCEL": "Cancel",
    "ACTIVITY_FEED_POST_MODAL_CHECK_IN": "I've just checked into the event!",
    "ACTIVITY_FEED_POST_MODAL_POST": "Post",
    "ACTIVITY_FEED_POST_MODAL_TITLE": "New Post",
    "ACTIVITY_FEED_POST_MODAL_TOAST_VIDEO_UPLOADED": "Video uploaded successfully, your video will now be processed. This could take some time.",
    "ACTIVITY_FEED_POST_MODAL_TOAST_VIDEO_CANCELLED": "Video upload cancelled",
    "ACTIVITY_FEED_EDIT_POST_MODAL_TITLE": "Edit Post",
    "ACTIVITY_FEED_EDIT_POST_MODAL_SAVE": "Save Changes",
    "ACTIVITY_FEED_EDIT_POST_MODAL_CANCEL": "Cancel",
    "ACTIVITY_FEED_POST_PAGE_POST": "Post",
    "ACTIVITY_FEED_POST_PAGE_REPLYING_TO": "Replying to {{name}}",
    "ACTIVITY_FEED_POST_PAGE_WRITE_COMMENT_HINT": "Write a comment...",
    "ACTIVITY_FEED_POST_PAGE_WRITE_REPLY_HINT": "Write a reply...",
    "ACTIVITY_FEED_POST_PENDING_MODERATION": "(Pending Moderation)",
    "ACTIVITY_FEED_POST_PINNED": "Pinned post",
    "ACTIVITY_FEED_POST_HIDDEN": "Post Hidden",
    "ACTIVITY_FEED_COMMENT_HIDDEN": "Comment Hidden",
    "ACTIVITY_FEED_REPORT_MENU_ITEM_FAKE": "Fake News",
    "ACTIVITY_FEED_REPORT_MENU_ITEM_MISLEADING": "Misleading",
    "ACTIVITY_FEED_REPORT_MENU_ITEM_NOT_SURE": "Not sure, I just think the moderator needs to see this",
    "ACTIVITY_FEED_REPORT_MENU_ITEM_SEXUALLY_INAPPROPRIATE": "Sexually Inappropriate",
    "ACTIVITY_FEED_REPORT_MENU_ITEM_SPAM": "Spam",
    "ACTIVITY_FEED_REPORT_MENU_ITEM_VIOLENT": "Violence",
    "ACTIVITY_FEED_REPORT_MENU_SUBMIT": "Submit",
    "ACTIVITY_FEED_REPORT_MENU_SUBTITLE": "What are the reasons for flagging this post?",
    "ACTIVITY_FEED_REPORT_MENU_TITLE": "Report this post",
    "ACTIVITY_FEED_SUBMIT_CHECK_IN": "Check-in",
    "ACTIVITY_FEED_SUBMIT_HINT": "What's on your mind?",
    "ACTIVITY_FEED_SUBMIT_PHOTO": "Add Photo",
    "ACTIVITY_FEED_SUBMIT_VIDEO": "Add Video",
    "ACTIVITY_FEED_TOAST_REPORTED": "This post has been reported. Thank you for your feedback",
    "ACTIVITY_FEED_VIEW_ALL_COMMENTS": "View all comments",
    "TRUNCATED_TEXT_SEE_MORE": "See more",
    "TRUNCATED_TEXT_SEE_LESS": "See less",
    "ADD_IMAGE": "Add Image",
    "AGENDA_ADDED_SESSION_TO_PERSONAL_SCHEDULE": "Session added to your event schedule",
    "AGENDA_ARIA_LABEL_EXPAND_TRACKS": "Expand Tracks",
    "AGENDA_ARIA_LABEL_LIVESTREAM_STATUS": "Livestream Status",
    "AGENDA_ARIA_LABEL_NOTES_CANCEL": "Cancel",
    "AGENDA_ARIA_LABEL_PANEL_CHAT": "Chat Panel",
    "AGENDA_ARIA_LABEL_PANEL_DESCRIPTION": "Description Panel",
    "AGENDA_ARIA_LABEL_PANEL_DOCUMENTS": "Documents Panel",
    "AGENDA_ARIA_LABEL_PANEL_FEEDBACK": "Feedback Panel",
    "AGENDA_ARIA_LABEL_PANEL_GROUP": "{{group}} Panel",
    "AGENDA_ARIA_LABEL_PANEL_LIVESTREAM": "Livestream Panel",
    "AGENDA_ARIA_LABEL_PANEL_LIVESTREAM_INTERACTIONS": "Livestream Interactions Panel",
    "AGENDA_ARIA_LABEL_PANEL_LIVESTREAM_INTERACTIONS_TABS": "Livestream Interactions Panel Tabs",
    "AGENDA_ARIA_LABEL_PANEL_NOTES": "Notes Panel",
    "AGENDA_ARIA_LABEL_PANEL_POLL": "Poll Panel",
    "AGENDA_ARIA_LABEL_PANEL_QANDA": "Q and A Panel",
    "AGENDA_ARIA_LABEL_PANEL_SPEAKERS": "Speakers Panel",
    "AGENDA_ARIA_LABEL_PANEL_TRACKS": "Tracks Panel",
    "AGENDA_ARIA_LABEL_PANEL_VIDEO": "Video Meeting Panel",
    "AGENDA_ARIA_LABEL_QANDA_UPVOTES": "{{upvotes}} Upvotes",
    "AGENDA_CARDS_WAITING_LIST_LABEL": "Waiting list",
    "AGENDA_CARDS_WAITING_LIST_DESC": "Please note: You are currently on the waiting list for this session. We will automatically assign you a ticket if spaces become available.",
    "AGENDA_CARDS_FEATURES_CHAT": "Chat",
    "AGENDA_CARDS_FEATURES_MEDIA": "Media",
    "AGENDA_CARDS_FEATURES_POLLING": "Live Polling",
    "AGENDA_CARDS_FEATURES_QANDA": "Q&A",
    "AGENDA_CARDS_ATTENDEES_LIST_TITLE": "Attendees",
    "AGENDA_CARDS_SPEAKERS_LIST_TITLE": "Speakers",
    "AGENDA_CARDS_DOCUMENTS_LIST_TITLE": "Documents",
    "AGENDA_CALENDAR_CLASH": "Clash",
    "AGENDA_CALENDAR_NO_FILTERS": "No Filters",
    "AGENDA_CALENDAR_NO_LOCATION": "No Location",
    "AGENDA_DATE_SESSIONS_ANNOUNCEMENT": "{{date}} has {{sessions}} sessions",
    "AGENDA_DATE_SESSIONS_SEARCHED_ANNOUNCEMENT": "Search term {{search}} has {{sessions}} sessions",
    "AGENDA_EVENT_TIMEZONE": "Event timezone",
    "AGENDA_LOCAL_TIMEZONE": "My timezone",
    "AGENDA_DOWNLOAD_CALENDAR": "Download Calendar",
    "AGENDA_DOWNLOAD_FULL": "Download Full Agenda",
    "AGENDA_PERSONAL_DOWNLOAD": "Download My Schedule",
    "AGENDA_EMPTY_PERSONAL_SCHEDULE": "No sessions have been saved to your event schedule.",
    "AGENDA_EVENT_ACTION": "Go to page for this event",
    "AGENDA_FILTER_APPLIED_TOAST": "Filter applied",
    "AGENDA_FILTER_CLEARED_TOAST": "Filter cleared",
    "AGENDA_FILTER_CLOSE_BUTTON_LABEL": "Close",
    "AGENDA_FILTER_CONTAINING_BUTTON": "Includes",
    "AGENDA_FILTER_EXPAND_FILTER_BUTTON_LABEL": "Expand filter",
    "AGENDA_FILTER_ONLY_BUTTON": "Only",
    "AGENDA_FILTER_SAVE_BUTTON_LABEL": "Save filter",
    "AGENDA_FILTER_SESSIONS_BUTTON": "Filter Sessions",
    "AGENDA_GOOGLE_CALENDAR": "Google Calendar",
    "AGENDA_ICAL": "iCal",
    "AGENDA_LIST_HEADER_LABEL": "{{date}} has {{sessions}} sessions",
    "AGENDA_NAV_FILTER": "Filter",
    "AGENDA_ON_NOW": "On Now",
    "AGENDA_NAV_MY_SCHEDULE": "View my schedule",
    "AGENDA_NO_RESULTS": "No Results",
    "AGENDA_NO_DATA": "Currently nothing to see here",
    "AGENDA_NO_RESULTS_TRACKS": "Nothing matches your criteria.",
    "AGENDA_NO_RESULTS_TRACKS_DATE": "Please select a different date.",
    "AGENDA_NO_SAVED_SESSIONS_MESSAGE": "Add sessions to your schedule by tapping the star icon in the schedule list",
    "AGENDA_NO_SAVED_SESSIONS_TITLE": "No sessions on this date",
    "AGENDA_SEARCH": "Search",
    "AGENDA_SESSION_CHAT_TAB_DELETE_ITEM": "Delete Message",
    "AGENDA_SESSION_CHAT_TAB_FAILED": "Failed to connect to chat room. The session may have ended.",
    "AGENDA_SESSION_CONTENT_TAB_HEADER": "Content",
    "AGENDA_SESSION_CHAT_TAB_HEADER": "Chat",
    "AGENDA_SESSION_CHAT_TAB_INPUT_PLACEHOLDER": "Say something...",
    "AGENDA_SESSION_CHAT_TAB_MAX_REACHED": "You've reached the limit of 500 characters",
    "AGENDA_SESSION_CHAT_TAB_NOT_AUTHED": "You must be logged in to chat",
    "AGENDA_SESSION_CHAT_TAB_IMAGE_BUTTON": "Image Picker",
    "AGENDA_SESSION_CHAT_TAB_EMOJI_BUTTON": "Emoji Picker",
    "AGENDA_SESSION_CHAT_TAB_SEND_BUTTON": "Send",
    "AGENDA_SESSION_CHAT_TAB_NO_MESSAGES": "There are no messages here yet, type in the box below to send a message.",
    "AGENDA_SESSION_DOCUMENTS": "Documents",
    "AGENDA_SESSION_FEEDBACK_TAB_AUTH_REQUIRED": "You must be logged in to complete this survey",
    "AGENDA_SESSION_FEEDBACK_TAB_EMPTY": "This live stream does not have a survey linked",
    "AGENDA_SESSION_FEEDBACK_TAB_HEADER": "Feedback",
    "AGENDA_SESSION_FILTERS": "Filters",
    "AGENDA_SESSION_HEADER_FEEDBACK": "Feedback",
    "AGENDA_SESSION_LIVESTREAM_LEAVE_BUTTON": "Leave Session",
    "AGENDA_SESSION_NOT_AUTHED": "You must be logged in to view this content.",
    "AGENDA_SESSION_LOGIN_BUTTON": "Log in",
    "AGENDA_SESSION_LOOPIN_HEADER": "Join this session in a networking room",
    "AGENDA_SESSION_LOOPIN_JOIN_BUTTON": "Join Now",
    "AGENDA_SESSION_LOOPIN_TEXT": "Network with other members of the audience, work together, chat and engage.",
    "AGENDA_SESSION_MEETING_CARD_HINT": "We recommend using head sets whilst in the video meeting and mute yourself when not speaking.",
    "AGENDA_SESSION_MEETING_CARD_JOIN_BUTTON": "Join Meeting",
    "AGENDA_SESSION_MEETING_CARD_TITLE": "Join the video meeting",
    "AGENDA_SESSION_NOTES_TAB_AUTOSAVE_TOAST_TITLE": "Note Updated",
    "AGENDA_SESSION_NOTES_TAB_AUTOSAVE_TOAST": "Successfully autosaved note: '{{ title }}'",
    "AGENDA_SESSION_NOTES_TAB_BUTTON_LABEL_BACK": "Back",
    "AGENDA_SESSION_NOTES_TAB_EDITING_HEADER_LABEL": "Editing Note:",
    "AGENDA_SESSION_NOTES_TAB_NEW_HEADER": "New note",
    "AGENDA_SESSION_NOTES_TAB_BUTTON_LABEL_DELETE_CANCEL": "Cancel",
    "AGENDA_SESSION_NOTES_TAB_BUTTON_LABEL_DELETE_CONFIRM": "Confirm",
    "AGENDA_SESSION_NOTES_TAB_BUTTON_LABEL_DELETE_MULTIPLE": "Delete",
    "AGENDA_SESSION_NOTES_TAB_BUTTON_LABEL_DELETE_SINGLE": "Delete",
    "AGENDA_SESSION_NOTES_TAB_BUTTON_LABEL_EMAIL": "Email",
    "AGENDA_SESSION_NOTES_TAB_BUTTON_LABEL_NEW_NOTE": "New note",
    "AGENDA_SESSION_NOTES_TAB_BUTTON_LABEL_SAVE": "Save",
    "AGENDA_SESSION_NOTES_TAB_HEADER": "Notes",
    "AGENDA_SESSION_NOTES_TAB_NOT_AUTHED": "You must be logged in to create notes",
    "AGENDA_SESSION_POLL_TAB_EMPTY": "This session has no poll linked",
    "AGENDA_SESSION_POLL_TAB_HEADER": "Poll",
    "AGENDA_SESSION_QANDA": "Question and answers",
    "AGENDA_SESSION_QANDA_TAB_EMPTY": "This session has no Q&A linked",
    "AGENDA_SESSION_QANDA_TAB_HEADER": "Q&A",
    "AGENDA_SESSION_QANDA_VOTE": "vote",
    "AGENDA_SESSION_QANDA_VOTES": "votes",
    "AGENDA_SESSION_QANDA_REPLY": "Reply",
    "AGENDA_SESSION_QANDA_EXPAND": "See more",
    "AGENDA_SESSION_QANDA_COLLAPSE": "See less",
    "AGENDA_SESSION_DIALOG_YES_BUTTON": "Yes",
    "AGENDA_SESSION_DIALOG_NO_BUTTON": "No",
    "AGENDA_SESSION_SCHEDULE_DIALOG_ADD_HEADER": "Add to your event schedule",
    "AGENDA_SESSION_SCHEDULE_DIALOG_ADD": "Would you like to add this session to your event schedule?",
    "AGENDA_SESSION_SCHEDULE_DIALOG_REMOVE_HEADER": "Remove from your event schedule",
    "AGENDA_SESSION_SCHEDULE_DIALOG_REMOVE": "Would you like to remove this session from your event schedule?",
    "AGENDA_SESSION_WAITING_LIST_DIALOG_REMOVE_HEADER": "Leave waiting list",
    "AGENDA_SESSION_WAITING_LIST_DIALOG_REMOVE": "Would you like to leave the waiting list for this session? You will lose your place in the queue",
    "AGENDA_SESSION_WAITING_LIST_DIALOG_ADD_HEADER": "Join waiting list",
    "AGENDA_SESSION_WAITING_LIST_DIALOG_ADD": "This session is full, would you like to join the waiting list for this session?",
    "AGENDA_SESSION_SPEAKERS": "Speakers",
    "AGENDA_SESSION_ATTENDEES": "Attendees",
    "AGENDA_SESSION_ATTENDEES_LOAD_MORE": "Load More",
    "AGENDA_SESSION_STATUS_ACTIVE": "Active",
    "AGENDA_SESSION_STATUS_IDLE": "Idle",
    "AGENDA_SESSION_VIDEO_POPOUT_PLAYER": "Popout Player",
    "AGENDA_SESSION_VIDEO_POPOUT": "Popout",
    "AGENDA_SESSION_VIDEO_SHOW_STREAM": "Show Player",
    "AGENDA_SESSION_VIDEO_HIDE_STREAM": "Hide Player",
    "AGENDA_SESSION_VIDEO_STREAM": "Stream",
    "AGENDA_SESSION_VIDEO_LEAVE": "Leave",
    "AGENDA_SESSION_REMOVE_SCHEDULE": "Remove from your schedule",
    "AGENDA_SESSION_ADD_SCHEDULE": "Add to your schedule",
    "AGENDA_SESSION_EMBEDDED_VIDEO_CALL_CONFIRM_TITLE": "Leave Video Call",
    "AGENDA_SESSION_EMBEDDED_VIDEO_CALL_CONFIRM_MESSAGE": "Click confirm to leave your current video call session",
    "AGENDA_SESSION_EMBEDDED_VIDEO_CALL_NOT_STARTED": "This session has not started yet. Please wait, or check your agenda and come back later.",
    "AGENDA_SESSION_EMBEDDED_VIDEO_CALL_ENDED": "This session has ended. Please return to your agenda to find what's next.",
    "AGENDA_SPACES_LEFT": "{{spaces}} spaces left",
    "AGENDA_FULL": "Session full",
    "AGENDA_ON_WAITING_LIST": "On waiting list",
    "AGENDA_TOAST_ADDED": "Session added to your event schedule",
    "AGENDA_TOAST_REMOVED": "Session removed from your event schedule",
    "AGENDA_TOAST_WAITING_LIST_REMOVED": "You have been removed from the waiting list for this session",
    "AGENDA_TOAST_WAITING_LIST_ADDED": "You have been added to the waiting list for this session",
    "AGENDA_UNAVAILABLE": "The agenda module is currently unavailable. Please contact our support team or try again later",
    "ALERTS_ALL_ARCHIVED": "You have archived all of your alerts.",
    "ALERTS_ARCHIVE": "Archive",
    "ALERTS_ARCHIVED_ALERTS_TAB": "Archived",
    "ALERTS_ARIA_LABEL_ITEM": "Alert",
    "ALERTS_ARIA_LABEL_LIST": "Alerts",
    "ALERTS_CLOSE_ALERT": "Close",
    "ALERTS_GO_TO_ALERTS": "Go To Alerts",
    "ALERTS_MY_ALERTS_TAB": "Inbox",
    "ALERTS_NEW": "New",
    "ALERTS_NO_ALERTS": "No alerts have been found",
    "ALERTS_UNARCHIVE": "Unarchive",
    "ALERTS_READ": "Read",
    "ALERTS_UNREAD": "Unread",
    "ALERTS_ALL_READ": "All Read",
    "ALERTS_ALL_UNREAD": "All Unread",
    "ALERTS_NOTIFICATION_VIEW": "View Now",
    "ALERTS_MARK_READ": "Mark as read",
    "ALERTS_MARK_ALL_READ": "Mark all as read",
    "ALERTS_ARCHIVE_ALL": "Archive all",
    "ALERTS_SURVEY_COMPLETE": "Complete",
    "ALERTS_SURVEY_INCOMPLETE": "Incomplete",
    "ALERTS_SURVEY_ATTACHED": "There is a survey attached to this alert. Would you like to load it?",
    "ALERTS_SURVEY_LOAD_BUTTON": "Load survey",
    "ALERTS_DIGEST_TITLE": "You have {{count}} unread alerts waiting to be reviewed",
    "ALERTS_DIGEST_INFO": "See what you've missed",
    "ALERTS_DIGEST_ARIA_LABEL": "View unread alerts in your alerts inbox",
    "ALERTS_BULK_ACTION_TITLE": "Confirm action",
    "ALERTS_BULK_ACTION_CONFIRM": "Confirm",
    "ALERTS_BULK_ACTION_CANCEL": "Cancel",
    "ALERTS_MARK_ALL_READ_MESSAGE": "Please confirm that you wish to mark all alerts as read",
    "ALERTS_ARCHIVE_ALL_MESSAGE": "Please confirm that you wish to archive all of your alerts",
    "ALERTS_TOAST_ARCHIVED": "Alert has been archived",
    "ALERTS_TOAST_UNARCHIVED": "Alert has been unarchived",
    "ALERTS_TOAST_ALL_ARCHIVED": "All alerts have been archived",
    "ALERTS_TOAST_ALL_READ": "All alerts have been marked as read",
    "APP_SIDEBAR_RIGHT_ACCOUNT": "Account Settings",
    "APP_SIDEBAR_RIGHT_CHANGE_LANGUAGE": "Change Language",
    "APP_SIDEBAR_RIGHT_CLOSE": "Close sidebar",
    "APP_SIDEBAR_RIGHT_CONTAINER": "Enter New Event Code",
    "APP_SIDEBAR_RIGHT_DEVELOPER": "Toggle Developer Mode",
    "APP_SIDEBAR_RIGHT_GO_BACK": "Exit user settings menu",
    "APP_SIDEBAR_RIGHT_LOGGED_IN_AS": "You are logged in as {{firstName}} {{secondName}} ({{suffix}})",
    "APP_SIDEBAR_RIGHT_LOGIN": "Login",
    "APP_SIDEBAR_RIGHT_LOGOUT": "Logout",
    "APP_SIDEBAR_RIGHT_MY_MEETINGS": "My Meetings",
    "APP_SIDEBAR_RIGHT_MY_PROFILE": "Edit Profile",
    "APP_SIDEBAR_RIGHT_MY_SURVEYS": "My Surveys",
    "APP_SIDEBAR_RIGHT_NOTES": "My Notes",
    "APP_SIDEBAR_RIGHT_MY_SCHEDULE": "My Event Schedule",
    "APP_SIDEBAR_RIGHT_MY_BADGE": "My Badge",
    "APP_SIDEBAR_RIGHT_INSTALL": "Install App",
    "APP_SIDEBAR_RIGHT_COOKIE_CONSENT": "Cookie Consent",
    "APP_SIDEBAR_RIGHT_COOKIE_NOTICE": "Cookie Notice",
    "APP_SIDEBAR_RIGHT_BADGE_CONTACTS": "My Connections",
    "APP_SIDEBAR_RIGHT_BUSINESS_CARDS": "My Business Cards",
    "APP_SIDEBAR_RIGHT_MEETINGS": "My Meetings",
    "APP_SIDEBAR_RIGHT_CUSTOM_CSS_SHOW": "Enable Custom CSS",
    "APP_SIDEBAR_RIGHT_CUSTOM_CSS_HIDE": "Disable Custom CSS",
    "AUTHENTICATION_ACCESS_DENIED_CONTENT": "You do not have access to this application. If you believe this to be a mistake, please contact your representative.",
    "AUTHENTICATION_ACCESS_DENIED_HEADER": "Sorry",
    "AUTHENTICATION_ARIA_LABEL_FORM": "Login Form",
    "AUTHENTICATION_ARIA_LABEL_SHOW_PASSWORD": "Show password",
    "AUTHENTICATION_BACK_TO_CONTAINER": "Back to Portal",
    "AUTHENTICATION_BACK_TO_PORTAL": "Back to Portal",
    "AUTHENTICATION_PORTAL_NO_ACCESS": "You have not been granted access to the target application. Speak to your event organiser to resolve this issue.",
    "AUTHENTICATION_CANCEL_PASSWORD_RESET_DIALOG": "Cancel",
    "AUTHENTICATION_CONFIRM_NEW_PASSWORD": "Confirm",
    "AUTHENTICATION_CONFIRM_PASSWORD": "Confirm Password",
    "AUTHENTICATION_CONFIRM_PASSWORD_RESET_DIALOG": "Confirm",
    "AUTHENTICATION_EMAIL_ADDRESS": "Email Address",
    "AUTHENTICATION_EMAIL_ADDRESS_MIN_LENGTH": "Must be more than 3 characters and look like an e-mail address.",
    "AUTHENTICATION_EMAIL_ADDRESS_VALID": "Needs to be a valid email address",
    "AUTHENTICATION_FIRST_NAME": "First Name",
    "AUTHENTICATION_IMAGE_HEADER_ALT_TEXT": "Login banner image",
    "AUTHENTICATION_LAST_NAME": "Last Name",
    "AUTHENTICATION_LOG_IN": "Log In",
    "AUTHENTICATION_LOG_IN_REGISTER": "Login/Register",
    "AUTHENTICATION_LOGIN": "Login",
    "AUTHENTICATION_LOGIN_PROMPT": "You must be logged in to add a session to your schedule",
    "AUTHENTICATION_LOGOUT": "Logout",
    "AUTHENTICATION_NEXT": "Next",
    "AUTHENTICATION_MFA_DISMISS": "Ask me later",
    "AUTHENTICATION_MFA_DISMISS_FOREVER": "Don't ask me again",
    "AUTHENTICATION_MFA_ENABLE": "Setup A Device",
    "AUTHENTICATION_MFA_COPIED": "2FA code copied to clipboard",
    "AUTHENTICATION_MFA_COPY": "Copy",
    "AUTHENTICATION_MFA_LOST": "I’ve lost my Authenticator device",
    "AUTHENTICATION_MFA_LOST_ALERT_HEADING": "Lost your authenticator device?",
    "AUTHENTICATION_MFA_LOST_ALERT_MESSAGE": "You will need to contact your event organiser and request for your device to be unlinked from your account.",
    "AUTHENTICATION_MFA_LOST_ALERT_BUTTON": "Ok",
    "AUTHENTICATION_MFA_PROMPT_OPTIONAL": "Enable 2-Factor Authentication?",
    "AUTHENTICATION_MFA_PROMPT_OPTIONAL_DESCRIPTION": "2-Factor Authentication can help improve the security of your account by using a separate app to login in addition to your email and password.",
    "AUTHENTICATION_MFA_PROMPT_OPTIONAL_DESCRIPTION_HINT": "If you don't wish to setup 2FA now you can always add a device later in the \"My Profile\" section of the app.",
    "AUTHENTICATION_MFA_PROMPT_EMAIL": "This app requires 2-Factor Authentication",
    "AUTHENTICATION_MFA_PROMPT_EMAIL_DESCRIPTION": "2-Factor Authentication can help improve the security of your account by using a separate app to login in addition to your email and password.",
    "AUTHENTICATION_MFA_PROMPT_EMAIL_DESCRIPTION_HINT": "If you don't wish to setup 2FA now, we'll send a verification code to your registered email address instead.",
    "AUTHENTICATION_MFA_SEND_EMAIL": "Email me a code",
    "AUTHENTICATION_MFA_RESEND_EMAIL": "Didn't receive the email?",
    "AUTHENTICATION_MFA_RESEND_EMAIL_ALERT_HEADING": "Didn't receive the email?",
    "AUTHENTICATION_MFA_RESEND_EMAIL_ALERT_MESSAGE": " The email may take a few minutes to be delivered. Please ensure that you’ve checked your junk / spam folder. If you still haven’t received the email then click the resend button below.",
    "AUTHENTICATION_MFA_RESEND_EMAIL_ALERT_BUTTON": "Resend",
    "AUTHENTICATION_MFA_RESEND_EMAIL_ALERT_CANCEL_BUTTON": "Cancel",
    "AUTHENTICATION_LOADING_HEADER": "This is taking a bit longer than usual",
    "AUTHENTICATION_LOADING_MESSAGE": "Sit tight, we'll automatically try to log you in again in a few seconds.",
    "AUTHENTICATION_FAILED_HEADER": "It looks like something has gone wrong.",
    "AUTHENTICATION_FAILED_MESSAGE": "Please try again later, or contact your event administrator.",
    "AUTHENTICATION_PASSCODE": "Passcode",
    "AUTHENTICATION_PASSCODE_REQUIRED": "Please enter the passcode (case sensitive) to use the app.",
    "AUTHENTICATION_PASSWORD": "Password",
    "AUTHENTICATION_PASSWORD_MIN_LENGTH": "Password must be more than 8 characters.",
    "AUTHENTICATION_PASSWORD_RESET": "Forgot Password?",
    "AUTHENTICATION_PASSWORD_RESET_DIALOG_HEADER": "Password Reset",
    "AUTHENTICATION_PASSWORDS_NOT_MATCH": "Passwords do not match",
    "AUTHENTICATION_PRIVATE_BROWSING_WARNING": "As you are in incognito\/private browsing mode, you will not remain logged in after closing this window. Switch to a regular window to remain logged in persistently.",
    "AUTHENTICATION_PRIVATE_HEADER": "Please enter your email address",
    "AUTHENTICATION_PUBLIC_HEADER": "You can log in here to gain further access to the app.",
    "AUTHENTICATION_REGISTER": "Register",
    "AUTHENTICATION_REQUEST_VERIFICATION_SUCCESS": "If your email is present on the app then a verification email has been sent to your provided email address.",
    "AUTHENTICATION_REQUIRED": "Required",
    "AUTHENTICATION_RESET_PASSWORD_SUCCESS": "Password has been reset! You can now log in with your new password.",
    "AUTHENTICATION_SECURE_HEADER": "This application is for registered delegates only. Please enter the email address you registered with to gain access.",
    "AUTHENTICATION_SEND_EMAIL_VERIFICATION": "Send verification link",
    "AUTHENTICATION_SESSION_EXPIRED": "Your current session has expired. Please log back in again to continue.",
    "AUTHENTICATION_SET_NEW_PASSWORD": "Set your new password",
    "AUTHENTICATION_SIGN_IN_WITH": "Sign in with",
    "AUTHENTICATION_SSO_DEFAULT_HINT": "Login with {{provider}}",
    "AUTHENTICATION_SSO_SIGN_IN_BUTTON": "Sign In",
    "AUTHENTICATION_SUBMIT_PASSCODE_FAILURE": "Invalid Passcode",
    "AUTHENTICATION_SUCCESS": "Successfully logged in",
    "AUTHENTICATION_SUCCESS_TOAST_MESSAGE": "You have logged in successfully",
    "AUTHENTICATION_SUFFIX": "Suffix",
    "AUTHENTICATION_TRUST": "Trust this device for 30 days",
    "AUTHENTICATION_UNLOCK": "Unlock",
    "AUTHENTICATION_VERIFY_EMAIL_INSTRUCTIONS": "This app requires that you must verify your email address and set a password to continue",
    "AUTHENTICATION_VERIFY_EMAIL_SUCCESS": "Your email address has now been verified. You may now log in",
    "AUTHENTICATION_WELCOME": "Welcome Back",
    "AUTHENTICATION_NOT_ALLOWED": "You are not permitted to access this page",
    "BACK_TO_HOMEPAGE": "Back to homepage",
    "BUSINESS_CARD_BACKGROUND_OPTION_EVENT": "Event",
    "BUSINESS_CARD_BACKGROUND_OPTION_TEAL": "Teal",
    "BUSINESS_CARD_BACKGROUND_OPTION_NAVY": "Navy",
    "BUSINESS_CARD_BACKGROUND_OPTION_BLUE": "Blue",
    "BUSINESS_CARD_BACKGROUND_OPTION_PURPLE": "Purple",
    "BUSINESS_CARD_BACKGROUND_OPTION_RED": "Red",
    "BUSINESS_CARD_BACKGROUND_OPTION_NO_BACKGROUND": "No background",
    "BUSINESS_CARD_NOTIFICATION_ARIA_LABEL": "Open my business cards",
    "BUSINESS_CARD_NOTIFICATION_REQUEST_SENT_TITLE": "New Business Card Request",
    "BUSINESS_CARD_NOTIFICATION_REQUEST_SENT_MESSAGE": "{{ name }} would like to exchange business cards with you",
    "BUSINESS_CARD_NOTIFICATION_REQUEST_ACCEPTED_TITLE": "Business Card Exchange Accepted",
    "BUSINESS_CARD_NOTIFICATION_REQUEST_ACCEPTED_MESSAGE": "{{ name }} Has accepted your request to exchange business cards with you",
    "BUSINESS_CARDS_EXCHANGE_MODAL_TITLE": "Request to exchange business cards",
    "BUSINESS_CARDS_EXCHANGE_MODAL_MESSAGE_LABEL": "Message",
    "BUSINESS_CARDS_EXCHANGE_MODAL_PLACEHOLDER": "Write a message for them...",
    "BUSINESS_CARDS_EXCHANGE_MODAL_SEND_BUTTON": "Send",
    "BUSINESS_CARDS_EXCHANGE_SUCCESS_TOAST": "Your exchange request has been sent successfully",
    "BUSINESS_CARDS_SCAN_SUCCESS_ALERT_TITLE": "Scan Successful",
    "BUSINESS_CARDS_SCAN_SUCCESS_ALERT_MESSAGE": "{{firstName}} {{lastName}} has been added to your business cards.",
    "BUSINESS_CARDS_SCAN_SUCCESS_ALERT_DISMISS": "Ok",
    "BUSINESS_CARDS_SCAN_FAILURE_ALERT_TITLE": "Scan Failure",
    "BUSINESS_CARDS_SCAN_FAILURE_ALERT_MESSAGE": "This does not seem like a delegate badge QR code for this event.",
    "BUSINESS_CARDS_SCAN_FAILURE_ALERT_DISMISS": "Ok",
    "BUSINESS_CARDS_EMAIL": "Send an email",
    "BUSINESS_CARDS_CHAT": "Chat",
    "BUSINESS_CARDS_CALL": "Call this person",
    "BUSINESS_CARDS_VIDEO": "Start a video call",
    "BUSINESS_CARDS_DOWNLOAD": "Download card",
    "BUSINESS_CARDS_NOTES": "Notes",
    "BUSINESS_CARDS_DELETE": "Delete card",
    "BUSINESS_CARDS_DOWNLOAD_ALL": "Download all cards",
    "BUSINESS_CARDS_SORT_MOST_RECENT": "Sort cards by most recent",
    "BUSINESS_CARDS_SORT_ALPHABETICAL": "Sort cards alphabetically",
    "BUSINESS_CARDS_TOGGLE_SEARCH": "Toggle search",
    "CALENDAR_CLASH": "Clash",
    "CANCEL": "Cancel",
    "CONFIRM": "Confirm",
    "CHANGE_APP_LANGUAGE_ERROR": "The language has been changed but there was a problem downloading the translated data. We will try to download this later.",
    "CHANGE_APP_LANGUAGE_PROGRESS": "Changing App Language",
    "CHANGE_APP_LANGUAGE_SUCCESS": "App Language Changed",
    "CHAT_AUTH_REQUIRED": "You must be logged in to chat with another user",
    "CHAT_DELETED_USER_NAME": "Deleted User",
    "CHAT_LIST_SEARCH_PLACEHOLDER": "Search people...",
    "CHAT_MESSAGE": "Message",
    "CHAT_MESSAGE_ARIA_LABEL": "{{first_name}} {{last_name}} said",
    "CHAT_NAVIGATION_TITLE_CHAT": "Chat",
    "CHAT_NAVIGATION_TITLE_CHAT_LIST": "My Chats",
    "CHAT_NO_ACTIVE_CHATS": "You have no active chats",
    "CHAT_NO_CHATS": "No chats or people to chat with have been found",
    "CHAT_POTENTIALS_HEADER": "Start a new chat",
    "CHAT_SEND": "Send",
    "CHAT_YOUR_MESSAGES_WILL_APPEAR_HERE": "Your chat messages will appear here",
    "CHAT_YOU_SENT_LAST": "You: {{message}}",
    "CHAT_POPUP_TITLE": "Chat Messages",
    "CHAT_POPUP_MESSAGE_PLACEHOLDER": "Message",
    "CHAT_POPUP_EMOJI_BUTTON": "Emoji Picker",
    "CHAT_POPUP_SEND_BUTTON": "Send",
    "CLOSE": "Close",
    "CONFIRM_DELETE_TITLE": "Are you sure?",
    "CONFIRM_DELETE_MESSAGE": "Deleting this item is permanent and cannot be undone.",
    "COLOR_PICKER_PLACEHOLDER": "Select a colour",
    "COLOR_PICKER_ACCEPT": "Accept",
    "COLOR_PICKER_CANCEL": "Cancel",
    "COMPANIES": "Companies",
    "COMPANIES_ARIA_LABEL_FILTER": "Filter list",
    "COMPANIES_COMPANY_ABOUT": "About",
    "COMPANIES_COMPANY_ADDRESS": "Address",
    "COMPANIES_COMPANY_ARIA_LABEL_EMAIL": "Email company",
    "COMPANIES_COMPANY_ARIA_LABEL_PHONE": "Phone company",
    "COMPANIES_COMPANY_ARIA_LABEL_WEBSITE": "Open company website",
    "COMPANIES_COMPANY_BUTTONS_CHAT": "Chat",
    "COMPANIES_COMPANY_BUTTONS_EMAIL": "Email",
    "COMPANIES_COMPANY_BUTTONS_MEETING": "Schedule Meeting",
    "COMPANIES_COMPANY_BUTTONS_BUSINESS_CARD": "Leave your business card",
    "COMPANIES_COMPANY_BUTTONS_PHONE_NUMBER": "Call",
    "COMPANIES_COMPANY_BUTTONS_WEBSITE": "Website",
    "COMPANIES_COMPANY_DOCUMENTS": "Documents",
    "COMPANIES_COMPANY_DOWNLOADS": "Downloads",
    "COMPANIES_COMPANY_GROUPS": "Groups",
    "COMPANIES_COMPANY_LOGO_ALT_TEXT": "Company logo",
    "COMPANIES_COMPANY_REPRESENTATIVES": "Company Representatives",
    "COMPANIES_COMPANY_RESOURCES": "Resources",
    "COMPANIES_COMPANY_SOCIALMEDIA": "Social Media",
    "COMPANIES_COMPANY_SPEAK_EXPERT": "Speak with an expert",
    "COMPANIES_NO_RESULTS": "No Results",
    "CONFIRM_PASSWORD_LABEL": "Password",
    "CONFIRM_PASSWORD_SUBTITLE": "Please enter your password to continue",
    "CONFIRM_PASSWORD_TITLE": "Confirm Password",
    "CONTAINER_BUTTON": "Enter",
    "CONTAINER_EVENT_NOT_FOUND": "Event not found. Check your shortcode and try again.",
    "CONTAINER_PLACEHOLDER": "Enter Event Code",
    "CONTAINER_TITLE": "Welcome to your event",
    "CONTINUE": "Continue",
    "COOKIES_ACCEPT": "Accept All",
    "COOKIES_CUSTOMISE": "Customise",
    "COOKIES_HEADER": "Cookies",
    "COOKIES_DESCRIPTION_ACKNOWLEDGE": "By continuing to use the site, you agree to the privacy policy.",
    "COOKIES_DESCRIPTION": "This website uses cookies to ensure you get the best experience on our website.",
    "COOKIES_CUSTOMISE_HEADER": "Cookie Consent",
    "COOKIES_CUSTOMISE_SAVE_SELECTION": "Save Selection",
    "COOKIES_CUSTOMISE_DESCRIPTION": "We would like to use cookies and similar technologies for the following purposes:",
    "COOKIES_CUSTOMISE_DESCRIPTION2": "Learn more about how we use cookies.",
    "COOKIES_CUSTOMISE_ESSENTIAL_TITLE": "Essential or strictly necessary cookies",
    "COOKIES_CUSTOMISE_ESSENTIAL_DESCRIPTION": "These are essential cookies which allow us to provide basic functionality throughout our platform.",
    "COOKIES_CUSTOMISE_ANALYTICS_TITLE": "Analytics cookies",
    "COOKIES_CUSTOMISE_ANALYTICS_DESCRIPTION": "These cookies are used to collect analytical data on our platform and helps us to understand how we can improve our platform for our users.",
    "COOKIE_NOTICE_TITLE": "Cookie Notice",
    "COOKIE_NOTICE_WHAT_ARE_COOKIES_TITLE": "What are cookies?",
    "COOKIE_NOTICE_WHAT_ARE_COOKIES_DESC": "A cookie is a piece of information in the form of a very small text file that is placed on an internet user’s computer. It is generated by a web page server (which is basically the computer that operates the web site) and can be used by that server whenever the user visits the site. A cookie can be thought of as an internet user’s identification card, which tells a web site when the user has returned.<br><br>Cookies can’t harm your computer and we don’t store any personally identifiable information about you on any of our cookies.",
    "COOKIE_NOTICE_WHY_USE_COOKIES_TITLE": "Why do we use cookies on the CrowdComms Platform?",
    "COOKIE_NOTICE_WHY_USE_COOKIED_DESC": "CrowdComms uses two types of cookies: cookies set by CrowdComms and cookies set by third parties (i.e. other websites or services).<br><br>CrowdComms use Strictly Necessary Cookies for the purposes of authenticating you when you use the CrowdComms platform.",
    "COOKIE_NOTICE_COOKIES_SET_TITLE": "Cookies set by third parties",
    "COOKIE_NOTICE_COOKIES_SET_DESC": "CrowdComms works with a number of third parties to provide services which help us to keep the platform tailored to our users’ needs. Some of these partners use cookies to help them deliver these services. A list of third party cookies can be found below:",
    "COOKIE_NOTICE_TABLE_COOKIE_NAME_HEADING": "Cookie name",
    "COOKIE_NOTICE_TABLE_PURPOSE_HEADING": "Purpose",
    "COOKIE_NOTICE_TABLE_GA_LABEL": "Google Analytics",
    "COOKIE_NOTICE_TABLE_GA_DESC": "Google Analytics collects statistical data about how you use the CrowdComms platform. The information is used to track the performance of the CrowdComms platform and to help us improve the service we offer to you.",
    "COOKIE_NOTICE_TABLE_MUX_LABEL": "MUX",
    "COOKIE_NOTICE_TABLE_MUX_DESC": "MUX Provides live streaming and video services to CrowdComms. In addition they provide tracking data on usage of the video / streaming features including watch times, playback quality, general user experience and other metrics. CrowdComms also sends user ids in the tracking data which are used to examine individual user journeys through the platform. No personally identifiable info is sent to MUX.",
    "COOKIE_NOTICE_TABLE_HOTJAR_LABEL": "Hotjar",
    "COOKIE_NOTICE_TABLE_HOTJAR_DESC": "We use Hotjar in order to better understand our users’ needs and to optimize this service and experience. Hotjar is a technology service that helps us better understand our users’ experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users’ behaviour and their devices. This includes a device's IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and the preferred language used to display our website. Hotjar stores this information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any of the data collected on our behalf. For further details, please see the ‘about Hotjar’ section of",
    "COOKIE_NOTICE_TABLE_HOTJAR_LINK_TEXT": "Hotjar's support site",
    "COOKIE_NOTICE_TABLE_HOTJAR_LINK": "https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information",
    "COOKIE_NOTICE_THIRD_PARTY_SUBHEADING": "3rd party Cookies set by client",
    "COOKIE_NOTICE_THIRD_PARTY_HOTJAR": "The CrowdComms platform provides the client the ability to use their own Hotjar account key, we don’t have control of what “cookies” they may use.",
    "COOKIE_NOTICE_THIRD_PARTY_DESC": "Below are details on the information that the client could collect from you.<br><br>If the CrowdComms platform detects that the client has set a tag, you have the option to either accept or decline the cookies used by that tag.",
    "COOKIE_NOTICE_OPT_OUT": "You can opt-out of the analytical cookie category (except strictly necessary cookies) by clicking on the “cookie settings” button.",
    "COOKIE_NOTICE_FULL_TERMS_DESC": "Please note that this Cookie Notice forms part of our",
    "COOKIE_NOTICE_FULL_TERMS_LINK": "Privacy Notice",
    "DIALOG_CONFIRM_TITLE": "Are you sure?",
    "DIALOG_CONFIRM_MESSAGE": "This action is permanent and cannot be undone.",
    "DIALOG_CONFIRM_OK": "Confirm",
    "DIALOG_CONFIRM_CANCEL": "Cancel",
    "DIALOG_SUCCESS_TITLE": "Success!",
    "DIALOG_SUCCESS_MESSAGE": "The action was successful.",
    "DIALOG_SUCCESS_OK": "Ok",
    "DIALOG_ERROR_TITLE": "Error!",
    "DIALOG_ERROR_MESSAGE": "We seem to have ran into a problem.",
    "DIALOG_ERROR_OK": "Ok",
    "TIMEZONE_NOTICE_LOCAL": "This time is being displayed in your local timezone ({{ timezone }})",
    "TIMEZONE_NOTICE_EVENT": "This time is being displayed in the event timezone ({{ timezone }})",
    "TIMEZONE_TRANSFORMED": "This time in the event timezone is {{ time }}.",
    "TIMEZONE_TOAST_TITLE": "Timezone updated",
    "TIMEZONE_TOAST_LOCAL": "Session times now being displayed in your local timezone ({{ timezone }})",
    "TIMEZONE_TOAST_EVENT": "Session times now being displayed in the event timezone ({{ timezone }})",
    "GLOBAL_BANNER_DISMISS": "Dismiss",
    "DOCUMENTS_NO_RESULTS": "No results",
    "EMPTY_DATA_DEFAULT_TITLE": "We couldn't find any data to display",
    "ENTER_POLL_ANSWER": "Enter your answer here",
    "ERR0R_404_BUTTON": "Take me home",
    "ERR0R_404_MESSAGE": "Sorry we can't find what you are looking for",
    "ERR0R_404_TITLE": "Something has gone wrong",
    "ERROR_APP_FAILED": "App failed to load",
    "ERROR_APP_FAILED_RETRY": "RETRY",
    "ERROR_APP_FAILED_TO_LOAD_A": "Unfortunately, the app failed to load",
    "ERROR_APP_FAILED_TO_LOAD_B": "Please check your connection and try again",
    "ERROR_POLL_ANSWER_MAXLENGTH": "Your answer is too long.",
    "ERROR_REVOKED_TOKEN": "You have been logged out, please login again to continue",
    "ERROR_SOMETHING_WENT_WRONG": "Something went wrong whilst processing your request",
    "FEED_ADD_A_COMMENT_PLACEHOLDER": "Add a comment",
    "FEED_ADD_A_COMMENT_SUBMIT_BUTTON": "Submit",
    "FEED_LOGIN_TO_POST": "Login to post",
    "FEED_MODERATION_WARNING_COMMENT_CONTENT": "Comments made to this activity feed must be approved by a moderator before they will appear visible to everyone else.",
    "FEED_MODERATION_WARNING_POST_CONTENT": "Posts made to this activity feed must be approved by a moderator before they will appear visible to everyone else.",
    "FEED_MODERATION_WARNING_TITLE": "Moderation Warning",
    "FEED_POST_COMMENT_BUTTON": "Comment",
    "FEED_POST_LIKE": "Like",
    "FEED_POST_LIKE_BUTTON": "Like",
    "FEED_POST_LIKE_PLURAL": "Likes",
    "FEED_POST_MAXLENGTH": "The status has to be less than {{length}} characters long.",
    "FEED_POST_PENDING_MODERATION": "(Pending moderation)",
    "FEED_POST_SHOW_LESS_COMMENTS": "Show less",
    "FEED_POST_SHOW_MORE_COMMENTS": "Show more",
    "FEED_POST_SUBMISSION_ADD_PHOTO": "Add Photo",
    "FEED_POST_SUBMISSION_LABEL": "What's on your mind?",
    "FEED_POST_SUBMISSION_SUBMIT": "Submit",
    "FEED_POST_UNLIKE_BUTTON": "Unlike",
    "FEED_POST_VIEW_ALL_X_COMMENTS": "View all {{comments}} comments",
    "FORM_EMAIL": "Needs to be a valid email address",
    "FORM_EMAIL_ADDRESS": "Needs to be a valid email address",
    "FORM_MAX_LENGTH": "This is too long",
    "FORM_REQUIRED": "Required",
    "FORM_URL": "URL Must start with http\/https",
    "FORM_WHITESPACE": "Must not contain only whitespace",
    "GAMIFICATION_BUTTON_LOGIN": "Log In",
    "GAMIFICATION_BUTTON_ANSWER_LABEL": "Answer",
    "GAMIFICATION_BUTTON_CANCEL": "Cancel",
    "GAMIFICATION_BUTTON_SUBMIT": "Submit",
    "GAMIFICATION_CHALLENGES": "Challenges",
    "GAMIFICATION_COMPLETE_CHALLENGE": "Challenge Completed",
    "GAMIFICATION_REPEATABLE_CHALLENGE": "Completable {{count}} Times",
    "GAMIFICATION_COMPLETED_ON": "Completed on",
    "GAMIFICATION_FOUND_A_CODE": "Found a code?",
    "GAMIFICATION_FOUND_A_CODE_MESSAGE": "Enter a code below to earn points",
    "GAMIFICATION_CODE_LABEL": "Code",
    "GAMIFICATION_LOG_IN_TO_PLAY": "Log In To Play",
    "GAMIFICATION_LOG_IN_TO_PLAY_MESSAGE": "To compete in the challenges you must log into the app first",
    "GAMIFICATION_LOG_IN_TO_PLAY_MESSAGE_SINGLE": "Log in to answer this challenge",
    "GAMIFICATION_NAV_ITEM_CHALLENGES": "Challenges",
    "GAMIFICATION_NAV_ITEM_INFO": "Info",
    "GAMIFICATION_NAV_ITEM_LEADERBOARD": "Leaderboard",
    "GAMIFICATION_NO_ATTEMPTS_LEFT": "You have no more attempts left",
    "GAMIFICATION_NO_CHALLENGES": "No challenges available at this moment",
    "GAMIFICATION_ENTER_CODE": "Enter Code",
    "GAMIFICATION_POINT": "Point",
    "GAMIFICATION_POINTS": "Points",
    "GAMIFICATION_POINTS_AVAILABLE": "Points Available",
    "GAMIFICATION_POINTS_EARNED": "Points Awarded",
    "GAMIFICATION_POINTS_MISSED": "Points Missed",
    "GAMIFICATION_ATTEMPTS_LEFT": "Attempts Left",
    "GAMIFICATION_COMPLETIONS_LEFT": "Completions Left",
    "GAMIFICATION_RANK": "Rank",
    "GAMIFICATION_QR_CHALLENGE_MESSAGE": "To complete this challenge you must scan the correct QR code",
    "GAMIFICATION_QR_CHALLENGE_OPEN_SCANNER_BUTTON": "Open QR Scanner",
    "GAMIFICATION_POLL_RESPONSE_CHALLENGE_MESSAGE": "To complete this challenge you must respond to a poll",
    "GAMIFICATION_CHOICE_POLL_RESPONSE_CHALLENGE_MESSAGE": "To complete this challenge you must respond to a poll with a specific answer",
    "GAMIFICATION_ACTIVITY_FEED_POST_CHALLENGE_MESSAGE": "To complete this challenge you must post to an activity feed",
    "GAMIFICATION_ACTIVITY_FEED_COMMENT_CHALLENGE_MESSAGE": "To complete this challenge you must comment on a post in an activity feed",
    "GAMIFICATION_ACTIVITY_FEED_UPLOAD_CHALLENGE_MESSAGE": "To complete this challenge you must upload an image to an activity feed",
    "GAMIFICATION_ACCEPT_BUSINESS_CARD_CHALLENGE_MESSAGE": "To complete this challenge you must accept a business card exchange",
    "GAMIFICATION_RETURNED_BUSINESS_CARD_CHALLENGE_MESSAGE": "To complete this challenge you must have a business card exchange accepted",
    "GAMIFICATION_CUSTOMIZE_BUSINESS_CARD_CHALLENGE_MESSAGE": "To complete this challenge you must customise your business card",
    "GAMIFICATION_QANDA_QUESTION_CREATED_CHALLENGE_MESSAGE": "To complete this challenge you must submit a question to a Q&A",
    "GAMIFICATION_PROFILE_PICTURE_UPLOADED_CHALLENGE_MESSAGE": "To complete this challenge you must upload a profile picture",
    "GAMIFICATION_CHALLENGE_COMPLETED_MODAL_TITLE": "Congratulations!",
    "GAMIFICATION_CHALLENGE_COMPLETED_MODAL_MESSAGE": "You have completed a challenge and earned {{points}} points",
    "GAMIFICATION_CHALLENGE_ALREADY_COMPLETED_MODAL_TITLE": "You have already completed this challenge",
    "GAMIFICATION_CHALLENGE_FAILED_MODAL_TITLE": "Incorrect Answer",
    "GAMIFICATION_FILTER_ALL_CHALLENGES": "All",
    "GAMIFICATION_FILTER_COMPLETED_CHALLENGES": "Completed",
    "GAMIFICATION_FILTER_INCOMPLETE_CHALLENGES": "Incomplete",
    "GENERAL_NOTE": "Empty",
    "NOW_NEXT_EXIT_LABEL": "Cancel",
    "NOW_NEXT_NEXT_LABEL": "Next",
    "NOW_NEXT_TITLE": "Up next...",
    "NOW_NEXT_BACKWARDS_BUTTON": "Backwards",
    "NOW_NEXT_FORWARD_BUTTON": "Forward",
    "NOW_NEXT_UPCOMING": "Starts in",
    "NOW_NEXT_IN_PROGRESS": "In progress",
    "NOW_NEXT_ENDED": "Ended",
    "HTML_COUNTDOWN_DAYS": "Days",
    "HTML_COUNTDOWN_HOURS": "Hours",
    "HTML_COUNTDOWN_MINUTES": "Minutes",
    "HTML_COUNTDOWN_SECONDS": "Seconds",
    "HTML_COUNTDOWN_TITLE": "Event Starts In:",
    "HTTP_RESPONSE_MESSAGE_200": "Success",
    "HTTP_RESPONSE_MESSAGE_400": "Bad Request",
    "HTTP_RESPONSE_MESSAGE_401": "Unauthorized",
    "HTTP_RESPONSE_MESSAGE_403": "Forbidden",
    "HTTP_RESPONSE_MESSAGE_500": "Internal Server Error",
    "I18N_APP_SELECT_NEW_LANGUAGE": "Select Language",
    "I18N_APP_SELECT_NEW_LANGUAGE_NOTE": "Note: Changing an app language will affect also language of displayed content.",
    "I18N_CANCEL_SELECT_LANGUAGE_DIALOG_HEADER": "Cancel",
    "I18N_CANCEL_SUBMIT_LANGUAGE_DIALOG_HEADER": "Select",
    "I18N_SELECT_LANGUAGE_DIALOG_HEADER": "Change app language",
    "ICON": "Icon",
    "IFRAME_NO_LINK": "This module is currently empty!",
    "IMAGE_DELETE_MESSAGE": "Are you sure you want to delete this image?",
    "IMAGE_DELETE_TITLE": "Delete Image",
    "IMAGE_DELETED": "Image deleted",
    "IMAGE_DELETEING": "Deleting image",
    "IMAGE_UPLOAD": "Image Upload",
    "IMAGE_UPLOAD_BUTTON": "Upload",
    "IMAGE_UPLOAD_ERROR": "There was an error uploading your image.",
    "IMAGE_UPLOAD_MAX_SIZE": "Image must be less than {{ maxSize }}",
    "IMAGE_UPLOAD_TYPES": "Please upload a PNG, JPG or GIF.",
    "IMAGE_VIEWER_TITLE": "Image Viewer",
    "IMAGE_PICKER_UPLOAD": "Upload a {{label}}",
    "IMAGE_PICKER_UPLOADING": "Uploading...",
    "IMAGE_PICKER_ERROR": "Uh oh there was an error, please try again",
    "IMAGE_PICKER_REMOVE": "Remove",
    "IMAGE_PICKER_REPLACE": "Replace",
    "INFOBOOTH_MORE": "More",
    "INFOBOOTH_NO_RESULTS": "No results",
    "LIVE_POLL_CLOSED": "Closed",
    "LIVE_POLL_COME_BACK": "Please check by later",
    "LIVE_POLL_NOT_ACTIVE": "No polls are currently active",
    "LIVE_POLL_NOT_LINKED": "There are currently no linked polls",
    "LIVE_POLL_VOTES": "{{votes}} votes",
    "LIVE_POLL_RATING_LABEL": "Average score",
    "LIVE_POLL_RESULTS_BACK": "Back",
    "LIVE_POLL_RESULTS_RETURN": "Show results",
    "LIVE_POLL_ERROR_POLL_GROUPS": "Something went wrong whilst fetching the live poll groups",
    "LIVE_POLL_ERROR_LIVE_POLLS": "Something went wrong whilst fetching the live polls",
    "LIVE_STREAM_ACTIVE_TOAST_MESSAGE": "The live stream is about to start...",
    "LIVE_STREAM_GO_BUTTON": "Go to live stream",
    "LIVE_STREAM_IDLE_MESSAGE": "This stream is not live at the moment, please check back later.",
    "LIVE_STREAM_NO_DATA_MESSAGE": "There are no streams to display",
    "LIVE_STREAM_PIP_BUTTON_POPOUT": "Show in popout",
    "LIVE_STREAM_PIP_BUTTON_TOOLTIP": "View live stream and continue browsing the platform",
    "LIVE_STREAM_PIP_HINT": "Watch the stream in a mini-player whilst using the rest of the app",
    "LIVE_STREAM_REPLAY_BUTTON": "Watch replay",
    "LIVE_STREAM_UNSUPPORTED_DIALOG_MESSAGE": "For the best experience please use the latest versions of Chrome or Edge for the desktop.",
    "LIVE_STREAM_UNSUPPORTED_DIALOG_TITLE": "Info",
    "LOCATION_CHIP_ARIA_LABEL": "Open location",
    "LOGIN_BUTTON": "Log in",
    "LOOPIN_ROOM_WHOLE_EVENT": "Whole event",
    "LOOPIN_ROOM_STARTS_AT": "Starts at",
    "LOOPIN_ROOM_ENDS_AT": "Ends at",
    "MAIN_ARIA_LABEL": "Main Page Content",
    "MAP_SEE_ALL_PINDROPS": "See all",
    "MAPS_NO_RESULTS": "No results",
    "MEDIA_MENU_CAMERA": "Take Photo",
    "MEDIA_MENU_CANCEL": "Cancel",
    "MEDIA_MENU_GALLERY": "Photo Library",
    "MEDIA_MENU_HEADER": "Add Photo",
    "MEDIA_MENU_UPLOAD": "Upload Image",
    "MFA_QR_HEADING": "Set up 2-Factor Authentication",
    "MFA_QR_HEADING_REQUIRED": "This app requires 2-Factor Authentication",
    "MFA_QR_MESSAGE": "Use a 3rd-party authenticator app to improve the security of your account and prevent unauthorised access. Simply open your authenticator app and scan the QR Code below or enter the unique key into the app to continue.",
    "MFA_QR_SCAN": "Scan QR code using your app",
    "MFA_QR_CODE": "Enter this code in to your app",
    "MFA_QR_CANCEL": "Cancel",
    "MFA_QR_ENTER": "Enter code",
    "MFA_VALIDATE_HEADING": "Authenticate device",
    "MFA_VALIDATE_HEADING_EMAIL": "Please check your email",
    "MFA_VALIDATE_MESSAGE": "Open your third party authenticator app and enter the 6-digit code below.",
    "MFA_VALIDATE_MESSAGE_EMAIL": "We've sent a unique 6-digit code to your registered email address. Please enter the code into the box below to proceed.",
    "MFA_VALIDATE_CANCEL": "Back",
    "MFA_VALIDATE_ENTER": "Confirm",
    "MFA_VALIDATE_SUCCESS": "Device has been successfully linked",
    "MFA_VALIDATE_FAILURE": "There was an issue validating your device",
    "NO_DATA": "No data",
    "NO_SEARCH_RESULTS": "We can't find any results for this search",
    "NO_SURVEYS": "No Surveys available at this moment",
    "NO_SURVEYS_PERSONAL": "You have not submitted any survey answers",
    "NOT_AUTHED": "You must be logged in to view this content.",
    "NOTES_AGENDA": "Go to agenda",
    "NOTES_ALL": "ALL",
    "NOTES_CANCEL": "Cancel",
    "NOTES_DELETE_ALL": "Delete all",
    "NOTES_DELETE_ALL_MESSAGE": "Please confirm you wish to delete all of your notes attached to session(s) as well as general notes.",
    "NOTES_DELETE_ALL_TITLE": "Delete all notes",
    "NOTES_DELETE_MESSAGE": "Please confirm you wish to delete the selected notes.",
    "NOTES_DELETE_SELECTED": "Delete Selected",
    "NOTES_DELETE_TITLE": "Delete selected note(s)",
    "NOTES_DELETED": "Deleted",
    "NOTES_DELETING": "Deleting",
    "NOTES_EMAIL_TOAST": "An email containing your notes should arrive shortly.",
    "NOTES_GENERAL": "General",
    "NOTES_GENERAL_NOTE": "General Note",
    "NOTES_GENERAL_TITLE": "Note title",
    "NOTES_TITLE_PLACEHOLDER": "Add a title",
    "NOTES_MY_TITLE": "My Notes",
    "NOTES_NO_NOTES": "You have no notes",
    "NOTES_NOT_UPLOADED": "There has been a problem saving the note",
    "NOTES_CONTENT_LABEL": "Note content",
    "NOTES_PLACEHOLDER": "Write your notes here",
    "NOTES_SAVE": "Save",
    "NOTES_SAVED": "Note has been saved",
    "NOTES_SESSIONS": "Sessions",
    "NOTES_TITLE": "Notes",
    "NOTES_TITLE_NEEDED": "You need a valid note title to save",
    "NOTES_UPLOADED": "Uploaded",
    "NOTES_UPLOADING": "Uploading",
    "PASSWORD_STRENGTH_WEAK": "Your password is easily guessable",
    "PASSWORD_STRENGTH_MEDIUM": "Almost there",
    "PASSWORD_STRENGTH_STRONG": "Your password is strong",
    "PASSWORD_REQUIREMENTS_LENGTH": "Must be at least 8 characters long",
    "PASSWORD_REQUIREMENTS_NUMBER": "Must contain at least 1 number",
    "PASSWORD_REQUIREMENTS_CASING": "Must have a mix of uppercase and lowercase characters",
    "PASSWORD_REQUIREMENTS_SPECIAL_CHAR": "Must contain at least 1 special character",
    "WEB_PUSH_REQUEST_SUCCESS": "You have successfully subscribed to web push notifications",
    "WEB_PUSH_REQUEST_FAILURE": "Something went wrong whilst subscribing to web push notifications.",
    "WEB_PUSH_REQUEST_FAILURE_PERMISSION_DENIED": "You have denied permission to receive web push notifications, please enable them in your browser settings.",
    "WEB_PUSH_NOT_AVAILABLE": "Web push notifications are currently not supported in your browser. Try using Chrome or the latest version of Safari",
    "ENABLE_WEB_PUSH": "Enable web push notifications",
    "REFRESH_WEB_PUSH": "Refresh web notifications subscription",
    "ALREADY_WEB_PUSH": "Web push notifications have already been enabled for this event. If you are having issues receiving them, try refreshing your token.",
    "WEB_PUSH_POPUP_TITLE": "Enable Notifications?",
    "WEB_PUSH_POPUP_MESSAGE": "Never miss a thing! Get notified of updates, even when away from this app.",
    "WEB_PUSH_POPUP_YES": "Allow",
    "WEB_PUSH_POPUP_NO": "Not now",
    "WEB_PUSH_POPUP_LATER_TITLE": "Notifications Denied",
    "WEB_PUSH_POPUP_LATER": "If you change your mind, you can always turn it on later in your account settings",
    "PAGE_TITLE_CONTAINER": "Container",
    "PAGE_TITLE_APP_FAILED": "Failed to Load",
    "PAGE_TITLE_NOT_FOUND": "Not Found",
    "PAGE_TITLE_LOGIN": "Login",
    "PAGE_TITLE_DOWNLOAD": "Download",
    "PAGE_TITLE_VERIFY_EMAIL": "Verify Email",
    "PAGE_TITLE_RESET_PASSWORD": "Reset Password",
    "PAGE_TITLE_CHATS": "Chats",
    "PAGE_TITLE_CHAT": "Chat",
    "PAGE_TITLE_MY_PROFILE": "My Profile",
    "PAGE_TITLE_ALERTS": "Alerts",
    "PAGE_TITLE_NOTES": "Notes",
    "PAGE_TITLE_PERSONAL_AGENDA": "My Event Schedule",
    "PAGE_TITLE_SURVEYS": "My Surveys",
    "PAGE_TITLE_ACCOUNT": "My Account",
    "PAGE_TITLE_FEED_POST": "Feed Post",
    "PAGE_TITLE_BADGE": "My Badge",
    "PAGE_TITLE_BADGE_CONTACTS": "Event Connections",
    "PAGE_TITLE_BUSINESS_CARDS": "Business Cards",
    "PAGE_TITLE_MEETINGS": "My Meetings",
    "PEOPLE_ABOUT_TITLE": "About",
    "PEOPLE_BUSINESS_CARD_TITLE": "Profile Card",
    "PEOPLE_BUSINESS_CARD_EXCHANGE": "Exchange business cards",
    "PEOPLE_ARIA_LABEL_LIST": "People",
    "PEOPLE_BUTTON_CHAT": "Chat",
    "PEOPLE_BUTTON_SCHEDULE_MEETING": "Meeting",
    "PEOPLE_BUTTON_EMAIL": "Email",
    "PEOPLE_BUTTON_LOCATION": "Address",
    "PEOPLE_BUTTON_MEETING": "Meet",
    "PEOPLE_BUTTON_PHONE": "Phone",
    "PEOPLE_BUTTON_VIDEO": "Video Call",
    "PEOPLE_BUTTON_VIDEO_UNAVAILABLE": "Unavailable",
    "PEOPLE_BUTTON_WEBSITE": "Website",
    "PEOPLE_DOCUMENTS_TITLE": "Documents",
    "PEOPLE_LOGIN_TO_VIDEO_CHAT": "You must be logged in to start a video chat",
    "PEOPLE_NAVIGATION_TITLE": "User Profile",
    "PEOPLE_NO_RESULTS": "No results",
    "PEOPLE_OFFLINE": "Offline",
    "PEOPLE_ONLINE": "Online",
    "PEOPLE_SESSIONS_TITLE": "Sessions",
    "PEOPLE_SOCIAL_MEDIA_TITLE": "Social Media",
    "PERSON_POPOVER_ARIA_LABEL": "Show more details of {{first_name}}",
    "PERSON_POPOVER_PROFILE_BUTTON_HINT": "Go to profile",
    "PERSON_POPOVER_VBO_BUTTON_HINT": "Video Call",
    "PERSON_POPOVER_CHAT_BUTTON_HINT": "Message",
    "PERSON_POPOVER_CLOSE_BUTTON_HINT": "Close",
    "PERSON_POPOVER_MEETING_BUTTON_HINT": "Schedule meeting",
    "POLL_CONTINUE": "Continue",
    "POLL_MUST_LOGIN": "You need to be logged in to answer this poll",
    "POLL_NEXT_QUESTION": "Go to next question",
    "POLL_PREV_QUESTION": "Go to previous question",
    "POLL_QUESTION_HEADER": "{{value}} of {{max}}",
    "POLL_SUBMITTED": "Poll submitted!",
    "PORTAL_ENTER_APP": "Enter App",
    "PORTAL_FILTER_ALL": "All Events",
    "PORTAL_FILTER_FEATURE": "Feature Events",
    "PORTAL_FILTER_LIVE": "Live Events",
    "PORTAL_FILTER_PAST": "Past Events",
    "PORTAL_FILTER_UPCOMING": "Upcoming Events",
    "PORTAL_NO_APPS": "No Portal Apps Found",
    "PORTAL_REGISTER": "Register",
    "PRIVACY_SETTINGS_DIALOG_CONTENT": "Please check the settings below to choose if you want to be available for video meetings, chat messages, and if you want other users to see your profile on the app.",
    "PRIVACY_SETTINGS_DIALOG_LABEL_AVAILABLE_FOR_VIDEO_MEETINGS": "Available For Video Meetings",
    "PRIVACY_SETTINGS_DIALOG_SUBLABEL_AVAILABLE_FOR_VIDEO_MEETINGS": "If unchecked, you will not be able to make private videos calls.",
    "PRIVACY_SETTINGS_DIALOG_LABEL_CHAT": "Available for Chat Messaging",
    "PRIVACY_SETTINGS_DIALOG_SUBLABEL_CHAT": "If unchecked, you will not be able to send or receive chat messages.",
    "PRIVACY_SETTINGS_DIALOG_LABEL_PROFILE_VISIBLE": "Profile Visible In App",
    "PRIVACY_SETTINGS_DIALOG_SUBLABEL_PROFILE_VISIBLE": "If unchecked, your profile will not show in any people modules.",
    "PRIVACY_SETTINGS_DIALOG_LABEL_MEETING_BOOKING": "Available for Meeting Booking",
    "PRIVACY_SETTINGS_DIALOG_SUBLABEL_MEETING_BOOKING": "If unchecked, you will not be able to create and receive meeting invites from other delegates.",
    "PRIVACY_SETTINGS_DIALOG_LABEL_BUSINESS_CARDS": "Available for Business Card Exchanges",
    "PRIVACY_SETTINGS_DIALOG_SUBLABEL_BUSINESS_CARDS": "If unchecked, you will not be able to create or exchange your own virtual business card.",
    "PRIVACY_SETTINGS_DIALOG_OK_BUTTON": "Ok",
    "PRIVACY_SETTINGS_DIALOG_TITLE": "Privacy Settings",
    "QANDA_NO_RESULTS": "No Results",
    "QANDA_SUBMITTING_QUESTION_SUCCESS": "Your question has been submitted",
    "QANDA_PANEL_INPUT_PLACEHOLDER": "Write a question...",
    "QANDA_PANEL_SUBMIT_BUTTON_ARIA_LABEL": "Submit question",
    "QANDA_PANEL_SUBMIT_FIRST_QUESTION": "Be the first to submit a question",
    "QANDA_PANEL_QUESTION_SUBMISSIONS_HIDDEN": "Submissions for this Q&A will be revealed during the session",
    "REQUEST_RESET_PASSWORD_SUCCESS": "An email to change your password has been sent to the email address entered. This will contain instructions on how to reset your password. If the email doesn't arrive in your inbox please check your spam and junk folders.",
    "RESPONSE_SUBMITTED": "Response submitted!",
    "SCHEDULE_LIST_ADD_LABEL": "Add to your event schedule",
    "SCHEDULE_LIST_LOCATIONS": "{{num_locations}} locations",
    "SCHEDULE_LIST_REMOVE_LABEL": "Remove from your event schedule",
    "SCHEDULE_LIST_TRACKS": "{{num_tracks}} filters",
    "SEARCH": "Search",
    "SENDING": "Sending",
    "SHARED_SEARCH": "Search",
    "SIDEBAR_LEFT_BACK_TO_PORTAL": "Back to ",
    "SIDEBAR_LEFT_COLLAPSE_HINT": "Collapse Menu",
    "SIDEBAR_LEFT_EXPAND_HINT": "Expand Menu",
    "SIDEBAR_LEFT_TERMS_AND_CONDITIONS": "Terms & Conditions",
    "SIDEBAR_RIGHT_UPDATED_TOAST": "App successfully updated",
    "SIDEBAR_RIGHT_UPDATING_TOAST": "Updating app",
    "SOCIAL_MEDIA_FACEBOOK": "View facebook feed",
    "SOCIAL_MEDIA_INSTAGRAM": "View on Instagram",
    "SOCIAL_MEDIA_LINKEDIN": "View on LinkedIn",
    "SOCIAL_MEDIA_NO_TWEETS": "There are no Tweets to display",
    "SOCIAL_MEDIA_SCAN_SNAPCHAT_QR_CODE": "Scan the QR code below using the Snapchat app or click on it to open the Snapchat account",
    "SOCIAL_MEDIA_SNAPCHAT": "View on Snapchat",
    "SOCIAL_MEDIA_TWITTER": "View Twitter feed",
    "SOCIAL_MEDIA_TWITTER_HASHTAG": "View Twitter hashtag",
    "SOCIAL_MEDIA_VIEW_ON_FACEBOOK": "View on Facebook",
    "SOCIAL_MEDIA_VIEW_ON_TWITTER": "View on Twitter",
    "SOCIAL_MEDIA_VIEW_ON_YOUTUBE": "View on Youtube",
    "SOCIAL_MEDIA_YOUTUBE": "View on YouTube",
    "SOCIALMEDIA_NO_SOCIAL_MEDIA": "No social media",
    "SUBMIT": "Submit",
    "SURVEY_ALREADY_ANSWERED": "Survey Answered",
    "SURVEY_COMPLETE": "Thank you for completing the survey",
    "SURVEY_MUST_LOGIN": "You need to be logged in to answer this survey.",
    "SURVEY_STARS": "Stars",
    "SURVEYS_PROXY_NEXT": "Next",
    "SURVEYS_PROXY_COMPLETE": "You have completed your proxy votes. Please click the submit button below to save your responses and return to your initial vote.",
    "SURVEYS_PROXY_INTRO": "You are now casting proxy vote",
    "SURVEYS_PROXY_AMOUNT": "You have {{ amount }} proxy votes to cast",
    "SURVEYS_PROXY_BUTTON": "Cast them now",
    "SURVEYS_CANNOT_VOTE": "You have not been selected to vote on this poll",
    "SURVEYS_PROXY_SAVE": "Submit answers",
    "SURVEYS_PROXY_RETURN": "Cancel",
    "SURVEYS_PROXY_SAVE_MESSAGE": "Proxy votes have been cast successfully",
    "SURVEYS_PROXY_WARNING": "Please note, you can only cast proxy votes once. Once submitted, they cannot be changed.",
    "SURVEYS": "Surveys",
    "SURVEYS_BLURB_SELECT_BETWEEN": "You must select between {{min}} and {{max}} answers",
    "SURVEYS_BLURB_SELECT_BETWEEN_P1": "You can select between",
    "SURVEYS_BLURB_SELECT_END_ANSWERS": "answers",
    "SURVEYS_BLURB_SELECT_MIDDLE_AND": "and",
    "SURVEYS_BLURB_SELECT_MUST_P1": "You must select",
    "SURVEYS_BLURB_SELECT_ONE": "You can select one answer",
    "SURVEYS_BLURB_SELECT_SPECIFIC": "You can select {{max}} answers",
    "SURVEYS_BLURB_SELECT_UP_TO_P1": "You can select up to",
    "SURVEYS_BLURB_SELECT_UP_TO": "You must select at least {{min}} answers",
    "SURVEYS_BLURB_SELECT_FROM_1": "You must select at least 1 answer",
    "SURVEYS_BLURB_ANSWERED": "You have answered this question",
    "SURVEYS_EMAIL_PERSONAL": "Email My Surveys",
    "SURVEYS_EMAIL_TOAST": "Surveys have been sent",
    "SURVEYS_MY_SURVEYS": "My Surveys",
    "SURVEYS_NO_DATA_MESSAGE": "No surveys have been added yet.  Please check back later.",
    "TAGS_SEARCH_PLACEHOLDER": "Search for users",
    "TAGS_SEARCH_COUNT": "{{ count }} results found",
    "TABBAR_MORE": "More",
    "TABBAR_MENU": "Menu",
    "TEST_TRANSLATION": "testing",
    "TOAST_ARIA_LABEL": "Toast alert",
    "TOAST_SUCCESS_TITLE": "Success",
    "TOAST_SUCCESS_MESSAGE_DEFAULT": "Successfully updated",
    "TOAST_INFO_TITLE": "Information",
    "TOAST_WARN_TITLE": "Warning",
    "TOAST_WARN_MESSAGE_DEFAULT": "Something went wrong",
    "TOAST_DANGER_TITLE": "Danger",
    "TOS_DIALOG_BUTTON_ACCEPT": "Accept",
    "TOS_DIALOG_BUTTON_CLOSE": "Close",
    "TOS_DIALOG_BUTTON_DECLINE": "Decline",
    "TOS_DIALOG_BUTTON_RETURN": "Return",
    "TOS_DIALOG_CLICKING_ACCEPT": "By clicking 'Accept' you are agreeing to the ",
    "TOS_DIALOG_ERROR_MESSAGE": "There was an issue when trying to load the Terms & Conditions.",
    "TOS_DIALOG_ERROR_MESSAGE_P1": "Please try again later",
    "TOS_DIALOG_PRIVACY_POLICY": "Privacy Policy",
    "TOS_DIALOG_TITLE_HIDING_PP": "How We Process Your Data",
    "TOS_DIALOG_TITLE_SHOWING_PP": "Privacy Policy",
    "TOS_DIALOG_VIEW_PP": "View the ",
    "TOOLBAR_RIGHT_OFFLINE_HINT": "You are currently offline",
    "TOOLBAR_RIGHT_UPDATE_HINT": "Update available",
    "TOOLBAR_RIGHT_CHAT_HINT": "Chat",
    "TOOLBAR_RIGHT_NEW_CHATS_HINT": "New Chats",
    "TOOLBAR_RIGHT_ALERTS_HINT": "Alerts",
    "TOOLBAR_RIGHT_NEW_ALERTS_HINT": "New Alerts",
    "TOOLBAR_RIGHT_PROFILE_HINT": "Profile",
    "TOOLBAR_RIGHT_LOG_IN_HINT": "Log In",
    "TRACKS_APPLIED_BANNER_BUTTON": "Clear",
    "TRACKS_APPLIED_BANNER_TITLE": "Filter Applied",
    "TRACKS_DIALOG_FILTERS_HEADER": "Filters",
    "TRACKS_DIALOG_LOCATIONS_HEADER": "Locations",
    "TRACKS_DIALOG_TITLE": "Filter",
    "TRACKS_NONE_SELECTED": "None",
    "UNAVAILABLE_MODULE_TOAST": "Selected module is currently unavailable. Please contact our support team or try again later.",
    "UNAVAILABLE_RESOURCE": "You do not have access to the selected resource. Contact our support team or try again later.",
    "USER_BADGE_TITLE": "My Badge",
    "USER_BADGE_CONTACTS_SCAN_BUTTON": "Scan a badge",
    "USER_BADGE_CONTACTS_SCAN_BUTTON_ARIA_LABEL": "Open modal to scan a badge qr code",
    "USER_BADGE_CONTACTS_ALPHABETICAL_ORDER_ARIA_LABEL": "Sort by alphabetical order",
    "USER_BADGE_CONTACTS_MOST_RECENT_ORDER_ARIA_LABEL": "Sort by recently added order",
    "USER_BADGE_CONTACTS_NO_CONTACTS_TITLE": "You haven't added any contacts yet.",
    "USER_BADGE_CONTACTS_NO_CONTACTS_TEXT": "Use the QR scanner to scan badges and add people to your contacts.",
    "USER_BADGE_CONTACTS_NO_RESULTS": "No results found.",
    "USER_BADGE_CONTACTS_SUCCESS_ALERT_TITLE": "Scan Successful",
    "USER_BADGE_CONTACTS_SUCCESS_ALERT_MESSAGE": "{{firstName}} {{lastName}} has been added to your contacts.",
    "USER_BADGE_CONTACTS_SUCCESS_ALERT_DISMISS": "Ok",
    "USER_BADGE_CONTACTS_FAILURE_ALERT_TITLE": "We have a problem...",
    "USER_BADGE_CONTACTS_FAILURE_ALERT_MESSAGE": "This doesn’t seem like a delegate badge QR code for this event.",
    "USER_BADGE_CONTACTS_FAILURE_ALERT_DISMISS": "Ok",
    "USER_BADGE_CONTACTS_CARD_DELETE": "Delete",
    "USER_BADGE_CONTACTS_CARD_CHAT_HINT": "Chat",
    "USER_BADGE_CONTACTS_CARD_VIDEO_CALL_HINT": "Video Call",
    "USER_BADGE_CONTACTS_CARD_CALL_HINT": "Call",
    "USER_BADGE_CONTACTS_CARD_EMAIL_HINT": "Email",
    "USER_BADGE_CONTACTS_CARD_WEBSITE_HINT": "Website",
    "USER_BUSINESS_CARDS_TITLE": "Business Cards",
    "USER_BUSINESS_CARDS_EDIT_BUTTON": "Edit",
    "USER_BUSINESS_CARDS_SHARE_BUTTON": "Share",
    "USER_BUSINESS_CARDS_SCAN_BUTTON": "Scan",
    "USER_BUSINESS_CARDS_SCAN_CLOSE_BUTTON": "Close And Return To Card",
    "USER_BUSINESS_CARDS_SCAN_OPEN_BUTTON": "Open Scanner",
    "USER_BUSINESS_CARDS_CONTACT_LIST_TITLE": "Your Contacts",
    "USER_BUSINESS_CARDS_CONTACT_LIST_NO_RESULTS": "You have no contacts yet",
    "USER_BUSINESS_CARDS_CONTACT_LIST_NO_RESULTS_SEARCH": "There are no contacts matching your search",
    "USER_BUSINESS_CARDS_REQUEST_LIST_TITLE": "Pending requests",
    "USER_BUSINESS_CARDS_REQUEST_LIST_NO_RESULTS": "You have no pending requests",
    "USER_BUSINESS_CARDS_REQUEST_TITLE": "{{firstName}} {{lastName}} would like to exchange business cards with you",
    "USER_BUSINESS_CARDS_REQUEST_ACCEPT": "Accept",
    "USER_BUSINESS_CARDS_REQUEST_DECLINE": "Decline",
    "USER_BUSINESS_CARDS_REQUEST_VIEW_PROFILE": "View Profile",
    "USER_BUSINESS_CARDS_EDIT_TITLE": "Edit Your Card",
    "USER_BUSINESS_CARDS_EDIT_SAVE_BUTTON": "Save Changes",
    "USER_BUSINESS_CARDS_EDIT_SECTION_DETAILS_TITLE": "Details",
    "USER_BUSINESS_CARDS_EDIT_PERSONAL_DETAILS_LABEL": "Personal details",
    "USER_BUSINESS_CARDS_EDIT_SOCIAL_DETAILS_LABEL": "Social details",
    "USER_BUSINESS_CARDS_EDIT_SECTION_STYLE_TITLE": "Card Style",
    "USER_BUSINESS_CARDS_EDIT_BACKGROUND_LABEL": "Choose a background",
    "USER_BUSINESS_CARDS_EDIT_COMPANY_LOGO_LABEL": "Company Logo",
    "USER_BUSINESS_CARDS_EDIT_COMPANY_LOGO_HINT": "Recommended dimensions 120px x 120px",
    "USER_BUSINESS_CARDS_EDIT_COMPANY_LOGO_UPLOAD_LABEL": "company logo",
    "USER_BUSINESS_CARDS_EDIT_CUSTOM_BACKGROUND_LABEL": "Custom background image",
    "USER_BUSINESS_CARDS_EDIT_CUSTOM_BACKGROUND_HINT": "Recommended dimensions 350px x 226px",
    "USER_BUSINESS_CARDS_EDIT_CUSTOM_BACKGROUND_UPLOAD_LABEL": "custom background",
    "USER_BUSINESS_CARDS_EDIT_CUSTOM_CARD_COLOR_LABEL": "Custom card colour",
    "USER_BUSINESS_CARDS_EDIT_CUSTOM_TEXT_COLOR_LABEL": "Custom text colour",
    "USER_BUSINESS_CARDS_EDIT_SAVED_SUCCESS_TOAST": "Your changes have been saved successfully",
    "USER_BUSINESS_CARDS_EDIT_SAVED_FAILURE_TOAST": "There was an error when saving your changes",
    "USER_BUSINESS_CARDS_NOTES_MODAL_TITLE": "Business card notes",
    "USER_BUSINESS_CARDS_NOTES_MODAL_NOTES_LABEL": "Notes for",
    "USER_BUSINESS_CARDS_NOTES_MODAL_NOTES_PLACEHOLDER": "Write some notes about this contact...",
    "USER_BUSINESS_CARDS_NOTES_MODAL_SAVE_BUTTON": "Save",
    "USER_PROFILE_ABOUT": "About",
    "USER_PROFILE_ABOUT_LABEL": "Write something about yourself",
    "USER_PROFILE_ADDRESS": "Address",
    "USER_PROFILE_LOCATION": "Location",
    "USER_PROFILE_ARIA_LABEL_FORM": "Edit profile",
    "USER_PROFILE_COMPANY": "Company",
    "USER_PROFILE_CONTACT_INFO": "Contact Info",
    "USER_PROFILE_DELETE_DATA": "Delete your personal data",
    "USER_PROFILE_DELETE_DATA_SUBTEXT": "This will delete your profile and any personal data that we hold of you.",
    "USER_PROFILE_DELETE_DATA_BUTTON": "Delete",
    "USER_PROFILE_DELETE_DATA_SUCCESS": "Your data will be deleted after 24 hours. Thank you.",
    "USER_PROFILE_DETAILS": "Details",
    "USER_PROFILE_EMAIL": "Email",
    "USER_PROFILE_EMAIL_VISIBLE": "Show my email address on my profile",
    "USER_PROFILE_FIRST_NAME": "First Name",
    "USER_PROFILE_GDPR_DELETE_MESSAGE": "WARNING: This will delete your profile and all data associated with it. This process will take place after 24 hours and can not be reversed past that point. Are you absolutely sure?",
    "USER_PROFILE_GDPR_DELETE_TITLE": "Data Deletion",
    "USER_PROFILE_GENERAL_SETTINGS_CHAT": "Chat Messaging",
    "USER_PROFILE_GENERAL_SETTINGS_CHAT_TEXT": "Send and receive private messages",
    "USER_PROFILE_GENERAL_SETTINGS_PROFILE": "Profile visible in app",
    "USER_PROFILE_GENERAL_SETTINGS_SHOW": "Show in People Modules",
    "USER_PROFILE_GENERAL_SETTINGS_SHOW_TEXT": "If unchecked, you won't show in any people modules",
    "USER_PROFILE_GENERAL_SETTINGS_SUB_TITLE": "Enable\/Disable settings below",
    "USER_PROFILE_GENERAL_SETTINGS_TITLE": "General Settings",
    "USER_PROFILE_GENERAL_SETTINGS_VIDEO": "Available For Video Meetings",
    "USER_PROFILE_UNSAVED_CHANGES_CONFIRM_TITLE": "Unsaved Changes",
    "USER_PROFILE_UNSAVED_CHANGES_CONFIRM_MESSAGE": "Leaving this page will discard the changes you have made to your profile.",
    "USER_PROFILE_JOB_TITLE": "Job Title",
    "USER_PROFILE_LAST_NAME": "Last Name",
    "USER_PROFILE_PROGRESS_MESSAGE_1": "Your profile is missing a lot of important details. Make your profile stand out by completing it!",
    "USER_PROFILE_PROGRESS_MESSAGE_2": "Your profile looks okay but there are still some important details missing.",
    "USER_PROFILE_PROGRESS_MESSAGE_3": "Your profile is looking great so far! However there are still a few more fields you can complete.",
    "USER_PROFILE_PROGRESS_MESSAGE_4": "Great work, your profile looks amazing! Be sure to keep your details up to date for the best experience",
    "USER_PROFILE_PROGRESS_NEXT_STEP": "Next step: Fill out your",
    "USER_PROFILE_PROGRESS_TITLE_INCOMPLETE": "Complete Your Profile",
    "USER_PROFILE_PROGRESS_TITLE_COMPLETE": "Profile Completion",
    "USER_PROFILE_PHONE_NUMBER": "Phone Number",
    "USER_PROFILE_PHONE_VISIBLE": "Show my phone number on my profile",
    "USER_PROFILE_PROFILE_IMAGE": "Profile Image",
    "USER_PROFILE_PROFILE_IMPORTED": "Profile imported!",
    "USER_PROFILE_PROFILE_UPDATED": "Profile updated!",
    "USER_PROFILE_PRIVACY_SETTINGS": "Change your privacy settings",
    "USER_PROFILE_NOTIFICATION_SETTINGS": "Change your notification settings",
    "USER_PROFILE_PRIVACY_SETTINGS_SUBTEXT": "Control how other users interact with your profile.",
    "USER_PROFILE_NOTIFICATION_SETTINGS_SUBTEXT": "Control your notification preferences for email and app notifications.",
    "USER_PROFILE_PRIVACY_SETTINGS_BUTTON": "Settings",
    "USER_PROFILE_NOTIFICATION_SETTINGS_BUTTON": "Settings",
    "USER_PROFILE_REQUEST_DATA": "Request your personal data",
    "USER_PROFILE_REQUEST_DATA_SUBTEXT": "This will email you a detailed breakdown of all the personal data we hold of you.",
    "USER_PROFILE_REQUEST_DATA_BUTTON": "Request",
    "USER_PROFILE_REQUEST_DATA_FAILURE": "We could not retrieve your data at this time. Please try again later or contact support.",
    "USER_PROFILE_REQUEST_DATA_INCORRECT_PASSWORD": "Your password was incorrect.",
    "USER_PROFILE_REQUEST_DATA_SUCCESS": "You should shortly receive an email with a breakdown of your data.",
    "USER_PROFILE_SUFFIX": "Suffix",
    "USER_PROFILE_SAVE": "Save",
    "USER_PROFILE_SOCIAL_MEDIA": "Social Media",
    "USER_PROFILE_TITLE": "Title",
    "USER_PROFILE_WEBSITE": "Website",
    "USER_PROFILE_TAGS": "Tags",
    "USER_PROFILE_TAGS_MESSAGE": "Tags assigned by your event organiser",
    "USER_PROFILE_TAGS_LABEL": "User tags",
    "USER_PROFILE_APPEAR_OFFLINE": "Appear offline to others",
    "VIDEO_CALL_BLOCK_DIALOG_HEADER": "Block Caller?",
    "VIDEO_CALL_BLOCK_DIALOG_NO": "No",
    "VIDEO_CALL_BLOCK_DIALOG_PREVENT": "Prevent all future calls from",
    "VIDEO_CALL_BLOCK_DIALOG_YES": "Yes",
    "VIDEO_CALL_INCOMING_DIALOG_ACCEPT": "Accept",
    "VIDEO_CALL_INCOMING_DIALOG_BLOCK": "Block from calling again",
    "VIDEO_CALL_INCOMING_DIALOG_DECLINE": "Decline",
    "VIDEO_CALL_INCOMING_DIALOG_HEADER": "Incoming video call from",
    "VIDEO_CALL_OUTGOING_DIALOG_CANCEL": "Cancel",
    "VIDEO_CALL_OUTGOING_DIALOG_HEADER": "Connecting with ",
    "VIDEO_CALL_UNAVAILABLE_DIALOG_CLOSE": "Close",
    "VIDEO_CALL_UNAVAILABLE_DIALOG_HEADER": "Unavailable",
    "VIDEO_CALL_UNAVAILABLE_DIALOG_MESSAGE": "is unavailable to take the call",
    "VIDEO_CALLER_BLOCKED_TOAST_MESSAGE": "has been blocked from calling you",
    "VIDEO_LOGIN_TO_WATCH": "You must be logged in to watch videos",
    "VIDEO_TRY_AGAIN": "Try again",
    "VIDEO_ERROR": "An error has occurred: {{error}}",
    "VIDEO_UNMUTE": "Unmute",
    "VOD_WATCH_MORE_TITLE": "Watch more",
    "VOD_WATCH_MORE_DESC": "The rest of the highlights from our fantastic event",
    "VOD_LIKE_LABEL": "Like this video",
    "VOD_ARIA_WATCH_MORE_FORWARDS": "Scroll videos forwards button",
    "VOD_ARIA_WATCH_MORE_BACK": "Scroll videos backwards button",
    "VOD_ARIA_VIEW_FAB": "View count inactive button",
    "VOD_ARIA_LIKE_FAB": "Like count interactive button",
    "VOD_ARIA_SHOW_VIDEO": "Show video page button",
    "VOD_ARIA_VIEW_COUNT": "View count",
    "VOD_ARIA_LIKE_COUNT": "Like count",
    "VOD_VIDEO_CARD_ALT": "Video poster or thumbnail",
    "VOD_SEARCH_NO_RESULTS": "No results found for:",
    "VOD_LOGIN_PROMPT": "You must be logged in to watch videos on demand",
    "VOD_LOADING": "Loading results...",
    "WIDGET_NO_RESULTS": "Not much going on around here... Please check back again later",
    "PERSONAL_AGENDA_HEADER_TAB_LIST": "View List",
    "PERSONAL_AGENDA_HEADER_TAB_CALENDAR": "View Calendar",
    "PERSONAL_AGENDA_TITLE": "My Event Schedule",
    "PERSONAL_AGENDA_NO_CONTENT": "You currently have no meetings or favourited sessions for this date",
    "PERSONAL_AGENDA_MEETING_SUBTEXT": "Tap to go to your meetings",
    "PERSONAL_AGENDA_MEETING_LABEL": "(Meeting)",
    "PERSONAL_AGENDA_SCHEDULED_ITEM_FALLBACK": "Scheduled Meeting",
    "PWA_OFFLINE_TOAST": "You are currently offline. Some areas of the app may not work as expected.",
    "PWA_ONLINE_TOAST": "Back online",
    "ZOOM_CONFIRM_LEAVE_SESSION_TITLE": "Leave Zoom Session",
    "ZOOM_CONFIRM_LEAVE_SESSION_MESSAGE": "Click confirm to leave your current Zoom session",
    "UNAUTHORISED_TOAST_MESSAGE": "Your session is no longer valid, please log in again.",
    "PWA_INSTALL_TITLE": "Install {{appName}}",
    "PWA_INSTALL_IOS_MESSAGE_PRE": "Tap",
    "PWA_INSTALL_IOS_MESSAGE_POST": "below then 'Add to Home Screen'",
    "PWA_INSTALL_BUTTON_PROMPT": "Click the button below to install the app to your device",
    "PWA_INSTALL_BUTTON": "Install to device",
    "PWA_INSTALL_OR": "OR",
    "PWA_INSTALL_WEB": "Continue to web app",
    "PWA_INSTALL_NOT_AVAILABLE": "If the install button isn't working for you, it may be because you are using an unsupported browser. Please try using Chrome or Edge, or Safari if on iOS.",
    "MEETINGS_MY_MEETINGS": "My Meetings",
    "MEETINGS_EXHIBITOR_BUTTON": "Exhibitor Meeting",
    "MEETINGS_DELEGATE_BUTTON": "Attendee Meeting",
    "MEETINGS_CALENDLY_BUTTON": "Calendly Meeting",
    "MEETINGS_CALENDLY_TITLE": "Calendly",
    "MEETINGS_CREATE_MEETING": "Create a new meeting",
    "MEETINGS_EXHIBITOR_TAG_LABEL": "EXHIBITOR",
    "MEETINGS_DELEGATE_TAG_LABEL": "DELEGATE",
    "MEETINGS_EDIT_MEETING": "Amend your meeting",
    "MEETINGS_EDIT_MEETING_OCCURRENCE": "Amend your meeting occurrence",
    "MEETINGS_EDIT_MEETING_SERIES": "Amend your meeting series",
    "MEETINGS_TAB_AVAILABILITY": "My Availability",
    "MEETINGS_VIEW_MEETING": "View meeting",
    "MEETINGS_JOIN_MEETING": "Join meeting",
    "MEETINGS_CALENDLY_RESCHEDULE_MEETING": "Reschedule",
    "MEETINGS_CALENDLY_CANCEL_MEETING": "Cancel",
    "MEETINGS_CANCEL_MEETING": "Cancel meeting",
    "MEETINGS_EDIT_MEETING_BUTTON": "Edit meeting",
    "MEETINGS_TODAY": "Today",
    "MEETINGS_VIRTUAL": "Virtual Meeting",
    "MEETINGS_COMPANY": "Exhibitor Location",
    "MEETINGS_SMART_SESSION": "Smart Session",
    "MEETINGS_CONTEXT_MENU_ARIA": "Open context menu",
    "MEETINGS_CANCEL": "Cancel",
    "MEETINGS_CANCEL_CONFIRM_HEADER": "Are you sure you want to cancel this meeting?",
    "MEETINGS_CANCEL_CONFIRM_CANCEL": "I've changed my mind",
    "MEETINGS_CANCEL_CONFIRM_CONFIRM": "I'm sure",
    "MEETINGS_UPDATE_SERIES_HEADER": "You are updating the meeting series",
    "MEETINGS_UPDATE_SERIES_MESSAGE": "This will overwrite changes made to individual meetings in the series and cannot be undone.",
    "MEETINGS_UPDATE_SERIES_CONFIRM": "Proceed",
    "MEETINGS_UPDATE_SERIES_CANCEL": "Cancel",
    "MEETINGS_DECLINE": "Decline",
    "MEETINGS_ACCEPT": "Accept",
    "MEETINGS_PENDING": "PENDING",
    "MEETINGS_AVAILABLE": "Available",
    "MEETINGS_UNAVAILABLE": "Unavailable",
    "MEETINGS_USER_AVAILABILITY": "Your availability",
    "MEETINGS_CREATE_MEETINGS_TAB": "My Meetings",
    "MEETINGS_CREATE_MEETING_TAB": "New Meeting",
    "MEETINGS_CREATE_AVAILABILITY_TAB": "My Availability",
    "MEETINGS_SEND_INVITATION": "Send Invitation",
    "MEETINGS_UPDATE_INVITATION": "Send Update",
    "MEETINGS_ARRANGE_MEETING_WITH": "Arrange a meeting with",
    "MEETINGS_SELECT_REPRESENTATIVE": "Choose a representative to meet",
    "MEETINGS_CHOOSE_PERSON": "Choose a person to meet",
    "MEETINGS_CHOOSE_COMPANY": "Choose an exhibitor to meet",
    "MEETINGS_CHOOSE_DIFFERENT_COMPANY": "Choose a different exhibitor",
    "MEETINGS_PERSON_PLACEHOLDER": "Choose a person to meet with",
    "MEETINGS_COMPANY_PLACEHOLDER": "Select an exhibitor to meet with",
    "MEETINGS_CHOOSE_ADDITIONAL_PERSON": "Add more people",
    "MEETINGS_SELECT_PERSON_PLACEHOLDER": "You need to choose at least one person to meet with",
    "MEETINGS_SELECT_PERSON_REMOVE_ALL": "Remove all",
    "MEETINGS_COLLAPSE_PERSON_CHOICES": "Collapse",
    "MEETINGS_AVAILABILITY_ALL_DAY": "Available all day",
    "MEETINGS_AVAILABILITY_RANGE_SELECTION": "Select range",
    "MEETINGS_AVAILABILITY_UPDATE_SUCCESS": "Meeting availability has been updated",
    "MEETINGS_AVAILABILITY_CONFIRM_SELECTION": "Confirm selection",
    "MEETINGS_AVAILABILITY_ALT_TIMEZONE": "Times shown in brackets are in the event timezone ({{ eventTimezone }}).",
    "MEETINGS_AVAILABIITY_NEXT_DAY": "View next day",
    "MEETINGS_AVAILABIITY_PREV_DAY": "View previous day",
    "MEETINGS_AVAILABILITY_OUTSIDE_RANGE": "Meeting booking is only available from {{ eventStart }} to {{ eventEnd }}",
    "MEETINGS_TIME_PICKER_TITLE": "{{ personName }}'s availability",
    "MEETINGS_TIME_PICKER_GROUP_TITLE": "Group Availability",
    "MEETINGS_TIME_PICKER_GROUP_SUBTITLE_PLURAL": "{{ participants }} people unavailable",
    "MEETINGS_TIME_PICKER_GROUP_SUBTITLE_SINGULAR": "{{ participants }} person unavailable",
    "MEETINGS_TIME_PICKER_MAX": "Maximum meeting length is {{ maxMeetingLength }} minutes",
    "MEETINGS_TIME_PICKER_SELECTED": "Selected meeting time",
    "MEETINGS_TIME_PICKER_SELECTED_HOURS_LENGTH": "hr",
    "MEETINGS_TIME_PICKER_SELECTED_MINS_LENGTH": "mins",
    "MEETINGS_TIME_PICKER_EVENT_TIME": "({{ start }} - {{ end }})",
    "MEETINGS_TIME_PICKER_CANCEL": "Cancel",
    "MEETINGS_TIME_PICKER_CONFIRM": "Confirm selection",
    "MEETINGS_TIME_PICKER_UNAVAILABLE_PARTICIPANTS": "Not all participants are available for this time range. Either remove the unavailable participants or choose a different time.",
    "MEETINGS_REQUESTS_SENT": "Sent",
    "MEETINGS_REQUESTS_RECEIVED": "Received",
    "MEETINGS_WHERE": "Where",
    "MEETINGS_IN_PERSON_CHOICE": "In-Person meeting",
    "MEETINGS_VIRTUAL_CHOICE": "Virtual meeting",
    "MEETINGS_BOTH_CHOICE": "Both meeting types",
    "MEETINGS_COMPANY_CHOICE": "Exhibitor location",
    "MEETINGS_MAKE_VIRTUAL": "Make this a virtual meeting",
    "MEETINGS_MAKE_VIRTUAL_PLACEHOLDER": "Use Video Breakouts for the meeting",
    "MEETINGS_CHOOSE_LOCATION": "Choose event location",
    "MEETINGS_CHOOSE_VIRTUAL_LOCATION": "Choose virtual meeting",
    "MEETINGS_CHOOSE_LOCATION_PLACEHOLDER": "Please select",
    "MEETINGS_SUGGEST_PLACE": "Suggest a meeting place",
    "MEETINGS_SUGGEST_PLACE_PLACEHOLDER": "Suggest a meeting spot",
    "MEETINGS_CREATE_SUCCESS": "Your meeting request has been sent",
    "MEETINGS_UPDATE_SUCCESS": "Meeting successfully updated",
    "MEETINGS_WHEN": "When",
    "MEETINGS_DATE": "Date",
    "MEETINGS_OR": "OR",
    "MEETINGS_TIME": "Time",
    "MEETINGS_SUBJECT": "Subject",
    "MEETINGS_SUBJECT_LABEL": "Meeting Subject",
    "MEETINGS_SUBJECT_PLACEHOLDER": "Add your subject",
    "MEETINGS_TIME_PLACEHOLDER": "Choose your meeting slot",
    "MEETINGS_LOCATION_SELECT_PLACEHOLDER": "Choose location",
    "MEETINGS_MESSAGE": "Description",
    "MEETINGS_MESSAGE_PLACEHOLDER": "Add a meeting description",
    "MEETINGS_ATTACHMENTS": "Attachments",
    "MEETINGS_ATTACHMENTS_PLACEHOLDER": "Attach a file or photo for your participants to view",
    "MEETINGS_PARTICIPANT_LABEL": "{{count}} participants",
    "MEETINGS_PARTICIPANT_TOOLTIP": "Meeting with",
    "MEETINGS_LOCATION_TOOLTIP": "Location",
    "MEETINGS_LOCATION_CALENDLY_TOOLTIP": "Managed by Calendly",
    "MEETINGS_LOCATION_CALENDLY_LINK": "Open Calendly Link",
    "MEETINGS_MESSAGE_TOOLTIP": "Description",
    "MEETINGS_TIMEDATE_TOOLTIP": "Time/Date",
    "MEETINGS_RECURRING_TOOLTIP": "Recurring",
    "MEETINGS_VIDEO_MEETING": "Video meeting",
    "MEETINGS_NO_COMPANY_LOCATION": "There is no location set for this exhibitor - please contact your event organiser for more information",
    "MEETINGS_ORGANISER_TOOLTIP": "Organiser",
    "MEETINGS_TIME_TOOLTIP": "Time",
    "MEETINGS_RECURRENCE_TOOLTIP": "Recurring frequency",
    "MEETING_DETAIL_HEADING": "Meeting Detail",
    "MEETING_DETAIL_MEETING_WITH": "You're meeting with",
    "MEETING_DETAIL_MET_WITH": "You met with",
    "MEETING_DETAIL_REMAINING": "Remaining",
    "MEETING_DETAIL_NOTES": "Notes",
    "MEETING_DETAIL_NOTES_PLACEHOLDER": "Add your meeting notes",
    "MEETING_DETAIL_TAGS": "Tags",
    "MEETING_DETAIL_UNTIL_START": "Until start",
    "MEETING_DETAIL_IN_PROGRESS": "Meeting in progress",
    "MEETING_DETAIL_ENDED": "This meeting has ended",
    "MEETING_DETAIL_COMPLETED": "Meeting ended",
    "MEETING_DETAIL_CANCEL_MEETING": "Cancel meeting",
    "MEETING_DETAIL_PARTICIPANTS_LABEL": "Show all participants",
    "MEETING_DETAIL_ADMIN_CREATED": "Admin Created",
    "MEETINGS_RECURRENCE_LABEL": "Recurrence",
    "MEETINGS_RECURRENCE_PLACEHOLDER": "Don't repeat",
    "MEETINGS_RECURRENCE_DATE": "Repeat Until",
    "MEETINGS_RECURS_DAILY": "Every Day",
    "MEETINGS_RECURS_WEEKDAILY": "Every Weekday",
    "MEETINGS_RECURS_WEEKLY": "Weekly",
    "MEETINGS_RECURS_MONTHLY": "Monthly",
    "MEETINGS_RECURS_ANNUALLY": "Annually",
    "MEETINGS_RECURS_UNTIL": "{{ frequency }} until {{ end }}",
    "MEETINGS_EDIT_OCCURRENCE": "Edit Occurrence",
    "MEETINGS_EDIT_OCCURRENCE_WARNING": "You can only edit the recurring details on the meeting series",
    "MEETINGS_EDIT_SERIES": "Edit Series",
    "MEETINGS_DECLINE_OCCURRENCE": "Decline Occurrence",
    "MEETINGS_DECLINE_SERIES": "Decline Series",
    "MEETINGS_CANCEL_OCCURRENCE": "Cancel Occurrence",
    "MEETINGS_CANCEL_SERIES": "Cancel Series",
    "MEETINGS_SERIES_LABEL": "Series",
    "MEETINGS_INVITE_RECEIVED": "Meeting Received",
    "MEETINGS_DOWNLOAD_COMPLETE": "Download report",
    "MEETINGS_TAB_EMPTY_TODAY_HEADING": "We couldn't find any data to display",
    "MEETINGS_TAB_EMPTY_TODAY_DESC": "No meetings scheduled for today",
    "MEETINGS_DAILY_VIEW": "Daily view",
    "MEETINGS_SEGMENT_FOOTNOTE": "{{ start }} - {{ end }}",
    "MEETINGS_COMPANY_LOCATION": "Using default location",
    "MEETINGS_ADMIN_CREATED_TOOLTIP": "Admin created meeting",
    "MEETINGS_LOCATION_PICKER_TITLE": "Choose a meeting location",
    "MEETINGS_LOCATION_PICKER_CANCEL_BUTTON": "Cancel",
    "MEETINGS_LOCATION_PICKER_CONFIRM_BUTTON": "Confirm",
    "MEETINGS_UNKNOWN_LOCATION": "Unknown location",
    "MEETING_ERROR_NO_PERSON": "You must select a person before creating a meeting",
    "MEETING_ERROR_NO_PERSON_UPDATE": "You must select at least one person to update this meeting",
    "MEETING_ERROR_NO_PERSON_SELECTED": "You must select a person before choosing a meeting slot",
    "MEETING_ERROR_NO_TIME": "You must select a date and time before creating a meeting",
    "MEETING_ERROR_MAX_MESSAGE": "Invite message can be no longer than 256 characters",
    "MEETING_ERROR_PERSON_UNAVAILABLE": "This user is no longer available",
    "MEETING_ERROR_GENERIC": "Whoops! Something went wrong whilst carrying out your request",
    "MEETING_ERROR_NO_LOCATION": "You must select a location before creating a meeting",
    "MEETING_ERROR_NO_SS_ID": "Cannot find a valid Smart Session attached to this meeting",
    "MEETING_ERROR_CANNOT_JOIN": "We ran into a problem whilst trying to join your meeting",
    "MEETING_TOAST_APPROVED": "Meeting has been accepted",
    "MEETING_TOAST_CANCELLED": "Your meeting has been cancelled",
    "MEETING_TOAST_DECLINED": "Meeting has been declined",
    "MEETING_NOTIFICATION_INVITE_SENT_TITLE": "New Meeting Request",
    "MEETING_NOTIFICATION_INVITE_SENT_MESSAGE": "{{ senderName }} would like to schedule a meeting with you",
    "MEETING_NOTIFICATION_INVITE_ACCEPTED_TITLE": "Meeting Accepted",
    "MEETING_NOTIFICATION_INVITE_ACCEPTED_MESSAGE": "{{ senderName }} accepted your meeting invitation",
    "MEETING_NOTIFICATION_INVITE_DECLINED_TITLE": "Meeting Declined",
    "MEETING_NOTIFICATION_INVITE_DECLINED_MESSAGE": "{{ senderName }} declined your meeting request",
    "MEETING_NOTIFICATION_CANCELLED_TITLE": "Meeting Cancelled",
    "MEETING_NOTIFICATION_CANCELLED_MESSAGE": "Your meeting with {{ senderName }} has been cancelled",
    "MEETING_NOTIFICATION_UPDATE_ACCEPTED_TITLE": "Meeting Amended",
    "MEETING_NOTIFICATION_UPDATE_ACCEPTED_MESSAGE": "There has been a change made to a meeting with {{ senderName }}",
    "MEETING_NOTIFICATION_UPDATE_DECLINED_TITLE": "Meeting Declined",
    "MEETING_NOTIFICATION_UPDATE_DECLINED_MESSAGE": "Your meeting invite to {{ senderName }} has been declined",
    "MEETING_NOTIFICATION_PENDING_TITLE": "Meeting is Starting",
    "MEETING_NOTIFICATION_PENDING_MESSAGE": "Your meeting with {{ senderName }} starts in 5 minutes",
    "MEETING_NOTIFICATION_STARTING_TITLE": "Join your Meeting Now",
    "MEETING_NOTIFICATION_STARTING_MESSAGE": "Your meeting with {{ senderName }} is starting now",
    "MEETING_NOTIFICATION_ARIA_LABEL": "Open my meetings",
    "MEETING_INVITE_RECEIVED": "Meeting Received",
    "NOTIFICATIONS_DISMISS_ARIA_LABEL": "Dismiss notification",
    "NOTIFICATIONS_MODAL_HEADER": "Notification Settings",
    "NOTIFICATIONS_MODAL_CONTENT": "Personalise how you receive notifications from this application.",
    "NOTIFICATIONS_MODAL_NOTIFICATION_SOUNDS_LABEL": "Play sound on notification",
    "NOTIFICATIONS_MODAL_NOTIFICATION_SOUNDS_SUBLABEL": "If unchecked, you will not hear a sound when an in app notification is received.",
    "NOTIFICATIONS_MODAL_RECEIVE_EMAIL_NOTIFICATIONS_LABEL": "Receive email notifications",
    "NOTIFICATIONS_MODAL_RECEIVE_EMAIL_NOTIFICATIONS_SUBLABEL": "If unchecked, you will not be sent an email when a notification is received.",
    "NOTIFICATIONS_MODAL_SAVE_BUTTON": "Ok",
    "NOTIFICATIONS_SUCCESS_TITLE": "Success",
    "NOTIFICATIONS_400_ERROR_TITLE": "Bad Request",
    "NOTIFICATIONS_401_ERROR_TITLE": "Unauthorized",
    "NOTIFICATIONS_403_ERROR_TITLE": "Forbidden",
    "NOTIFICATIONS_500_ERROR_TITLE": "Server Error",
    "QR_SCANNER_MODAL_NO_CAMERA_1": "We were unable to detect a camera connected to your device.",
    "QR_SCANNER_MODAL_NO_CAMERA_2": "Please connect a camera to allow us to scan QR codes.",
    "QR_SCANNER_MODAL_LOADING": "Loading QR Scanner...",
    "QR_SCANNER_MODAL_TITLE": "Scan a QR",
    "UNKNOWN": "Unknown",
    "ABSTRACTS_POSTER_PRESENTER": "Poster Presenter:",
    "ABSTRACTS_PRESENTERS_LABEL": "Presenters",
    "ABSTRACTS_AUTHORS_LABEL": "Authors",
    "ABSTRACTS_ENGAGEMENT_TABS": "Abstracts Engagement Tabs",
    "ABSTRACTS_ENGAGEMENT_CONTENT_TAB": "Abstracts Detail Content Tab",
    "ABSTRACTS_METRICS_VIEWS_ARIA_LABEL": "Number of views on this abstract",
    "ABSTRACTS_METRICS_LIKES_ARIA_LABEL": "Like or unlike this abstract",
    "ABSTRACTS_ENGAGEMENT_CONTENT_TAB_HEADER": "Content",
    "ABSTRACTS_ENGAGEMENT_CHAT_TAB_HEADER": "Chat",
    "ABSTRACTS_ENGAGEMENT_QANDA_TAB_HEADER": "Q&A",
    "ABSTRACTS_ENGAGEMENT_POLL_TAB_HEADER": "Poll",
    "ABSTRACTS_ENGAGEMENT_FEEDBACK_TAB_HEADER": "Feedback",
    "ABSTRACTS_ENGAGEMENT_NOTES_TAB_HEADER": "Notes",
    "ABSTRACTS_ENGAGEMENT_QANDA_TAB_EMPTY": "This session has no Q&A linked",
    "ABSTRACTS_ENGAGEMENT_POLL_TAB_EMPTY": "This session has no poll linked",
    "ABSTRACTS_ENGAGEMENT_NOTES_TAB_NOT_AUTHED": "You must be logged in to create notes",
    "ABSTRACTS_ENGAGEMENT_FEEDBACK_TAB_EMPTY": "This session has no survey linked",
    "ABSTRACTS_ENGAGEMENT_CHAT_TAB_NOT_AUTHED": "You must be logged in to chat",
    "ABSTRACTS_DOCUMENTS_LABEL": "Documents",
    "ABSTRACTS_LIST_GRID_LAYOUT_ARIA_LABEL": "Display Abstracts in a grid layout",
    "ABSTRACTS_LIST_LIST_LAYOUT_ARIA_LABEL": "Display Abstracts in a list layout",
    "ABSTRACTS_LIST_MASONRY_LAYOUT_ARIA_LABEL": "Display Abstracts in a masonry layout",
    "ABSTRACTS_LIST_FILTER_ARIA_LABEL": "Show filter dialog",
    "ABSTRACTS_AUDIO_COMMENTARY_LABEL": "Audio Commentary",
    "ABSTRACTS_ERROR_GENERIC": "Something went wrong whilst trying to execute your request",
    "AUDIO_PLAYER_ERROR": "There was a problem playing the audio. Check that the correct file type has been uploaded.",
    "AUDIO_DECODING_REFRESH": "There was an error whilst decoding the audio file",
    "AUDIO_PLAYER_ERROR_MESSAGE": "The audio player cannot load the source media and has suspended operation to prevent degraded performance",
    "IMAGE_SERVICE_FAILED_TO_LOAD": "Image Service: Could not load image",
    "AUDIO_TOKEN_REFRESH": "The audio token expired, attempting to refresh",
    "NOTES_PANEL_BACK_ACTION_LABEL": "Back",
    "NOTES_PANEL_SAVE_ACTION_LABEL": "Save",
    "NOTES_PANEL_DELETE_ACTION_LABEL": "Delete",
    "NOTES_PANEL_EMAIL_ACTION_LABEL": "Email",
    "NOTES_PANEL_CREATE_ACTION_LABEL": "New",
    "NOTES_PANEL_CANCEL_ACTION_LABEL": "Cancel",
    "NOTES_PANEL_SELECT_ACTION_LABEL": "Select",
    "NOTES_PANEL_AUTOSAVE_TOAST_TITLE": "Note Updated",
    "NOTES_PANEL_AUTOSAVE_TOAST": "Successfully autosaved note: '{{ title }}'",
    "NOTES_PANEL_EMAIL_NOTES_SUCCESS": "Your notes should arrive in your inbox soon",
    "TAG_FILTER_DIALOG_LABEL": "Filter Results",
    "TAG_FILTER_DIALOG_ADDITIVE_MODE": "Includes",
    "TAG_FILTER_DIALOG_SUBTRACTIVE_MODE": "Only",
    "TAG_FILTER_DIALOG_SAVE_BUTTON": "Save Selection",
    "TAG_FILTER_DIALOG_CLEAR_BUTTON": "Clear Selected",
    "TAG_LIST_CLEAR_FILTER_ARIA_LABEL": "Clear filter",
    "FORMS_INPUTS_CHIPLIST_DEFAULT_LABEL": "Options...",
    "FORMS_INPUTS_CHIPLIST_NO_RESULTS": "No options found",
    "TAG_FILTER_DIALOG_FAVOURITES_OPTION": "Only include my favourites in results",
    "TAG_FILTER_DIALOG_FAVOURITES_TAG_LABEL": "My Favourites",
    "GLOBAL_SCANNER_ACCESS_REJECTED_HEADER": "Access Rejected",
    "GLOBAL_SCANNER_ACCESS_REJECTED_MESSAGE": "You are not able to scan QR codes of this type",
    "GLOBAL_SCANNER_ACCESS_REJECTED_BUTTON": "Ok",
    "AGENDA_SESSION_SOCIAL_FEED_TAB_HEADER": "Social Feed",
    "ABSTRACTS_ENGAGEMENT_SOCIAL_FEED_TAB_HEADER": "Social Feed",
    "SOCIAL_FEED_POST_OVERFLOW_ACTIONS_LABEL": "More post actions",
    "SOCIAL_FEED_POST_OVERFLOW_ACTION_DELETE": "Delete",
    "SOCIAL_FEED_POST_OVERFLOW_ACTION_EDIT": "Edit",
    "SOCIAL_FEED_POST_OVERFLOW_ACTION_HIDE": "Hide",
    "SOCIAL_FEED_POST_OVERFLOW_ACTION_UNHIDE": "Unhide",
    "SOCIAL_FEED_POST_OVERFLOW_ACTION_PIN": "Pin",
    "SOCIAL_FEED_POST_OVERFLOW_ACTION_UNPIN": "Unpin",
    "SOCIAL_FEED_POST_OVERFLOW_ACTION_REPORT": "Report",
    "SOCIAL_FEED_COMMENT_OVERFLOW_ACTIONS_LABEL": "More comment actions",
    "SOCIAL_FEED_COMMENT_OVERFLOW_ACTION_DELETE": "Delete",
    "SOCIAL_FEED_COMMENT_OVERFLOW_ACTION_EDIT": "Edit",
    "SOCIAL_FEED_COMMENT_OVERFLOW_ACTION_HIDE": "Hide",
    "SOCIAL_FEED_COMMENT_OVERFLOW_ACTION_UNHIDE": "Unhide",
    "SOCIAL_FEED_FOOTER_CONTEXT_NEW_COMMENT": "Replying to {{ postAuthor }}",
    "SOCIAL_FEED_FOOTER_CONTEXT_EDIT_COMMENT": "Editing comment",
    "SOCIAL_FEED_FOOTER_CONTEXT_EDIT_POST": "Editing post",
    "SOCIAL_FEED_FOOTER_CONTEXT_CANCEL_LABEL": "Clear changes",
    "SOCIAL_FEED_FOOTER_MEDIA_CLEAR_LABEL": "Remove media",
    "SOCIAL_FEED_FOOTER_MEDIA_UPLOAD_LABEL": "Upload media",
    "SOCIAL_FEED_FOOTER_EMOJI_LABEL": "Add emoji",
    "SOCIAL_FEED_FOOTER_SUBMIT_LABEL": "Submit",
    "SOCIAL_FEED_FOOTER_UPLOAD_IMAGE": "Upload photo",
    "SOCIAL_FEED_FOOTER_UPLOAD_VIDEO": "Upload video",
    "SOCIAL_FEED_ERROR_TITLE": "Oops, we couldn't load the social feed",
    "SOCIAL_FEED_ERROR_RETRY_BUTTON": "Try again",
    "SOCIAL_FEED_PINNED_POSTS_LABEL": "Pinned message",
    "SOCIAL_FEED_PINNED_POSTS_UNPIN_LABEL": "Unpin message",
    "SOCIAL_FEED_PINNED_POSTS_COLLAPSE_LABEL": "Collapse pinned message",
    "SOCIAL_FEED_PINNED_POSTS_EXPAND_LABEL": "Expand pinned message",
    "SOCIAL_FEED_POST_VIEW_REPLIES": "View replies",
    "SOCIAL_FEED_POST_HIDE_REPLIES": "Hide replies",
    "SOCIAL_FEED_PANEL_EMPTY_PROMPT": "Start the conversation",
    "SOCIAL_FEED_FOOTER_INPUT_PLACEHOLDER": "Say something...",
    "SOCIAL_FEED_SUBMISSION_ACTIONS_REPLY": "Reply",
    "SOCIAL_FEED_SUBMISSION_ACTIONS_LIKE": "Like",
    "SOCIAL_FEED_SUBMISSION_ACTIONS_UNLIKE": "Unlike",
    "AGENDA_CARDS_FEATURES_ACTIVITY_FEED": "Social Feed",
    "SOCIAL_FEED_FOOTER_PROCESSING_VIDEO": "Processing...",
    "PINDROP_COMPANIES": "Companies",
    "PINDROP_SESSIONS": "Sessions",
    "CALENDLY_WIDGET_ERROR": "Something went wrong whilst trying to load the Calendly",
    "CALENDLY_FAILED_TO_LOAD": "Failed to load Calendly Script",
    "CALENDLY_BACK_TO_SCHEDULER": "Back to Scheduler"
}